<template>
    <div class="content-header">
        <div class="row-fluid">
        </div>
        <nav style="display: flex;">
<!--            <template>-->
<!--                <div class="locale-changer pt-3 form-inline nav-link">-->
<!--                    <select class="form-control-sm form-group" v-model="$i18n.locale">-->
<!--                        <label>Language: </label>-->
<!--                        <option v-for="(lang, i) in auth.languages" :key="`Lang${i}`" :value="lang">{{ lang }}</option>-->
<!--                    </select>-->
<!--                </div>-->
<!--            </template>-->
<!--            <b-badge @click="$i18n.locale = 'en'" title="English"  variant="danger" style="font-size: 16px; cursor: pointer;">EN</b-badge><span style=" padding-left: 10px;"></span>-->
<!--            <b-badge @click="$i18n.locale = 'fr'"  title="French" variant="primary" style="font-size: 16px; cursor: pointer;">FR</b-badge>-->
            <!--            <a href="#" @click="logout()" class="nav-link"><log-out-icon/></a>-->
<!--            <a href="#" @click="startTour()" class="nav-link"><help-circle-icon></help-circle-icon></a>-->
        </nav>
<!--        <v-tour name="myTour" :steps="steps"></v-tour>-->
    </div><!-- content-header -->
</template>

<script>
    import {mapState} from 'vuex'
    // import {LogOutIcon, HelpCircleIcon} from "vue-feather-icons"
    //import { genUserBaseUrl } from "src/app.config";
    // import LanguagesDropdown from 'vue-languages-dropdown'

    export default {
        name: "Header",
        components:{
            // LogOutIcon,
            // LanguagesDropdown
        },
        data(){
            return{
                acc:'',
                getDropdownValues:'',
                validateSelection:'',
                model:{
                    account:'01893098999'
                },
                country_iso : "mm",
                accounts:[
                    { id: 1, name: 'Option 1'},
                    { id: 2, name: 'Option 2'}
                ]
            }
        },
        methods:{
            optionSelected: function (data) {
                this.$i18n.locale = data.code
                console.log(data)
            },
            logout(){
                localStorage.clear();
                window.location = '/';
            },
            startTour(){
                this.$tours['myTour'].start()
            }
        },
        computed:{
            ...mapState([
                'company',
                'officer',
                'auth'
            ])
        },
        mounted() {
            //$('.my-select').selectpicker();
        }
    }
</script>

<style scoped>

</style>