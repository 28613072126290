<template>
    <div class="content ht-100v pd-0">
        <div class="content-body pd-10">
            <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                <div>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                            <li class="breadcrumb-item">
                                <router-link to="#">User</router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">All Users</li>
                        </ol>
                    </nav>
                    <h4 class="mg-b-0 tx-spacing--1">View All Users</h4>
                    <p class="tx-color-03 tx-12 mg-b-0">{{auth.userInfo.userFirstName}} {{auth.userInfo.userLastName}}</p>
                    <p class="tx-color-03 tx-12 mg-b-0"><strong>Last login:</strong> {{auth.userInfo.userLastLogin | moment('dddd, MMMM Do YYYY, h:mm:ss a')}}</p>
                </div>
                <div class="d-none d-md-block">
                    <download-excel
                            :data="users"
                            class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
                        <PrinterIcon class="mg-r-5"/>{{$t('extra.downloadExcel')}}
                    </download-excel>
                    <download-excel
                            type="csv"
                            :data="users"
                            class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
                        <PrinterIcon class="mg-r-5"/>{{$t('extra.downloadCsv')}}
                    </download-excel>
                    <button v-if="!auth.userInfo.username.includes('@')" :style="`display:${roleUtil.authorizePrivilege('MTO', 'CREATE')?'':'none'};`" @click="showModal = !showModal" class="btn btn-sm pd-x-15 btn-secondary btn-uppercase mg-l-5">Add New User</button>
                </div>
            </div>
            <base-top-cards :carditems="cards"/>
            <hr>
            <base-table
                    :items="users"
                    :fields="fields"
                    :show-title="true"
                    :is-busy="user.userLoading"
                    class="mt-2"
                    filter-mode="balances"
            ></base-table>
        </div>
      <create-mto-user-modal :show="showModal" :row="{}" module="mto" />
    </div>
</template>

<script>
    import BaseTopCards from "../../components/card/BaseTopCards";
    import BaseTable from "../../components/table/BaseTable";
    // import CreateUserModal from "@/components/modal/CreateUserModal";
    // import BaseButton from "../../components/button/BaseButton";
    import { PrinterIcon } from 'vue-feather-icons'
    import {mapState} from 'vuex'
    import CreateMtoUserModal from "@/components/modal/CreateMtoUserModal";
    import {RoleUtil} from "@/utility/RoleUtil";

    export default {
        name: 'User',
        data() {
            return {
                showModal: true,
                fields: [
                    { key: 'serial', label: 'S/N', sortable: true, sortDirection: 'desc' },
                    { key: 'userFirstName', label: 'First Name', sortable: true, sortDirection: 'desc' },
                    { key: 'userLastName', label: 'Last Name', sortable: true, class: 'text-left' },
                    { key: 'userEmail', label: 'Email', sortable: true, class: 'text-left' },
                    { key: 'roleName', label: 'Role', sortable: true, class: 'text-left' },
                    { key: 'userStatus', label: 'Status', sortable: true, class: 'text-left' },
                    // { key: 'userLastLogin', label: 'Last Login Date', sortable: true, sortDirection: 'desc' },
                    { key: 'userCreatedAt', label: 'Enrollment Date', sortable: true, class: 'text-left' },
                    { key: 'actionsMtoUser', label: 'Action', sortable: true, class: 'text-center' }
                ],
                model:{
                    userFirstName:'',
                    userLastName:'',
                    userEmail:'',
                    userRole:''
                }
            }
        },
        components: {
            BaseTable,
            BaseTopCards,
            CreateMtoUserModal,
            PrinterIcon
        },
        computed: {
          roleUtil(){
            return RoleUtil
          },
            cards:{
                get(){
                    return [
                        {
                            id: 0,
                            name: 'Total Users',
                            number: this.user.mtoUsers.totalUsers,// == null?'0.00':'GHS '+this.numberWithCommas(this.totalGhs),
                            color: 'bg-primary'
                        },
                        {
                            id: 1,
                            name: 'Total Pending Users',
                            number: this.user.mtoUsers.totalPendingUsers,// == null?'0.00':'GBP '+this.numberWithCommas(this.totalGbp),
                            color: 'bg-orange'
                        },
                        {
                            id: 2,
                            name: 'Total Active Users',
                            number: this.user.mtoUsers.totalActiveUsers,// == null?'0.00':'USD '+this.numberWithCommas(this.totalUsd),
                            color: 'bg-success'
                        },
                        {
                            id: 3,
                            name: 'Total Inactive Users',
                            number: this.user.mtoUsers.totalOnlineUsers,// == null?'0.00':'EUR '+this.numberWithCommas(this.totalEur),
                            color: 'bg-black-8'
                        }
                    ]
                },
                set(){

                }
            },
            users(){
                return  this.user.mtoUsers.data
            },
            ...mapState([
                'auth',
                'user'
            ])
        },
        methods: {
            hideModal(){
                this.$refs['new-user-modal'].hide();
            },
            createUser: async function () {
                await this.$store.dispatch("auth/initiateEnrollment", this.model, {root: true}).then();
                this.$refs['new-user-modal'].hide();
                await this.$store.dispatch("auth/updateUsers", {
                    request: 'users'
                }, {root: true}).then();
            },
            numberWithCommas(x) {
                let parts = x.toString().split(".");
                if (parts[1] == null)  parts[1] = '00'
                else if (parts[1].length === 0) parts[1] = parts[1]+'00'
                else if (parts[1].length === 1) parts[1] = parts[1]+'0'
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            },
        },
        created() {
            let mtoCode = this.auth.userInfo.userMtoCode?this.auth.userInfo.userMtoCode:localStorage.mtoCode
            if (mtoCode === '') mtoCode = localStorage.mtoCode
            this.$store.dispatch("user/updateMtoUsers", {userMtoCode: mtoCode,source: 'WEB'}, {root: false}).then()
        }
    }


</script>

<style scoped>

</style>
