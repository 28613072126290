<template>
    <b-modal id="skill-create-modal" :hide-header="true" :hide-footer="true" :centered="true" ref="skill-create-modal">
        <div class="modal-body">
            <a href="#" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"
               @click="hide()">
                <span aria-hidden="true">&times;</span>
            </a>
            <div>
                <h4 v-if="model.type === 'edit'">Create New Periodic Report</h4>
                <h4 v-else>Create New Periodic Report</h4>
                <b-form @submit.prevent="submit" enctype="multipart/form-data">
                    <b-form-group label="List of Report">
                      <b-input v-if="mode === 'edit'" :value="model.scheduleReportId" disabled></b-input>
                      <b-form-select v-else  v-model="model.scheduleReportId" >
                        <b-select-option value="">Select Report</b-select-option>
                        <b-select-option v-for="report in reports" :value="report.reportName" v-bind:key="report.reportName">{{ report.reportName }}</b-select-option>
                      </b-form-select>
                    </b-form-group>
                  <b-form @submit.prevent="submit" enctype="multipart/form-data">
                    <b-form-group label="Frequency">
                      <b-select v-model="model.scheduleFrequency">
                        <b-select-option value="">Select Frequency</b-select-option>
                        <b-select-option value="Daily">Daily</b-select-option>
                        <b-select-option value="Weekly">Weekly</b-select-option>
                        <b-select-option value="Monthly">Monthly</b-select-option>
                      </b-select>
                    </b-form-group>
                    <b-form-group label="Delivery file format">
                      <b-select v-model="model.scheduleFormat">
                        <b-select-option value="">Select Format</b-select-option>
                        <b-select-option value="CSV">CSV</b-select-option>
                        <b-select-option value="PDF">PDF</b-select-option>
                        <b-select-option value="EXCEL">EXCEL</b-select-option>
                      </b-select>
                    </b-form-group>
                    <b-form-group label="Destination Email">
                        <input type="text" class="form-control"  placeholder="Destination Email" required v-model="model.scheduleEmail"/>
                    </b-form-group>
                    <b-form-group label="Status">
                      <b-select v-model="model.scheduleStatus">
                        <b-select-option value="">Select Status</b-select-option>
                        <b-select-option value="ACTIVE">ACTIVE</b-select-option>
                        <b-select-option value="DISABLED">DISABLED</b-select-option>
                        <b-select-option value="DELETED">DELETED</b-select-option>
                      </b-select>
                    </b-form-group>
                    <base-button title="Submit" :loading="report.reportsLoading"/>
                </b-form>
                </b-form>
            </div>
        </div><!-- modal-body -->
    </b-modal>
</template>
<script>
    import { mapState } from "vuex"
    import BaseButton from "../button/BaseButton";
    // import swal from "sweetalert";
    export default {
        props:['show','row','mode'],
        components:{BaseButton},
        name: 'PeriodicReportModal',
        data(){
            return{
                password: '',
                model:{
                  scheduleReportId:"",
                  scheduleFrequency:"",
                  scheduleFormat:"",
                  scheduleEmail:"",
                  scheduleMaker:"",
                  scheduleChecker:"",
                  scheduleStatus:""
                },
              reportsId:"",
              periodicReport:[],
            }
        },
        methods:{
            filterOutBadChar(){
              this.model.mtoUserFirstName = this.model.mtoUserFirstName.replace(/[^A-Z0-9\s]/i, '')
              this.model.mtoUserLastName = this.model.mtoUserLastName.replace(/[^A-Z0-9\s]/i, '')
              this.model.mtoCode = this.model.mtoCode.replace(/[^A-Z0-9\s]/i, '')
              this.model.mtoAccounts = this.model.mtoAccounts.replace(/[^0-9,\s]/i, '')
              this.model.mtoPhone = this.model.mtoPhone.replace(/[^0-9]/i, '')
            },
            hide() {
                this.$refs['skill-create-modal'].hide()
            },
            onSelect({name, iso2, dialCode}) {
              this.model.mtoCountryCode =  dialCode
              console.log(name, iso2, dialCode);
            },
            async submit(){
              this.model.scheduleMaker = this.$store.state.auth.userInfo.userId
              if (this.mode === 'edit')
                await this.$store.dispatch("report/updatePeriodicReport", this.model)
              else
                await this.$store.dispatch("report/createPeriodicReport", this.model)
              this.$store.dispatch("report/readPeriodicReport", {readAll: 'YES',source: 'WEB'}, {root: false}).then()
              this.hide()
            },
            gradeCode(){
                return  localStorage._gradeCode
            },
            loadGrade(){
                this.$store.dispatch("grade/updateGradeSalaries", {
                    readAll:'NO',
                    employerGradeSalaryEmployerId: localStorage.orgId,
                    employerGradeSalaryGradeCode: localStorage._gradeCode
                }, {root: false}).then()
            },
            hideauth: function () {
                this.password = '';
                this.$refs['skill-create-modal'].hide();
            },
            currentDate(){
                let date = new Date()
                date = this.$moment(date.toDateString()).format('YYYY-MM-DD')
                console.log(date)
                return date
            },
            periodicReportFun(reports){
                this.periodicReport=reports.map((item)=>{
                  return{
                    value:item.reportId,
                    text:item.reportName
                  }
                })
            }
        },
        mounted() {
          this.$store.dispatch("accountingRule/updateAccountingRules", {readAll: 'YES',source: 'WEB'}, {root: false}).then()
          // console.log(this.$store.state.report.reports)
          // this.periodicReportFun(this.report.reports)

          this.$store.dispatch('report/readReports', {
                reportBankCode: '00000',
                readAll: 'YES',
                reportCategory: 'GENERAL SERVICES'
              }
          )
          this.$store.dispatch('report/readTables', {reportBankCode: '00004'})

        },
        computed:{
          reports(){
            return this.$store.state.report.reports;
          },
            ...mapState(['auth','user','accountingRule','report'])
        },
        watch: {
            show: function () {
                let self = this;
              // eslint-disable-next-line no-unused-vars
                Object.keys(this.model).forEach(function(key,index) {
                  self.model[key] = '';
                });
                // eslint-disable-next-line no-unused-vars
                Object.keys(this.row).forEach(function(key,index) {
                    self.model[key] = self.row[key];
                });
                //this.model.accounts = this.model.mtoAccounts.split(',')
                this.$refs['skill-create-modal'].show()
            }
        },
    }
</script>
