import apiClient from "./BaseService";

export default {
    callReportCreateApi(payload){
        return apiClient.post("report/create", payload)
    },
    callReportReadApi(payload){
        return apiClient.post("report/read", payload)
    },
    callReportReadSingleApi(payload){
        return apiClient.post("report/readSingle", payload)
    },
    callReportUpdateApi(payload){
        return apiClient.post("report/update", payload)
    },
    callReportUpdateStatusApi(payload){
        return apiClient.post("report/updateStatus", payload)
    },
    callReportReadColumnApi(payload){
        return apiClient.post("report/readColumn", payload)
    },
    callReadTablesApi(payload){
        return apiClient.post("report/readTables", payload)
    },
    callPeriodicReportApi(payload){
        return apiClient.post("report/createScheduler", payload)
    },
    callUpdatePeriodicReportApi(payload){
        return apiClient.post("report/updateScheduler", payload)
    },
    readPeriodicReportApi(payload){
        return apiClient.post("report/readScheduler", payload)
    }

};

