<template>
    <div id="app">
<!--        <loading :active.sync="auth.a"-->
<!--                 :can-cancel="false"-->
<!--                 loader="dots"-->
<!--                 color="#6d0606"-->
<!--                 :is-full-page="true"></loading>-->
        <transition name="fade" mode="out-in">
            <auth-layout v-if="layout === 'auth-layout'"></auth-layout>
            <app-layout v-if="layout === 'default-layout'"></app-layout>
            <account-layout v-if="layout === 'account-layout'"></account-layout>
            <payment-layout v-if="layout === 'payments-layout'"></payment-layout>
            <collection-layout v-if="layout === 'collection-layout'"></collection-layout>
            <on-boarding-layout v-if="layout === 'on-boarding-layout'"></on-boarding-layout>
            <loader-layout v-if="layout === 'loader-layout'"></loader-layout>
            <not-found-layout v-if="layout === '404-layout'"></not-found-layout>
        </transition>
        <v-tour name="myTour" :steps="steps"></v-tour>
        <notifications group="app" />
    </div>
</template>

<script>
    import AppLayout from "./views/template/layouts/AppLayout";
    import AuthLayout from  './views/template/layouts/AuthLayout'
    import AccountLayout from "./views/template/layouts/AccountLayout";
    import PaymentLayout from "./views/template/layouts/PaymentLayout";
    import OnBoardingLayout from "./views/template/layouts/OnBoardingLayout";
    import LoaderLayout from "./views/template/layouts/component/LoaderLayout";
    import CollectionLayout from "./views/template/layouts/CollectionLayout";
    import NotFoundLayout from "./views/template/layouts/component/NotFoundLayout";
    //import ClientJS from 'clientjs'
    // import './assets/js/fingerprint2.min'

    import {mapState} from 'vuex'
    // Import component
    // import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';

    const default_layout = "default";

    // let fingerprint = require('./assets/js/fingerprint2.min');

    export default {
        components:{
            AppLayout,
            AuthLayout,
            AccountLayout,
            OnBoardingLayout,
            PaymentLayout,
            LoaderLayout,
            CollectionLayout,
            NotFoundLayout
        },
        data() {
            return {
                finger: '',
                //isLoading: false,
                steps: [
                    {
                        target: '#v-step-0',  // We're using document.querySelector() under the hood
                        header: {
                            title: 'Get Started',
                        },
                        content: `Welcome to your <strong>Dashboard</strong>. Here you can monitor the activity and status of your account !`,
                        params: {
                            placement: 'left' // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
                        }
                    },
                    {
                        target: '#v-step-1',
                        header: {
                            title: 'Details',
                        },
                        content: 'Here you can view all the details about your company',
                        params: {
                            placement: 'left' // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
                        }
                    },
                    {
                        target: '#officer',
                        header: {
                            title: 'Officers',
                        },
                        content: 'Assign account role. The roles include signatory, Director, Developer or customer service. (Note that Developer and Customer service have limited access to this account)',
                        params: {
                            placement: 'left' // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
                        }
                    },
                    {
                        target: '#document',
                        header: {
                            title: 'Documents',
                        },
                        content: 'kindly provide required company documents. Documents provided will be accessed and verified by Rubies team.',
                        params: {
                            placement: 'left' // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
                        }
                    },
                    {
                        target: '#createuser',
                        header: {
                            title: 'Create User',
                        },
                        content: 'Creating a user will give access to Initiating transactions (bulk and single), view balance, generate account statement and more.',
                        params: {
                            placement: 'left' // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
                        }
                    }
                ]
            }
        },
        computed: {
            ...mapState([
                'auth'
            ]),
            layout() {
                return (this.$route.meta.layout || default_layout) + '-layout';
            },
        },
        methods:{
        },
        beforeCreate() {
            // this.initDashboard()
          // console.log(this.$fingerprint)
            this.$fingerprint.get((components) => {
                this.finger = this.$fingerprint.x64hash128(components.map((pair) => { return pair.value }).join(), 31)
                console.log(this.finger)
                localStorage.xrh = this.finger
                this.$store.commit('auth/updateDeviceId', this.finger)
            });

        },
        mounted() {
          // console.log('Password>>>>>: ', btoa('P@ssW00d1894#'))
          // fetch('https://api.ipify.org?format=json')
          //   .then(x => x.json())
          //   .then(({ ip }) => {
          //     localStorage._ipAddress = ip;
          //   });
            //this.$router.push('/preloader')
          const mDnsSd = require('node-dns-sd');

          mDnsSd.discover({
            name: '4cae8d7e-2b47-4fd7-af46-f933ec6b0ba1.local'
          }).then((device_list) =>{
            console.log('device_list',JSON.stringify(device_list, null, '  '));
          }).catch((error) => {
            console.error(error);
          });
          console.log("SHA512 === ",crypto.subtle.digest("SHA-512", new TextEncoder("utf-8").encode("CORALPAY TECHNOLOGY NIGERIA LIMITED-Tra846991201550000.00")))
        },
    }

    /* ES6 */
    const findLocalIp = (logInfo = true) => new Promise( (resolve, reject) => {
      window.RTCPeerConnection = window.RTCPeerConnection
          || window.mozRTCPeerConnection
          || window.webkitRTCPeerConnection;

      if ( typeof window.RTCPeerConnection == 'undefined' )
        return reject('WebRTC not supported by browser');

      let pc = new RTCPeerConnection();
      let ips = [];

      pc.createDataChannel("");
      pc.createOffer()
          .then(offer => pc.setLocalDescription(offer))
          .catch(err => reject(err));
      pc.onicecandidate = event => {
        if ( !event || !event.candidate ) {
          // All ICE candidates have been sent.
          if ( ips.length == 0 )
            return reject('WebRTC disabled or restricted by browser');

          return resolve(ips);
        }
        let parts = event.candidate.candidate.split(' ');
        let [base,componentId,protocol,priority,ip,port,,type,...attr] = parts;
        let component = ['rtp', 'rtpc'];

        if ( ! ips.some(e => e == ip) )
          ips.push(ip);

        if ( ! logInfo )
          return;
        let privateIp = ip
        fetch('https://api.ipify.org?format=json')
            .then(x => x.json())
            .then(({ ip }) => {
              localStorage._ipAddress = `${ip} | ${privateIp}`;
              localStorage.candidate = base.split(':')[1];
              localStorage.component = component[componentId - 1];
              localStorage.protocol = protocol;
              localStorage.priority = priority;
              localStorage.port = port;
              localStorage.netType = type;
        }).catch(err=>{
          console.error(err)
          localStorage._ipAddress = `${privateIp}`;
        });

        if ( attr.length ) {
          console.log("attributes: ");
          for(let i = 0; i < attr.length; i += 2)
            console.log("> " + attr[i] + ": " + attr[i+1]);
        }

        // console.log();
      };
    } );
    findLocalIp().then(
        ips => {
          ips.forEach( ip => console.log(ip)  );
        },
        err => console.log(err)
    );
</script>



<style>
    @import "assets/lib/@fortawesome/fontawesome-free/css/all.min.css";
    @import "assets/lib/ionicons/css/ionicons.min.css";
    @import "assets/lib/jqvmap/jqvmap.min.css";
    @import "assets/css/dashforge.css";
    @import "assets/css/dashforge.dashboard.css";
    @import "assets/css/dashforge.contacts.css";
    @import "assets/lib/typicons.font/typicons.css";
    @import "assets/lib/prismjs/themes/prism-vs.css";
    @import "assets/lib/datatables.net-dt/css/jquery.dataTables.min.css";
    @import "assets/lib/datatables.net-responsive-dt/css/responsive.dataTables.min.css";
    @import "assets/lib/select2/css/select2.min.css";
    @import "assets/css/dashforge.chat.css";
    @import "assets/css/dashforge.mail.css";
    @import "assets/css/dashforge.contacts.css";
</style>

