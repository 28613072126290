import ProprietorService from "../../service/ProprietorService";
import swal from 'sweetalert'
//import router from "../../router";



export const state = {
    proprietors:{
        responseCode: "00",
        responseMessage: "Success",
        totalBorrowers: "0",
        totalLoans: "0",
        maleProprietors: "0",
        femaleProprietors: "0",
        data: []
    },
    proprietorLoading: false,
    response:{}

}

export const mutations = {
    updateProprietors: (state, payload) =>{
        state.proprietors = payload
    },
    updateProprietorLoading: (state, payload) =>{
        state.proprietorLoading = payload
    },
    updateResponse: (state, payload) =>{
        state.response = payload
    },
}

export const actions = {
    updateProprietors: ({ commit, state }, payload)=>{
        if(state.proprietors.data.length < 1)
            commit("updateProprietorLoading", true)
        return ProprietorService.callProprietorReadAllApi(payload)
            .then(response => {
                commit("updateProprietorLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updateProprietorLoading", false)
                    let responseData = response.data;
                    commit("updateProprietors", responseData)
                }

            })
            .catch(error => {
                commit("updateProprietorLoading", false)
                alert(error)
                // throw error;
            });
    },
    createProprietor: ({ commit }, payload)=>{
        commit("updateProprietorLoading", true)
        return ProprietorService.callProprietorCreateApi(payload)
            .then(response => {
                commit("updateProprietorLoading", false)
                let responseData = response.data
                if (responseData.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success')
                }else{
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            .catch(error => {
                commit("updateProprietorLoading", false)
                alert(error)
                // throw error;
            });
    },
}