<template>
  <b-modal id="parameterization-create-modal" :hide-header="true" :hide-footer="true" :centered="true" ref="parameterization-create-modal">
    <div class="modal-body">
      <a href="#" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"
         @click="hide()">
        <span aria-hidden="true">&times;</span>
      </a>
      <div>
        <h4 v-if="model.type === 'edit'">Edit Parameter</h4>
        <h4 v-else>Add New Parameter</h4>
        <b-form @submit.prevent="submit" enctype="multipart/form-data">
          <b-form-group  label="MTO Code">
            <b-select v-if="model.type !== 'edit'" readonly v-model="model.parameterMtoCode">
              <b-select-option value="">Select MTO</b-select-option>
              <b-select-option v-for="mto in mtos" :value="mto.mtoCode" v-bind:key="mto.mtoCode">{{ mto.mtoName }}</b-select-option>
            </b-select>
            <b-input v-else required readonly v-model="model.parameterMtoCode" placeholder="MTO Code" />
          </b-form-group>
          <b-form-group  label="Payout Currency">
            <b-select required v-model="model.parameterPayoutCurrency">
              <b-select-option value="EUR">EUR</b-select-option>
              <b-select-option value="USD">USD</b-select-option>
              <b-select-option value="NGN">NGN</b-select-option>
              <b-select-option value="CDF">CDF</b-select-option>
              <b-select-option value="GHC">GHC</b-select-option>
            </b-select>
          </b-form-group>
          <b-form-group  label="Cash Enabled">
            <b-select required v-model="model.parameterCashEnabled">
              <b-select-option value="YES">YES</b-select-option>
              <b-select-option value="NO">NO</b-select-option>
            </b-select>
          </b-form-group>
          <b-form-group  label="Direct Credit(Intra) Enabled">
            <b-select required v-model="model.parameterIntraDcEnabled">
              <b-select-option value="YES">YES</b-select-option>
              <b-select-option value="NO">NO</b-select-option>
            </b-select>
          </b-form-group>
          <b-form-group  label="Direct Credit(Other Bank) Enabled">
            <b-select required v-model="model.parameterInterDcEnabled">
              <b-select-option value="YES">YES</b-select-option>
              <b-select-option value="NO">NO</b-select-option>
            </b-select>
          </b-form-group>
          <b-form-group v-if="model.parameterCashEnabled === 'YES'" label="Cash Transaction Limit">
            <b-input type="number" step="0.01"  placeholder="Cash Transaction Limit" required v-model="model.parameterCashLimit"/>
          </b-form-group>
          <b-form-group v-if="model.parameterIntraDcEnabled === 'YES'" label="Direct Credit(Intra) Transaction Limit">
            <b-input type="number" step="0.01"  placeholder="Cash Transaction Limit" required v-model="model.parameterIntraDcLimit"/>
          </b-form-group>
          <b-form-group v-if="model.parameterInterDcEnabled === 'YES'" label="Direct Credit(Other Bank) Transaction Limit">
            <b-input type="number" step="0.01"  placeholder="Cash Transaction Limit" required v-model="model.parameterInterDcLimit"/>
          </b-form-group>
          <b-form-group v-if="model.parameterCashEnabled === 'YES'" label="Cash Max. Daily Transaction Count">
            <b-input type="number" step="0.01"  placeholder="Cash Max. Daily Transaction Count" required v-model="model.parameterTrnCountCashLimit"/>
          </b-form-group>
          <b-form-group v-if="model.parameterIntraDcEnabled === 'YES'" label="Direct Credit(Intra) Max. Daily Transaction Count">
            <b-input type="number" step="0.01"  placeholder="Direct Credit(Intra) Max. Daily Transaction Count" required v-model="model.parameterTrnCountIntraDcLimit"/>
          </b-form-group>
          <b-form-group  v-if="model.parameterInterDcEnabled === 'YES'" label="Direct Credit(Other Bank) Max. Daily Transaction Count">
            <b-input type="number" step="0.01"  placeholder="Direct Credit(Other Bank) Max. Daily Transaction Count" required v-model="model.parameterTrnCountInterDcLimit"/>
          </b-form-group>
<!--          <b-form-group v-if="model.type === 'edit'" label="Status">-->
<!--            <b-select required v-model="model.parameterStatus">-->
<!--              <b-select-option value="APPROVED">Active</b-select-option>-->
<!--              <b-select-option value="DELETED">Delete</b-select-option>-->
<!--            </b-select>-->
<!--          </b-form-group>-->
          <!--                    <b-form-group label="Status">-->
          <!--                        <b-select required v-model="model.employerGradeSalaryItemType">-->
          <!--                            <b-select-option  value="ACTIVE">ACTIVE</b-select-option>-->
          <!--                            <b-select-option  value="DELETED">DELETE</b-select-option>-->
          <!--                        </b-select>-->
          <!--                    </b-form-group>-->
          <base-button title="Submit" :loading="mto.parameterLoading"/>
        </b-form>
      </div>
    </div><!-- modal-body -->
  </b-modal>
</template>
<script>
import { mapState } from "vuex"
import BaseButton from "../button/BaseButton";
export default {
  props:['show','row'],
  components:{BaseButton},
  name: 'ConfirmModal',
  data(){
    return{
      password: '',
      model:{
        parameterCashEnabled: '',
        parameterIntraDcEnabled: '',
        parameterInterDcEnabled: '',
        parameterCashLimit: '',
        parameterIntraDcLimit: '',
        parameterInterDcLimit: '',
        parameterTrnCountCashLimit: '',
        parameterTrnCountIntraDcLimit: '',
        parameterTrnCountInterDcLimit: '',
        parameterPayoutCurrency: '',
        parameterMtoCode: '',
        parameterMaker: '',
        parameterChecker: ''
      },
    }
  },
  methods:{
    hide() {
      this.$refs['parameterization-create-modal'].hide()
    },
    async submit(){
      this.model.parameterMaker = this.auth.userInfo.userId
      this.model.parameterChecker = 'SYSTEM'
      this.model.parameterCashLimit = this.model.parameterCashLimit!==''?this.model.parameterCashLimit:'0.00'
      this.model.parameterIntraDcLimit = this.model.parameterIntraDcLimit!==''?this.model.parameterIntraDcLimit:'0.00'
      this.model.parameterInterDcLimit = this.model.parameterInterDcLimit!==''?this.model.parameterInterDcLimit:'0.00'
      this.model.parameterTrnCountCashLimit = this.model.parameterTrnCountCashLimit!==''?this.model.parameterTrnCountCashLimit:'0'
      this.model.parameterTrnCountIntraDcLimit = this.model.parameterTrnCountIntraDcLimit!==''?this.model.parameterTrnCountIntraDcLimit:'0'
      this.model.parameterTrnCountInterDcLimit = this.model.parameterTrnCountInterDcLimit!==''?this.model.parameterTrnCountInterDcLimit:'0'
      //this.model.parameterPayoutCurrency = this.model.parameterPayoutCurrency!==''?this.model.parameterPayoutCurrency:'0.00'
      if (this.model.type === 'edit')
        await this.$store.dispatch("mto/editParameter", this.model, {root: false});
      else {
        //this.model.parameterEmployeeId = this.row.employeeId
        await this.$store.dispatch("mto/createParameter", this.model, {root: false});
      }
      this.hide();
      this.$store.dispatch('mto/updateParameters', {
        readAll: 'NO'
      })
      let self = this;
      // eslint-disable-next-line no-unused-vars
      Object.keys(this.model).forEach(function(key,index) {
        self.model[key] = '';
      });

    },
    gradeCode(){
      return  localStorage._gradeCode
    },
    loadData(){
      this.$store.dispatch("mto/updateParameters", {
        readAll:'NO'
      }, {root: false}).then()
    },
    hideauth: function () {
      this.password = '';
      this.$refs['parameterization-create-modal'].hide();
    },
    currentDate(){
      let date = new Date()
      date = this.$moment(date.toDateString()).format('YYYY-MM-DD')
      console.log(date)
      return date
    },
  },
  mounted() {
    //this.model = this.row readSkillCategory
    //this.$store.dispatch('employee/readSkillCategory',{readAll:"YES"})
  },
  computed:{
    ...mapState(['user','auth','mto']),
    mtos(){
      return  this.user.mtos.data
    },
  },
  watch: {
    show: function () {
      let self = this;
      // eslint-disable-next-line no-unused-vars
      Object.keys(this.model).forEach(function(key,index) {
        self.model[key] = '';
      });
      //this.$store.dispatch('mto/updateParameterCategory',{readAll:"YES"})
      // eslint-disable-next-line no-unused-vars
      Object.keys(this.row).forEach(function(key,index) {
        self.model[key] = self.row[key];
      });
      this.$store.dispatch("user/updateMtos", {cqMode: 'READ',source: 'WEB'}, {root: false}).then()
      this.$refs['parameterization-create-modal'].show()
    }
  },
}
</script>
