<template>
    <div class="content ht-100v pd-0">
        <div class="content-body pd-10">
            <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
              <div>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                    <li class="breadcrumb-item">
                      <router-link to="#">Sandbox</router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">Api Keys</li>
                  </ol>
                </nav>
                <h4 class="mg-b-0 tx-spacing--1">Api Keys</h4>
                <p class="tx-color-03 tx-12 mg-b-0">{{auth.userInfo.userFirstName}} {{auth.userInfo.userLastName}}</p>
                <p class="tx-color-03 tx-12 mg-b-0"><strong>Last login:</strong> {{auth.userInfo.userLastLogin | moment('dddd, MMMM Do YYYY, h:mm:ss a')}}</p>
              </div>
                <div class="d-none d-md-block">
<!--                    <router-link to="/new-officer" class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5"><i data-feather="plus" class="wd-10 mg-r-5"></i> New Officer</router-link>-->
<!--                    <button style="background-color: #6d0606; border-color: #6d0606;" class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">-->
<!--                        <download-excel-->
<!--                                class   = "lnr-cloud-download"-->
<!--                                style="cursor: pointer;"-->
<!--                                :data   = "company.company"-->
<!--                                worksheet = "My Worksheet"-->
<!--                                :name    = "$route.name+'.xls'">-->
<!--                            <span> <i data-feather="file" class="wd-10 mg-r-5"></i> Generate Report</span>-->
<!--                        </download-excel>-->
<!--                    </button>-->
                </div>
            </div>

            <!--TODO TOP CARDS-->
<!--            <top-cards></top-cards>-->
            <!--TODO GROWTH-->
            <settings-form></settings-form>
<!--          <iframe id="f" src="https://documenter.getpostman.com/view/4543866/TzRNGAgo"></iframe>-->
        </div><!-- container -->
    </div>
</template>

<script>
    // import TopCards from "../../components/card/TopCards";
    // import DetailsForm from "../../components/form/DetailsForm";
    // import {} from ''
    import SettingsForm from "../../components/form/SettingsForm";
    // import BaseTable from "../../components/table/BaseTable";
    import {mapState} from 'vuex'
    export default {
        name: 'Home',
        components:{
            // TopCards,
            SettingsForm,
        },
        data(){
            return{
                fields: [
                    { key: 'serial', label: 'S/N', sortable: true, sortDirection: 'desc' },
                    { key: 'officerFirstName', label: 'First Name', sortable: true, sortDirection: 'desc' },
                    { key: 'officerLastName', label: 'Surname', sortable: true, sortDirection: 'desc' },
                    { key: 'officerDesignation', label: 'Role', sortable: true, class: 'text-center' },
                    { key: 'officerAuthBank', label: 'Bank Verified', sortable: true, class: 'text-center' },
                    { key: 'officerAuthStatus', label: 'DIR/SIG Verified', sortable: true, class: 'text-center' },
                    { key: 'actionx', label: 'Action', sortable: true, class: 'text-center' }
                ],
            }
        },
        methods:{

        },
        computed:{
            ...mapState([
                'officer',
                'auth'
            ]),

            officers:{
                get(){
                    return this.officer.officers
                },
                set(val){
                    this.$store.commit('officer/updateOfficers', val)
                }
            },
        }
    }
</script>
