import axios from "axios";
import Vue from 'vue';
import VueToast from 'vue-toast-notification';
// Import one of the available themes
import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(VueToast);
import store from "../store/store";
import router from "../router";
import swal from "sweetalert";

const apiClient = axios.create({
    baseURL: window.__env.api.baseUrl,
    withCredentials: false,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
    }
});


apiClient.interceptors.request.use(config => {
    const token = store.getters.getToken;
    const tok = store.getters["auth/getTok"];
    config.headers.Authorization = (token == null)? tok: token;
    config.headers.ipaddress = localStorage._ipAddress;
    config.data = {payload: encrypt(JSON.stringify(config.data))}
    // console.log({payload: encrypt(JSON.stringify(config.data))})
    return config
});

apiClient.interceptors.response.use(config => {
    // console.log("config.data.payload:::",config)
    if (config != null)
        if (config.data != null) {
            if (config.data.payload != null) {
                config.data = JSON.parse(decrypt(config.data.payload))
            }
            if (config.data.responseCode === '92' || config.data.responseCode === '990') {
                swal('Session Expired!', config.data.responsemessage != null ? config.data.responsemessage : config.data.responseMessage, 'info')
                    localStorage.clear();
                    // console.log(res)
                    // console.log(res)
                    router.push('/').then()
            }
            if (config.data.responseCode === '999') {
                Vue.$toast.open({
                    message: config.data.responseMessage,
                    type: 'error',
                    position: 'top-right'
                    // all of other options may go here
                });
            }
        }
    return config
});

let secrete = "ZXlKeVpYTndiMjV6WldOdlpHVWlPaUl3TUNJc0luSmxjM0J2Ym5ObGJXVnpjMkZuWlNJNklsVnpaWElnWkc4Z1pYaHBjM1FpZlE="
const cryptLib = require('@skavinvarnan/cryptlib');

function encrypt(messageToEncrypt = JSON.stringify({payload: ""})){
    return cryptLib.encryptPlainTextWithRandomIV(messageToEncrypt, secrete);
}

function decrypt(messageToDecrypt = ''){
    try { return  cryptLib.decryptCipherTextWithRandomIV(messageToDecrypt, secrete); }
    catch(err) { console.log(err) }
}

console.log('bibi',decrypt("0cp/btiA0U5p7XF+Orhs0Gt37rNbRlOsEMJQj9FlWw1xVViV2/dE839TEjOZls4MtfibWwJyhIFcbYz9LoX/QWOntzjtwTRRqmyzJRvLFJpKLdLbyzfzKQkCDZje6cD2"))



export default apiClient