import CashPickupService from "../../service/CashPickupService";
import swal from 'sweetalert'
//import router from "../../router";



export const state = {
    cashPickups:{
        responseCode: "00",
        responseMessage: "Success",
        data: []
    },
    cashPickup:{
        responseCode: "00",
        responseMessage: "Success"
    },
    history:{
        responseCode: "00",
        responseMessage: "Success",
        data: []
    },
    cashPickupLoading: false,
    response:{}

}

export const mutations = {
    updateCashPickups: (state, payload) =>{
        state.cashPickups = payload
    },
    updateCashPickup: (state, payload) =>{
        state.cashPickup = payload
    },
    updateCashPickupLoading: (state, payload) =>{
        state.cashPickupLoading = payload
    },
    updateResponse: (state, payload) =>{
        state.response = payload
    },
    updateHistory: (state, payload) =>{
        state.history = payload
    },
}

export const actions = {
    updateCashPickups: ({ commit, state }, payload)=>{
        if(state.cashPickups.data.length < 1)
            commit("updateCashPickupLoading", true)
        return CashPickupService.callReadApi(payload)
            .then(response => {
                commit("updateCashPickupLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updateCashPickupLoading", false)
                    let responseData = response.data;
                    commit("updateCashPickups", responseData)
                }

            })
            .catch(error => {
                commit("updateCashPickupLoading", false)
                alert(error)
                // throw error;
            });
    },
    updateHistory: ({ commit }, payload)=>{
        commit("updateCashPickupLoading", true)
        return CashPickupService.callReadHistoryApi(payload)
            .then(response => {
                commit("updateCashPickupLoading", false)
                if (response.data.responseCode === "00") {
                    let responseData = response.data;
                    commit("updateHistory", responseData)
                }

            })
            .catch(error => {
                commit("updateCashPickupLoading", false)
                alert(error)
                // throw error;
            });
    },
    updateCashPickupsMto: ({ commit, state }, payload)=>{
        if(state.cashPickups.data.length < 1)
            commit("updateCashPickupLoading", true)
        return CashPickupService.callReadByImtoApi(payload)
            .then(response => {
                commit("updateCashPickupLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updateCashPickupLoading", false)
                    let responseData = response.data;
                    commit("updateCashPickups", responseData)
                }

            })
            .catch(error => {
                commit("updateCashPickupLoading", false)
                alert(error)
                // throw error;
            });
    },
    updateCashPickupsReference: ({ commit, state }, payload)=>{
        if(state.cashPickups.data.length < 1)
            commit("updateCashPickupLoading", true)
        return CashPickupService.callQueryReferenceApi(payload)
            .then(response => {
                commit("updateCashPickupLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updateCashPickupLoading", false)
                    let responseData = response.data;
                    commit("updateCashPickup", responseData)
                }else{
                    swal('Error!', response.data.responseMessage, 'error')
                }

            })
            .catch(error => {
                commit("updateCashPickupLoading", false)
                alert(error)
                // throw error;
            });
    },
    createCashPickup: ({ commit }, payload)=>{
        commit("updateCashPickupLoading", true)
        return CashPickupService.callCreateApi(payload)
            .then(response => {
                commit("updateCashPickupLoading", false)
                let responseData = response.data
                if (responseData.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success')
                }else{
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            .catch(error => {
                commit("updateCashPickupLoading", false)
                alert(error)
                // throw error;
            });
    },
    uploadTransactionReceipt: ({ commit }, payload)=>{
        commit("updateCashPickupLoading", true)
        return CashPickupService.callUploadTransactionReceiptApi(payload)
            .then(response => {
                commit("updateCashPickupLoading", false)
                let responseData = response.data
                if (responseData.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success')
                }else{
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            .catch(error => {
                commit("updateCashPickupLoading", false)
                alert(error)
                // throw error;
            });
    },
    createTransactionReceipt: ({ commit }, payload)=>{
        commit("updateCashPickupLoading", true)
        return CashPickupService.callCreateTransactionReceiptApi(payload)
            .then(response => {
                commit("updateCashPickupLoading", false)
                let responseData = response.data
                if (responseData.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success')
                    window.location.href = responseData.encodedReceipt
                }else{
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            .catch(error => {
                commit("updateCashPickupLoading", false)
                alert(error)
                // throw error;
            });
    },
}