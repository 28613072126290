<template>
  <div class="content ht-100v pd-0">
    <div class="content-body pd-10">
      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
        <div>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-style1 mg-b-10">
              <li class="breadcrumb-item">
                <router-link to="#">Transaction</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">All Transaction AML Queue</li>
            </ol>
          </nav>
          <h4 class="mg-b-0 tx-spacing--1">View All Transaction AML Queue</h4>
          <p class="tx-color-03 tx-12 mg-b-0">{{auth.userInfo.userFirstName}} {{auth.userInfo.userLastName}}</p>
          <p class="tx-color-03 tx-12 mg-b-0"><strong>Last login:</strong> {{auth.userInfo.userLastLogin | moment('dddd, MMMM Do YYYY, h:mm:ss a')}}</p>
        </div>
        <div class="d-none d-md-block">
          <download-excel
              :data="transactions"
              class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
            <PrinterIcon class="mg-r-5"/>{{$t('extra.downloadExcel')}}
          </download-excel>
          <download-excel
              type="csv"
              :data="transactions"
              class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
            <PrinterIcon class="mg-r-5"/>{{$t('extra.downloadCsv')}}
          </download-excel>

        </div>
      </div>
      <base-top-cards :carditems="cards"/>
      <hr>

      <base-table
          :items="transactions"
          :fields="fields"
          :show-title="true"
          :is-busy="transaction.transactionLoading"
          class="mt-2"
          filter-mode="balances"
      ></base-table>
    </div>
  </div>
</template>

<script>
import BaseTopCards from "../../components/card/BaseTopCards";
import BaseTable from "../../components/table/BaseTable";
import { PrinterIcon } from 'vue-feather-icons'
import {mapState} from 'vuex'

export default {
  name: 'Transaction',
  data() {
    return {
      fields: [
        { key: 'serial', label: 'S/N', sortable: true, sortDirection: 'desc' },
        { key: 'transactionMtoCode', label: 'MTO Code', sortable: true, sortDirection: 'desc' },
        { key: 'transactionBatchReference', label: 'Batch Ref', sortable: true, class: 'text-left' },
        { key: 'transactionContractReference', label: 'Trn Ref', sortable: true, class: 'text-left' },
        { key: 'transactionSourceCountryCurrency', label: 'Source CCY', sortable: true, class: 'text-right' },
        { key: 'transactionCurrency', label: 'S. CCY', sortable: true, class: 'text-right' },
        { key: 'transactionAmount', label: 'S. Amount', sortable: true, class: 'text-right' },
        { key: 'transactionDestinationAmount', label: 'Destination Amount', sortable: true, class: 'text-right' },
        // { key: 'transRate', label: 'Rate', sortable: true, class: 'text-right' },
        { key: 'transactionSourceCountryCurrency', label: 'S. CCY', sortable: true, class: 'text-left' },
        { key: 'transactionSourceCountryCode', label: 'S. Country', sortable: true, sortDirection: 'desc', class: 'text-left' },
        // { key: 'transSender', label: 'Sender', sortable: true, class: 'text-left' },
        // { key: 'transactionDestinationCountryCode', label: 'Destination Country', sortable: true, class: 'text-left' },
        // { key: 'transactionDestinationCountryCurrency', label: 'Destination CCY', sortable: true, class: 'text-left' },
        { key: 'transactionReceiverName', label: 'R. Name', sortable: true, class: 'text-left' },
        // { key: 'transactionReceiverLastName', label: 'R. LastName', sortable: true, class: 'text-left' },
        { key: 'transactionStatus', label: 'Status', sortable: true, class: 'text-left' },
        { key: 'actionsTransactionPending', label: 'Action', sortable: true, class: 'text-center' }
      ],
    }
  },
  components: {
    BaseTable,
    BaseTopCards,
    PrinterIcon,


  },
  computed: {
    cards:{
      get(){
        return [
          {
            id: 0,
            name: 'Total Transactions',
            number: this.totalCount,// == null?'0.00':'GHS '+this.numberWithCommas(this.totalGhs),
            color: 'bg-primary'
          },
          {
            id: 1,
            name: 'AML Screened',
            number: this.totalPending,// == null?'0.00':'GBP '+this.numberWithCommas(this.totalGbp),
            color: 'bg-orange'
          },
          {
            id: 2,
            name: 'Successful',
            number: this.totalPaid,// == null?'0.00':'USD '+this.numberWithCommas(this.totalUsd),
            color: 'bg-success'
          },
          {
            id: 3,
            name: 'Unsuccessful',
            number: this.totalReceived,// == null?'0.00':'EUR '+this.numberWithCommas(this.totalEur),
            color: 'bg-black-8'
          }
        ]
      },
      set(){

      }
    },
    transactions(){
      return  this.transaction.pendingTrn.data//.filter(it=>it.transactionStatus.toLowerCase() === 'aml gate')
    },
    authobj: function () {
      return this.$store.state.auth.response
    },
    ...mapState([
      'transaction',
      'auth'
    ]),
    totalCount(){
      let count = 0
      if (this.transaction.pendingTrn.data != null)
        count = this.transaction.pendingTrn.data.length
      return count
    },
    totalPaid(){
      let count = 0.00
      if (this.transaction.pendingTrn.data != null)
        count = this.transaction.pendingTrn.data.reduce((init, curr) => (
            init+=parseFloat(curr.transactionStatus.toLowerCase()==='posted'?'1':'0')
        ),0)
      return count
    },
    totalPending(){
      let count = 0.00
      if (this.transaction.pendingTrn.data != null)
        count = this.transaction.pendingTrn.data.reduce((init, curr) => (
            init+=parseFloat(curr.transactionStatus.toLowerCase()==='aml gate'?'1':'0')
        ),0)
      return count
    },
    totalReceived(){let count = 0.00
      if (this.transaction.pendingTrn.data != null)
        count = this.transaction.pendingTrn.data.reduce((init, curr) => (
            init+=parseFloat(curr.transactionStatus.toLowerCase()==='received'?'1':'0')
        ),0)
      return count
    },
  },
  methods: {
    numberWithCommas(x) {
      let parts = x.toString().split(".");
      if (parts[1] == null)  parts[1] = '00'
      else if (parts[1].length === 0) parts[1] = parts[1]+'00'
      else if (parts[1].length === 1) parts[1] = parts[1]+'0'
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    },
  },
  created() {
    this.$store.dispatch("transaction/updatePendingTrn", {requestType:'readPending'}, {root: false}).then()
  }
}


</script>

<style scoped>

</style>
