import TransactionService from "../../service/TransactionService";
import swal from 'sweetalert'
//import router from "../../router";



export const state = {
    dcTransactions:{
        responseCode: '',
        responseMessage: '',
        data: []
    },
    cpTransactions:{
        responseCode: '',
        responseMessage: '',
        data: []
    },
    cpTransaction:{
        responseCode: '',
        responseMessage: ''
    },
    dcHistory:{
        responseCode: '',
        responseMessage: '',
        data: []
    },
    pendingTrn:{
        responseCode: '',
        responseMessage: '',
        data: []
    },
    screen:'enquiry',
    transactionLoading: false,
    response:{}

}

export const mutations = {
    updateDcTransactions: (state, payload) =>{
        state.dcTransactions = payload
    },
    updateCpTransactions: (state, payload) =>{
        state.cpTransactions = payload
    },
    updateCpTransaction: (state, payload) =>{
        state.cpTransaction = payload
    },
    updateResponse: (state, payload) =>{
        state.response = payload
    },
    updateScreen: (state, payload) =>{
        state.screen = payload
    },
    updateDcHistory: (state, payload) =>{
        state.dcHistory = payload
    },
    updateTransactionLoading: (state, payload) =>{
        state.transactionLoading = payload
    },
    updatePendingTrn: (state, payload) =>{
        state.pendingTrn = payload
    },
}

export const actions = {
    updateDcTransactions: ({ commit, state }, payload)=>{
        if(state.dcTransactions.data.length < 1)
            commit("updateTransactionLoading", true)
        return TransactionService.callDcTransactionReadApi(payload)
            .then(response => {
                commit("updateTransactionLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updateTransactionLoading", false)
                    let responseData = response.data;
                    commit("updateDcTransactions", responseData)
                }

            })
            .catch(error => {
                commit("updateTransactionLoading", false)
                alert(error)
                // throw error;
            });
    },

    updateDcHistory: ({ commit }, payload)=>{
        commit("updateTransactionLoading", true)
        return TransactionService.callDcTransactionReadHistoryApi(payload)
            .then(response => {
                commit("updateTransactionLoading", false)
                if (response.data.responseCode === "00") {
                    let responseData = response.data;
                    commit("updateDcHistory", responseData)
                }

            })
            .catch(error => {
                commit("updateTransactionLoading", false)
                alert(error)
                // throw error;
            });
    },

    updatePendingTrn: ({ commit }, payload)=>{
        commit("updateTransactionLoading", true)
        return TransactionService.callDcTransactionReadPendingApi(payload)
            .then(response => {
                commit("updateTransactionLoading", false)
                if (response.data.responseCode === "00") {
                    let responseData = response.data;
                    commit("updatePendingTrn", responseData)
                }

            })
            .catch(error => {
                commit("updateTransactionLoading", false)
                alert(error)
                // throw error;
            });
    },

    updateCpTransactions: ({ commit, state }, payload)=>{
        if(state.cpTransactions.data.length < 1)
            commit("updateTransactionLoading", true)
        return TransactionService.callCpTransactionReadApi(payload)
            .then(response => {
                commit("updateTransactionLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updateTransactionLoading", false)
                    let responseData = response.data;
                    commit("updateCpTransactions", responseData)
                }

            })
            .catch(error => {
                commit("updateTransactionLoading", false)
                alert(error)
                // throw error;
            });
    },

    updateCpTransaction: ({ commit }, payload)=>{
        commit("updateTransactionLoading", true)
        return TransactionService.callCpTransactionReferenceApi(payload)
            .then(response => {
                commit("updateTransactionLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updateTransactionLoading", false)
                    let responseData = response.data;
                    commit("updateCpTransaction", responseData)
                    commit("updateScreen", 'mto')
                }else{
                    swal('Error!', response.data.responseMessage, 'error')
                }

            })
            .catch(error => {
                commit("updateTransactionLoading", false)
                alert(error)
                // throw error;
            });
    },

    createCpTransaction: ({ commit }, payload)=>{
        commit("updateTransactionLoading", true)
        return TransactionService.callCpTransactionCreateApi(payload)
            .then(response => {
                commit("updateTransactionLoading", false)
                let responseData = response.data
                if (responseData.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success')
                }else{
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            .catch(error => {
                commit("updateTransactionLoading", false)
                alert(error)
                // throw error;
            });
    },

    updateStatusDcTransaction: ({ commit }, payload)=>{
        commit("updateTransactionLoading", true)
        return TransactionService.callDcTransactionUpdateStatusApi(payload)
            .then(response => {
                commit("updateTransactionLoading", false)
                if (response.data.responseCode === "00") {
                    swal('Success!', response.data.responseMessage, 'success')
                }else{
                    swal('Error!', response.data.responseMessage, 'error')
                }

            })
            .catch(error => {
                commit("updateTransactionLoading", false)
                alert(error)
                // throw error;
            });
    },

    updateStatusCpTransaction: ({ commit }, payload)=>{
        commit("updateTransactionLoading", true)
        return TransactionService.callCpTransactionUpdateStatusApi(payload)
            .then(response => {
                commit("updateTransactionLoading", false)
                if (response.data.responseCode === "00") {
                    swal('Success!', response.data.responseMessage, 'success')
                }else{
                    swal('Error!', response.data.responseMessage, 'error')
                }

            })
            .catch(error => {
                commit("updateTransactionLoading", false)
                alert(error)
                // throw error;
            });
    },
}