import apiClient from "./BaseService";

export default {
    callTransactionCreateApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgID;
        return apiClient.post("createTransaction", payload);
    },
    callCpTransactionCreateApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgID;
        return apiClient.post("cashPickup/create", payload);
    },
    callCpTransactionReferenceApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgID;
        return apiClient.post("cashPickup/queryReference", payload);
    },
    callDcTransactionReadApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgID;
        return apiClient.post("imto/readDirectCredit", payload);
    },
    callDcTransactionReadHistoryApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgID;
        return apiClient.post("imto/readHistory", payload);
    },
    callDcTransactionReadPendingApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgID;
        return apiClient.post("imto/readPending", payload);
    },
    callCpTransactionReadApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgID;
        return apiClient.post("cashPickup/read", payload);
    },
    callDcTransactionUpdateStatusApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgID;
        return apiClient.post("imto/updateStatusDirectCredit", payload);
    },
    callCpTransactionUpdateStatusApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgID;
        return apiClient.post("cashPickup/updateStatus", payload);
    },
};
