import apiClient from "./BaseService";

export default {
  callCreateSlabApi(payload) {
    payload.orgId = localStorage.orgID;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.post("slabService/create", payload);
  },
  callReadSlabApi(payload) {
    payload.orgId = localStorage.orgID;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.post("slabService/read", payload);
  },
  callReadSingleSlabApi(payload) {
    payload.orgId = localStorage.orgID;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.post("slabService/readSingle", payload);
  },
  callAccountingRuleCreateApi(payload) {
    payload.orgId = localStorage.orgID;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.post("accountingRule/create", payload);
  },
  callAccountRuleReadApi(payload) {
    payload.orgId = localStorage.orgID;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.post("accountingRule/read", payload);
  },
  callAccountingRuleReadSingleApi(payload) {
    payload.orgId = localStorage.orgID;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.post("accountingRule/readSingle", payload);
  },
  callAccountingRuleDeleteApi(payload) {
    payload.orgId = localStorage.orgID;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.post("accountingRule/delete", payload);
  },
  callAccountingRuleEntrySubmitApi(payload) {
    payload.orgId = localStorage.orgID;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.post("accountingRuleEntry/create", payload);
  },
};
