<template>
  <div class="content ht-100v pd-0">
    <div class="content-body pd-10">
      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
        <div>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-style1 mg-b-10">
              <li class="breadcrumb-item">
                <router-link to="#">AccountingRule</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">All AccountingRules</li>
            </ol>
          </nav>
          <h4 class="mg-b-0 tx-spacing--1">View All AccountingRules</h4>
          <p class="tx-color-03 tx-12 mg-b-0">{{auth.userInfo.userFirstName}} {{auth.userInfo.userLastName}}</p>
          <p class="tx-color-03 tx-12 mg-b-0"><strong>Last login:</strong> {{auth.userInfo.userLastLogin | moment('dddd, MMMM Do YYYY, h:mm:ss a')}}</p>
        </div>
        <div class="d-none d-md-block">
          <download-excel
              :data="accountRules"
              class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
            <PrinterIcon class="mg-r-5"/>{{$t('extra.downloadExcel')}}
          </download-excel>
          <download-excel
              type="csv"
              :data="accountRules"
              class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
            <PrinterIcon class="mg-r-5"/>{{$t('extra.downloadCsv')}}
          </download-excel>
          <!--          <button @click="showModal = !showModal" class="btn btn-sm pd-x-15 btn-secondary btn-uppercase mg-l-5"><b-icon-plus/> Add New MTO</button>-->
        </div>
      </div>
      <base-top-cards :carditems="cards"/>
      <hr>
      <partner-modal :show="showModal" :row="{}"/>
      <base-table
          :items="accountRules"
          :fields="fields"
          :show-title="true"
          :is-busy="accountingRule.accountingRuleLoading"
          class="mt-2"
          filter-mode="balances"
      ></base-table>
    </div>
  </div>
</template>

<script>
import BaseTopCards from "../../components/card/BaseTopCards";
import BaseTable from "../../components/table/BaseTable";
import PartnerModal from "@/components/modal/PartnerModal";
// import BaseButton from "../../components/button/BaseButton";
import { PrinterIcon } from 'vue-feather-icons'
import {mapState} from 'vuex'


export default {
  name: 'Checker',
  data() {
    return {
      showModal: true,
      fields: [
        { key: 'serial', label: 'S/N', sortable: true, sortDirection: 'desc' },
        { key: 'aeCode', label: 'Code', sortable: true, sortDirection: 'desc' },
        { key: 'aeDescription', label: 'Description', sortable: true, sortDirection: 'desc' },
        { key: 'aeStatus', label: 'Status', sortable: true, sortDirection: 'desc' },
        { key: 'aeCreatedAt', label: 'Created At', sortable: true, sortDirection: 'desc' },
        { key: 'actionsAccountRule', label: 'Action', sortable: true, class: 'text-center' }
      ],
      model:{
        accountRulesFirstName:'',
        accountRulesLastName:'',
        accountRulesEmail:'',
        accountRulesChecker:''
      }
    }
  },
  components: {
    BaseTable,
    BaseTopCards,
    PartnerModal,
    PrinterIcon
  },
  computed: {
    cards:{
      get(){
        return [
          {
            id: 0,
            name: 'Total AccountingRules',
            number: this.accountRules.length,// == null?'0.00':'GHS '+this.numberWithCommas(this.totalGhs),
            color: 'bg-primary'
          },
          {
            id: 1,
            name: 'Total Pending AccountingRules',
            number: this.accountRules.filter(it => it.accountingRuleStatus === 'PENDING' ).length,// == null?'0.00':'GBP '+this.numberWithCommas(this.totalGbp),
            color: 'bg-orange'
          },
          {
            id: 2,
            name: 'Total Active AccountingRules',
            number: this.accountRules.filter(it => it.accountingRuleStatus === 'ACTIVE' ).length,// == null?'0.00':'USD '+this.numberWithCommas(this.totalUsd),
            color: 'bg-success'
          },
          {
            id: 3,
            name: 'Total Declined AccountingRules',
            number: this.accountRules.filter(it => it.accountingRuleStatus === 'DECLINED' ).length,// == null?'0.00':'EUR '+this.numberWithCommas(this.totalEur),
            color: 'bg-black-8'
          }
        ]
      },
      set(){

      }
    },
    accountRules(){
      return  this.accountingRule.accountingRules.data
    },
    ...mapState([
      'auth',
      'accountingRule'
    ])
  },
  methods: {
    hideModal(){
      this.$refs['new-accounting-rule-modal'].hide();
    },
    createChecker: async function () {
      await this.$store.dispatch("accountingRule/initiateEnrollment", this.model, {root: true}).then();
      this.$refs['new-accounting-rule-modal'].hide();
      await this.$store.dispatch("accountingRule/updateAccountingRules", {
        request: 'accountRules'
      }, {root: true}).then();
    },
    numberWithCommas(x) {
      let parts = x.toString().split(".");
      if (parts[1] == null)  parts[1] = '00'
      else if (parts[1].length === 0) parts[1] = parts[1]+'00'
      else if (parts[1].length === 1) parts[1] = parts[1]+'0'
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    },
  },
  created() {
    this.$store.dispatch("accountingRule/updateAccountingRules", {readAll: 'YES',source: 'WEB'}, {root: false}).then()
  }
}


</script>

<style scoped>

</style>
