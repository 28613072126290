<template>
  <div class="content ht-100v pd-0">
    <div class="content-body pd-10">
      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
        <div>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-style1 mg-b-10">
              <li class="breadcrumb-item">
                <router-link to="#">Report</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">All Reports</li>
            </ol>
          </nav>
          <h4 class="mg-b-0 tx-spacing--1">View All Reports</h4>
          <p class="tx-color-03 tx-12 mg-b-0">{{auth.userInfo.userFirstName}} {{auth.userInfo.userLastName}}</p>
          <p class="tx-color-03 tx-12 mg-b-0"><strong>Last login:</strong> {{auth.userInfo.userLastLogin | moment('dddd, MMMM Do YYYY, h:mm:ss a')}}</p>
        </div>
        <div class="d-none d-md-block">
          <download-excel
              type="excel"
              :data="reports"
              class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
            <PrinterIcon class="mg-r-5"/>Download Excel
          </download-excel>
          <download-excel
              type="csv"
              :data="reports"
              class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
            <PrinterIcon class="mg-r-5"/>Download CSV
          </download-excel>
          <button @click="generatePdf" style="background-color: #6d0606; border-color: #6d0606;" class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
            <span> <file-icon class="wd-10 mg-r-5"/> Download Pdf</span>
          </button>
        </div>
      </div>
      <new-report-form/>
      <br>
      <base-table
          :items="reports"
          :fields="fields"
          filter-mode="intrabankMultiple"
          :is-busy="report.reportsLoading">
      </base-table>
      <b-modal id="queryaudit" ref="queryaudit" hide-header hide-footer>
        <QueryAuditForm />
      </b-modal>
      <ReportInvoice :reports="reports" ref="childComponent"/>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import { PrinterIcon } from 'vue-feather-icons'
import BaseTable from "../../components/table/BaseTable";
import QueryAuditForm from "@/components/form/QueryAuditLogForm";
import NewReportForm from "../../components/form/NewReportForm";
import ReportInvoice from "./ReportPdf/ReportInvoice";

export default {
  name: "ReportEngine",
  components:{ReportInvoice, QueryAuditForm, BaseTable, NewReportForm,PrinterIcon },
  data(){
    return{
      fields: [
        {key: 'serial', label: 'S/N', sortable: true, class: 'text-left'},
        // {key: 'country', label: 'Country Code', sortable: true, class: 'text-left'},
        {key: 'reportCategory', label: 'Category', sortable: true, class: 'text-left'},
        {key: 'reportName', label: 'Report Name', sortable: true, class: 'text-left'},
        {key: 'reportDescription', label: 'Description', sortable: true, class: 'text-left'},
        {key: 'reportCreatedAt', label: 'Date Created', sortable: true, class: 'text-left'},
        {key: 'actionsReport', label: 'Actions'}
      ],
    }
  },
  computed : {
    ...mapState(['company','report','auth']),
    reports:{
      get(){
        // console.log(this.report.reports)
        return this.report.reports
      },
      set(val){
        this.$store.commit('report/updateReports',val)
      }
    }
  },
  methods:{
    generatePdf(){
      // window.resizeTo(300, 300).print();
      this.$refs.childComponent.generatePdf();
    },
  },
  created(){
    let jsonData = [{"person":"me","age":"30"},{"person":"you","age":"25"}];

    // eslint-disable-next-line no-unused-vars
    let val = jsonData[0];
    for(var j in val){
      let sub_key = j;
      // eslint-disable-next-line no-unused-vars
      let sub_val = val[j];
      console.log(sub_key);
    }
  }
}
</script>

<style scoped>

</style>