<template>
    <div>
        <side-bar v-if="auth.userInfo" layout="account-service-layout"></side-bar>
        <div class="content ht-100v pd-0">
            <Header v-if="auth.userInfo" ></Header>
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import Header from "../../../components/menu/Header";
    import SideBar from "../../../components/menu/Sidebar";
    import  { mapState } from  "vuex"

    export default {
        name: 'Home',
        components: {SideBar, Header},
        methods: {
            // loaddata: async function (customerid) {
            //     const payload = {customerid: customerid, status:  this.auth.response.roles === 'ROLE_AUTHORIZER' ? 'completed' : 'pushed'};
            //     await this.$store.dispatch("account/getAllAccount", payload, {root: false});
            //     await this.$store.dispatch("account/updateChartData", payload, {root: false});
            //     await this.$store.dispatch("ft/readAllBatches", payload, {root: false});
            //     await this.$store.dispatch("ft/banklist", payload, {root: false});
            //
            // }
        },
        created(){
            // this.init();
            // this.loadSubsidiaries()
        },
        computed:{
            ...mapState(['auth','system'])
        },
        mounted() {
        }


    }
</script>
