<template>
    <div class="row row-xs">
        <div class="col-md-12 mg-t-10">
            <div class="card">
                <div class="card-header pd-t-6 pd-b-0 bd-b-0">
<!--                  {{analyticChartDc}}-->
<!--                  {{analyticChartCp}}-->
<!--                    <h6 class="mg-b-5">Status</h6>-->
<!--                    <p class="tx-12 tx-color-03 mg-b-0">Percentage of completion</p>-->
                </div><!-- card-header -->
                <div class="card-body pd-20">
                    <div class="chart-two mg-b-14">
                        <p v-if="dashboard.analyticLoading" align="center"><bar-loader  class="custom-class" color="#6d0606" loading="loading" :size="150" sizeUnit="px"></bar-loader></p>
                        <div v-else>
                            <b-row>
                            <b-col md="4" class="my-0 mb-0 ml-0 " style="margin-top: -5px;">
<!--                                <b-form-group class="ml-0 mb-3 pd-l-0"  label-class="mr-0" label="Showing Metrics For:" label-cols-md="5">-->
<!--                                    <b-input-group size="sm" class="ml-0">-->
<!--                                        <b-form-select class="ml-0 border-0" style="margin-left: -10px;" v-model="metrics" id="sortBySelect">-->
<!--                                                <option value="today">Today</option>-->
<!--                                                <option value="thisweek">This Week</option>-->
<!--                                                <option value="thismonth">This Month</option>-->
<!--                                                <option value="last30" aria-selected="true">Last 30days</option>-->
<!--                                                <option value="last90">Last 90days</option>-->
<!--                                                <option value="thisyear">This Year</option>-->
<!--                                                <option value="alltime">All Time</option>-->
<!--                                                <option value="custom">Custom Period</option>-->
<!--                                        </b-form-select>-->

<!--                                    </b-input-group>-->
<!--                                </b-form-group>-->

                            </b-col>
                            <b-col md="4">
<!--                                <b-form-group v-if="this.dashboard.metrics.type === 'custom'"  horizontal class="mb-0">-->
<!--                                    <b-input-group>-->
<!--                                        <div class="datepicker-trigger">-->
<!--                                            <input-->
<!--                                                    type="text"-->
<!--                                                    class="mt-1 p-1"-->
<!--                                                    id="datepicker-trigger"-->
<!--                                                    placeholder="Select dates"-->
<!--                                                    :value="formatDates(metricData.startDate, metricData.endDate)"-->
<!--                                            >-->
<!--                                            <AirbnbStyleDatepicker-->
<!--                                                    :trigger-element-id="'datepicker-trigger'"-->
<!--                                                    :mode="'range'"-->
<!--                                                    :fullscreen-mobile="true"-->
<!--                                                    :date-one="metricData.startDate"-->
<!--                                                    :date-two="metricData.endDate"-->
<!--                                                    @date-one-selected="val => { metricData.startDate = val }"-->
<!--                                                    @date-two-selected="val => { metricData.endDate = val }"-->
<!--                                            />-->
<!--                                        </div>-->
<!--                                        <b-input-group-append>-->
<!--                                            <b-btn size="xm" style="font-size: 11px; height: 87%;" class="pd-t-0 pd-b-0 mt-1" @click="queryFt()">Query</b-btn>-->
<!--                                        </b-input-group-append>-->
<!--                                    </b-input-group>-->
<!--                                </b-form-group>-->
                            </b-col>
                            </b-row>
                            <br> <br>
                            <div class="ml-3 t-20 l-20 wd-xl-150p z-index-10">
                                <div v-if="this.analyticChartDc.length > 0" class="row">
                                    <div class="col-sm-3">
                                        <h3 class="tx-normal tx-rubik tx-spacing--2 mg-b-5">{{ this.totalCountDc }}</h3>
                                        <h6 class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-10">Total Direct Credit Count</h6>
                                        <p class="mg-b-0 tx-12 tx-color-03">From <strong>{{this.labelx[0]}}</strong> to <strong>{{this.labelx[this.labelx.length-1]}}</strong></p>
                                    </div><!-- col -->
                                    <div class="col-sm-3">
                                        <h3 class="tx-normal tx-rubik tx-spacing--2 mg-b-5">{{ this.totalCountCp }}</h3>
                                        <h6 class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-10">Total Cash Pickup Count</h6>
                                        <p class="mg-b-0 tx-12 tx-color-03">From <strong>{{this.labelx[0]}}</strong> to <strong>{{this.labelx[this.labelx.length-1]}}</strong></p>
                                    </div><!-- col -->
<!--                                    <div class="col-sm-3 mg-t-20 mg-sm-t-0">-->
<!--                                        <h3 class="tx-normal tx-rubik tx-spacing&#45;&#45;2 mg-b-5">{{ this.balance }}</h3>-->
<!--                                        <h6 class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-10">Balance</h6>-->
<!--                                        <p class="mg-b-0 tx-12 tx-color-03">All time</p>-->
<!--                                    </div>&lt;!&ndash; col &ndash;&gt;-->
                                </div><!-- row -->
                                <div v-else class="row">
                                    <div class="col-sm-3">
<!--                                        <h3 class="tx-normal tx-rubik tx-spacing&#45;&#45;2 mg-b-5">{{ this.revenue }}</h3>-->
<!--                                        <h6 class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-10">Revenue</h6>-->
<!--                                        <p class="mg-b-0 tx-12 tx-color-03">From <strong>{{this.labelx[0]}}</strong> to <strong>{{this.labelx[this.labelx.length-1]}}</strong></p>-->
                                    </div><!-- col -->
                                    <div class="col-sm-3">
                                        <h3 class="tx-normal tx-rubik tx-spacing--2 mg-b-5">NO ACTIVITY THIS PERIOD</h3>
<!--                                        <h6 class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-10">Balance</h6>-->
<!--                                        <p class="mg-b-0 tx-12 tx-color-03">From <strong>{{this.labelx[0]}}</strong> to <strong>{{this.labelx[this.labelx.length-1]}}</strong></p>-->
                                    </div><!-- col -->
                                    <div class="col-sm-3 mg-t-20 mg-sm-t-0">
<!--                                        <h3 class="tx-normal tx-rubik tx-spacing&#45;&#45;2 mg-b-5">{{ this.balance }}</h3>-->
<!--                                        <h6 class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-10">Balance</h6>-->
<!--                                        <p class="mg-b-0 tx-12 tx-color-03">All time</p>-->
                                    </div><!-- col -->
                                </div><!-- row -->
                            </div>
                            <br> <br>
<!--                            <stats-chart/>-->
<!--                            <br>-->
                            <line-chart style="height: 28vh; width: 100%;" class="mt-0" :data="retentionData" :option="retentionOptions"></line-chart>
                        </div>
                    </div><!-- chart-two -->
                    <div class="row">
                    </div><!-- row -->
                </div><!-- card-body -->
            </div><!-- card -->
        </div>
    </div>
</template>

<script>
    import LineChart from "../chart/LineChart";
    // import StatsChart from "./StatsChart";
    import {mapState} from 'vuex'
    import format from 'date-fns/format'
    // import LineChart from "../chart/LineChart";
    export default {
        name: "Growth",
        components:{
            LineChart,
            // StatsChart,
        },
        data:()=>{
            return{
                metricData:{
                    type:'custom',
                    startDate:'',
                    endDate:'',
                },
                fullData:{},
                show:false,
                dateFormat: 'D MMM',
                //dateOne:'',
                //dateTwo:'',
                allbalance:'',
                balance:'',
                revenue:'',
                expense:'',
                totalCountDc:'',
                totalCountCp:'',
                labelx:[],
                datax:[],
                datay:[],
                dataz:[],
                dataa:[],
                fields: [
                    { key: 'id', label: 'S/N', sortable: true, sortDirection: 'desc' },
                    { key: 'name', label: 'Name', sortable: true, sortDirection: 'desc' },
                    { key: 'updated', label: 'Updated?', sortable: true, class: 'text-center' },
                    { key: 'verified', label: 'Verified?', sortable: true, class: 'text-center' }
                ],
                revenueOptions: {
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    },
                    responsive: true,
                    maintainAspectRatio: false
                },


                retentionOptions: {
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    },
                    responsive: true,
                    maintainAspectRatio: false
                }
            }
        },
        methods:{
            getMonday(d) {
                d = new Date(d);
                let day = d.getDay(),
                    diff = d.getDate() - day + (day === 0 ? -6:1); // adjust when day is sunday
                return new Date(d.setDate(diff));
            },
            formatDates(dateOne, dateTwo) {
                let formattedDates = ''
                if (dateOne) {
                    formattedDates = format(dateOne, this.dateFormat)
                }
                if (dateTwo) {
                    formattedDates += ' - ' + format(dateTwo, this.dateFormat)
                }
                return formattedDates
            },
            queryFt(){
                this.$store.commit("dashboard/updateMetrics", this.metricData)
                this.$store.commit("dashboard/updateChartLoading", true)
                this.$store.dispatch("dashboard/updateLoanCharts", this.metricData)
            },
        },
        computed:{
            ...mapState([
                'auth',
                'dashboard'
            ]),
            items:{
                get(){
                    return this.dashboard.dataCp
                },
                set(val){
                    this.$store.commit('system/updateDashboardData', val)
                }
            },
            analyticChartDc(){
                return  this.dashboard.analytics.dataDc
            },
            analyticChartCp(){
                return  this.dashboard.analytics.dataCp
            },
            retentionData:{
                get(){
                  // eslint-disable-next-line no-unused-vars
                    let formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'NGN',
                    });

                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.labelx = [...new Set(this.analyticChartDc.map(data => data.transactionDate))];
                    //this.labelx = [...new Set(this.analyticChart.map(data => data.applicationDate.substring(0, 10)))];
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.datax = this.labelx.map(
                        it => this.analyticChartDc.filter(
                            log => log.transactionDate === it).reduce(
                            (init, curr) => (
                                init+=curr.transactionCount
                            ),0)
                    );
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.datay = this.labelx.map(
                        it => this.analyticChartCp.filter(
                            log => log.transactionDate === it).reduce(
                            (init, curr) => (
                                init+=curr.transactionCount
                            ),0)
                    );

                    if(this.labelx.length < 2 ){
                        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                        this.labelx = [... new Set(this.analyticChartDc.map(data => data.transactionDate))];
                        //this.labelx = [... new Set(this.analyticChart.map(data => data.applicationDate.substring(10, 13)))];
                        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                        this.datax = this.labelx.map(
                            it => this.analyticChartDc.filter(
                                log => log.transactionDate === it).reduce(
                                (init, curr) => (
                                    init+=curr.transactionCount
                                ),0)
                        );
                        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                        this.datay = this.labelx.map(
                            it => this.analyticChartCp.filter(
                                log => log.transactionDate === it).reduce(
                                (init, curr) => (
                                    init+=curr.transactionCount
                                ),0)
                        );
                    }

                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    // this.totalCountDc = formatter.format(this.analyticChartDc.reduce(
                    //     (init, curr) => (
                    //         init+=curr.transactionCount
                    //     ),0));
                  // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.totalCountDc = this.analyticChartDc.reduce(
                        (init, curr) => (
                            init+=curr.transactionCount
                        ),0);
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.totalCountCp = this.analyticChartCp.reduce(
                        (init, curr) => (
                            init+=curr.transactionCount
                        ),0);

                    let color1 = 'rgba('+Math.ceil(Math.random() * 255)+', '+Math.ceil(Math.random() * 255)+', '+Math.ceil(Math.random() * 255)+', 0.2)'
                    let bdcolor1 = color1.replace('0.2','1')

                    let color2 = 'rgba('+Math.ceil(Math.random() * 255)+', '+Math.ceil(Math.random() * 255)+', '+Math.ceil(Math.random() * 255)+', 0.2)'
                    let bdcolor2 = color2.replace('0.2','1')

                    return {
                        labels: this.labelx,
                        datasets: [
                            {
                                label: 'Direct Credit',
                                data: this.datax,
                                backgroundColor: color1,
                                borderColor: bdcolor1,
                                borderWidth: 1
                            },
                            {
                                label: 'Cash Pickup',
                                data: this.datay,
                                backgroundColor: color2,
                                borderColor: bdcolor2,
                                borderWidth: 1
                            },
                        ]
                    }
                },
                set(val){
                    this.$store.commit('dashboard/updateLoanChart', val)
                }
            },
            metrics:{
                get(){
                    return this.dashboard.metrics.type
                },
                set(val){
                    let metrics = {
                        type:'',
                        startDate:null,
                        endDate:null
                    };
                    this.show = false;
                    let today = new Date();
                    if (val === 'today') {
                        metrics.type = 'today';
                        metrics.startDate = today.toJSON().slice(0,10);
                        metrics.endDate = new Date(new Date().setDate(today.getDate()+1)).toJSON().slice(0,10);
                    }else if (val === 'thisweek') {
                        let priorDate = this.getMonday(new Date());
                        metrics.type = 'thisweek';
                        metrics.startDate = priorDate.toJSON().slice(0,10);
                        metrics.endDate = new Date(new Date().setDate(today.getDate()+1)).toJSON().slice(0,10);
                    }else if (val === 'thismonth') {
                        let priorDate = new Date(today.getFullYear(), today.getMonth(), 2);
                        metrics.type = 'thismonth';
                        metrics.startDate = priorDate.toJSON().slice(0,10);
                        metrics.endDate = new Date(new Date().setDate(today.getDate()+1)).toJSON().slice(0,10);
                    }else if (val === 'last30') {
                        let priorDate = new Date(new Date().setDate(today.getDate()-30));
                        metrics.type = 'last30';
                        metrics.startDate = priorDate;//.toJSON().slice(0,10);
                        metrics.endDate = new Date(new Date().setDate(today.getDate()+1)).toJSON().slice(0,10);
                    }else if (val === 'last90') {
                        let priorDate = new Date(new Date().setDate(today.getDate()-90));
                        metrics.type = 'last90';
                        metrics.startDate = priorDate.toJSON().slice(0,10);
                        metrics.endDate = new Date(new Date().setDate(today.getDate()+1)).toJSON().slice(0,10);
                    }else if (val === 'thisyear') {
                        let priorDate = new Date(new Date().getFullYear(), 0, 1);
                        metrics.type = 'thisyear';
                        metrics.startDate = priorDate.toJSON().slice(0,10);
                        metrics.endDate = new Date(new Date().setDate(today.getDate()+1)).toJSON().slice(0,10);
                    }else if (val === 'alltime') {
                        metrics.type = 'alltime';
                        metrics.startDate = null;
                        metrics.endDate = null;
                    }
                    if (val !== 'custom') {
                        console.log('>>>>>>>con1')
                        this.$store.commit("dashboard/updateMetrics", metrics)
                        this.$store.commit("dashboard/updateAnalyticsLoading", true)
                        this.$store.dispatch("dashboard/updateAnalytics", metrics)
                    }else{
                        metrics.type = 'custom';
                        this.$store.commit("dashboard/updateMetrics", metrics)
                    }
                }
            }
        },
        created() {
            console.log(this.retentionData)
        }
    }
</script>

<style scoped>

</style>