<template>
    <b-modal id="confirm-password" ref="password-modal" :hide-header="true" :hide-footer="true"
             :centered="true">
        <div class="modal-body">
            <a href="#" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal"
               aria-label="Close" @click="hideauth">
                <span aria-hidden="true">&times;</span>
            </a>

            <div>
                <template>
                    <h4>Confirm Verification</h4>
                    <p class="tx-color-03">Confirm Verification</p>
                </template>
                <b-form @submit.prevent="authorize">
                    <b-form-group label="Input Token:">
                        <b-form-input type="password" id="narration" v-model="model.tokenCode"></b-form-input>
                    </b-form-group>
                    <b-button v-if="auth.authLoading" disabled
                              class="btn-secondary btn-brand-02 btn-block">
                                        <span class="spinner-border spinner-border-sm" role="status"
                                              aria-hidden="true"></span>
                        Loading...
                    </b-button>
                    <b-button type="submit" v-else class="btn btn-secondary btn-block btn-sm">Submit
                    </b-button>

                </b-form>


            </div>
        </div><!-- modal-body -->
    </b-modal>
</template>
<script>
    import { mapState} from "vuex"
    // import Vue from 'vue'
    export default {
        props:['show', 'row'],
        name: 'ConfirmModal',
        data(){
            return{
              model:{
                tokenCode: '',
                userId: ''
              }
            }
        },
        methods:{
            authorize: async function () {
              this.model.userId = this.auth.userInfo.username
              this.$store.dispatch("auth/validateHardToken", this.model)
              this.$refs['password-modal'].hide();
              // window.location = window.__env.app.publicPath+'preloader'
            },
            hideauth: function () {
                this.password = '';
                this.$refs['password-modal'].hide();
            },
            formatAmount(value){
                if (value == null) return '0.00'
                var parts = value.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            }
        },
        watch: {
          show: function () {
            let self = this;
            // eslint-disable-next-line no-unused-vars
            // Object.keys(this.model).forEach(function(key,index) {
            //   self.model[key] = '';
            // });
            // if (this.type !== 'edit') this.$store.commit("user/updateScreen", 'enquiry')
            // this.$store.dispatch('accountingRule/updateSlabs',{slabAeCode:this.row.aeCode})
            // eslint-disable-next-line no-unused-vars
            Object.keys(this.row).forEach(function(key,index) {
              self.model[key] = self.row[key];
            });
            this.$refs['password-modal'].show()
          }
        },
        computed:{
            ...mapState([ 'auth'])
        }
    }
</script>
