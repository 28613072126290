<template>
    <b-container fluid>
        <!-- User Interface controls -->
        <b-row>
            <b-col md="4" class="my-1 ml-0">

            </b-col>
<!--            <b-col sm="4">-->
<!--            </b-col>-->
            <b-col md="8" class="my-1">
                <b-form-group
                        v-if="filterMode !== 'accountingRuleEntries'"
                        :label="showTitle?'Filter':''"
                        label-cols-sm="2"
                        label-align-sm="right"
                        label-size="sm"
                        label-for="filterInput"
                        class="mb-0"
                >
                    <b-input-group size="sm">
                        <b-form-input
                                v-model="filter"
                                type="search"
                                id="filterInput"
                                placeholder="Type to Search"
                        ></b-form-input>
                        <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>

        <br>

        <!-- Main table element -->
        <b-table
                small
                show-empty
                stacked="md"
                style="font-size: 12px;"
                striped
                :items="items"
                :busy="isBusy"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :tbody-tr-class="rowClass"
                :filterIncludedFields="filterOn"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
        >
          <template v-slot:cell(transAmount)="row">
            {{ row.item.transAmount | formatAmount }}
          </template>

            <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                </div>
            </template>

            <template v-slot:cell(serial)="row">
                {{ row.index + 1 }}
            </template>

            <template v-slot:cell(actions)="row">
                <b-button size="xs" @click="row.toggleDetails">
                    {{ row.detailsShowing ? 'Less' : 'More' }}
                </b-button>
            </template>

            <template v-slot:cell(actionsComplex)="row">
                <b-button size="xs" variant="success">
                    Approve
                </b-button>
                &nbsp;
                <b-button size="xs" variant="danger">
                    Delete
                </b-button>
                &nbsp;
                <b-button size="xs" @click="row.toggleDetails">
                    {{ row.detailsShowing ? 'Less' : 'More' }}
                </b-button>
            </template>

            <template v-slot:cell(actionsPayemet)="row">
                <b-button size="xs" variant="success">
                    Approve
                </b-button>
                &nbsp;
                <b-button size="xs" variant="danger">
                    Delete
                </b-button>
                &nbsp;
                <b-button size="xs" @click="row.toggleDetails">
                    {{ row.detailsShowing ? 'Less' : 'More' }}
                </b-button>
            </template>

            <template v-slot:cell(actionx)="row">
                <b-button size="xs" @click="navigate(row.item.officerID)">
                    Details
                </b-button>
            </template>

            <template v-slot:row-details="row">
                <b-card>
                    <ul>
                      <li v-for="(value, key) in row.item" :key="key"><b>{{ key | camelToSentenceCase }}</b>: {{ value }}</li>
                    </ul>
                </b-card>
            </template>
        </b-table>

        <b-row>
            <b-col v-if="filterMode !== 'accountingRuleEntries'" md="3" class="my-1 ml-0">
                <b-form-group
                        :label="showTitle?'Per page':'Page'"
                        label-cols-sm="6"
                        label-cols-md="4"
                        label-cols-lg="3"
                        label-align-sm="right"
                        label-size="sm"
                        label-for="perPageSelect"
                        class="mb-0"
                >
                    <b-form-select
                            v-model="perPage"
                            id="perPageSelect"
                            size="sm"
                            :options="pageOptions"
                    ></b-form-select>
                </b-form-group>
            </b-col>
            <b-col sm="4"></b-col>
            <b-col sm="5" md="4" class="my-1 mr-0 mx-3">
                <b-pagination
                        v-model="currentPage"
                        :total-rows="items==null?0:items.length"
                        :per-page="perPage"
                        align="fill"
                        size="sm"
                        class="my-0"
                ></b-pagination>
            </b-col>
        </b-row>
        <!--        <b-icon-arrow-up></b-icon-arrow-up>-->
        <!-- Info modal -->
        <!--        <b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal">-->
        <!--            <pre>{{ infoModal.content }}</pre>-->
        <!--        </b-modal>-->
    </b-container>
</template>

<script>
    import {mapState} from "vuex";
    import format from 'date-fns/format'
    // import {MoreHorizontal, Delete} from 'vue-feather-icon'
    export default {
        props:['items', 'fields', 'subfields', 'isBusy','showTitle', 'type','showMetrics','filterMode'],
        components:{
            // MoreHorizontal,
            // Delete
        },
        data() {
            return {
                show:false,
                dateFormat: 'D MMM',
                metricData:{
                    type:'custom',
                    startDate:'',
                    endDate:''
                },
                totalRows: 1,
                currentPage: 1,
                perPage: 5,
                pageOptions: [5, 10, 15, 20],
                sortBy: '',
                account: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                filterOn: [],
                infoModal: {
                    id: 'info-modal',
                    title: '',
                    content: ''
                },
            }
        },
        computed: {
            getAccounts() {
                // Create an options list from our fields

                return this.accounts;
            },
            ...mapState([
                'company',
                'logs',
                //'account'
            ]),
        },
        mounted() {
            // Set the initial number of items
            this.totalRows = this.items.length
        },
        methods: {
            navigate(_id){
                localStorage.suOfficerID = _id;
                this.$router.push('/officer-details');
            },
            onFiltered(){

            },
            queryFt(){
                this.$store.commit("logs/updateFtMetrics", this.metricData);
                this.$store.commit("logs/updateFtLoading", true);
                this.$store.dispatch("logs/readFtLogs", {
                    customerId: this.company.account[0].accCustID
                })
            },
            getMonday(d) {
                d = new Date(d);
                let day = d.getDay(),
                    diff = d.getDate() - day + (day === 0 ? -6:1); // adjust when day is sunday
                return new Date(d.setDate(diff));
            },
            formatDates(dateOne, dateTwo) {
                let formattedDates = ''
                if (dateOne) {
                    formattedDates = format(dateOne, this.dateFormat)
                }
                if (dateTwo) {
                    formattedDates += ' - ' + format(dateTwo, this.dateFormat)
                }
                return formattedDates
            },
            rowClass(item, type) {
              if (!item || type !== 'row') return '';
              if (item.cqStatus === 'REJECTED') return 'table-danger';
              else if(item.cqStatus === 'AUTHORIZED') return 'table-success';
              else if(item.entryDrCr === 'CR') return 'table-success';
              else if(item.entryDrCr === 'DR') return 'table-danger';
              else return '';
            }
        }
    }
</script>
