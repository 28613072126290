<template>
    <div>
        <form v-if="auth.screen === 'recovery2'" @submit.prevent="completeForgotPassword">
            <div class="row">
                <div class="col-12">
                    <h6>Forgot Password</h6>
                    <div class="form-group">
                        <div class="input-group">
                            <input id="otp" v-model="model.otp" type="text" class="form-input" required/>
                            <label class="input-label" for="otp">
                                <span class="label-span">{{ $t('auth.otp')}}</span>
                            </label>
                        </div>
                        <p v-if="!model.otp" class="input-error">{{ $t('auth.otpIsRequired')}}</p>
                    </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                        <span style="display: flex">
                          <div class="input-group">
                              <input id="newPassword" autocomplete="new-password" v-model="model.password" :type="inputType2" class="form-input" minlength="6" maxlength="13" required/>
                              <label class="input-label" style="border-top-right-radius: 0;border-bottom-right-radius: 0;" for="newPassword">
                                  <span class="label-span">New Password</span>
                              </label>
                          </div>
                          <b-input-group-text style="border-top-left-radius: 0;border-bottom-left-radius: 0; cursor: pointer; height: 50px">
                            <b-icon-eye v-if="inputType2 === 'password'" @click="inputType2 = 'text'" />
                            <b-icon-eye-slash v-else @click="inputType2 = 'password'" />
                          </b-input-group-text>
                        </span>
                  </div>
                </div>
                <div class="col-12">
                <div class="form-group">
                      <span style="display: flex">
                        <div class="input-group">
                            <input id="passwordConfirmation" v-model="model.passwordConfirmation" :type="inputType3" class="form-input" minlength="6" maxlength="13" required/>
                            <label class="input-label" style="border-top-right-radius: 0;border-bottom-right-radius: 0;" for="passwordConfirmation">
                                <span class="label-span">Password Confirmation</span>
                            </label>
                        </div>
                        <b-input-group-text style="border-top-left-radius: 0;border-bottom-left-radius: 0; cursor: pointer; height: 50px">
                          <b-icon-eye v-if="inputType3 === 'password'" @click="inputType3 = 'text'" />
                          <b-icon-eye-slash v-else @click="inputType3 = 'password'" />
                        </b-input-group-text>
                      </span>
                </div>
              </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <h6 class="h6">Password Requirement:</h6>
                        <ul >
                            <li :style="'color:'+minLength">Minimum length: 8</li>
                            <li :style="'color:'+maxLength">Maximum length: 13</li>
                            <li :style="'color:'+hasLettersNumbers">Must contain letters and numbers</li>
                            <li :style="'color:'+hasLowerCase">Must contain a lowercase letter</li>
                            <li :style="'color:'+hasUpperCase">Must contain an uppercase letter</li>
                            <li :style="'color:'+hasSpecialXters">Must contain special characters</li>
                            <li :style="'color:'+hasSpace">Must not contain white space</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <auth-base-button title="Submit" :loading="auth.authLoading"></auth-base-button>
                </div>
            </div>
        </form>
        <form v-else  @submit.prevent="forgotPassword">
            <div class="row">
                <div class="col-12">
                    <h6>Forgot Password</h6>
                    <div class="form-group">
                        <div class="input-group">
                            <input id="email" v-model="model.username" type="email" class="form-input" required/>
                            <label class="input-label" for="email">
                                <span class="label-span">{{ $t('auth.email')}}</span>
                            </label>
                        </div>
                        <p v-if="!model.username" class="input-error">{{ $t('auth.emailIsRequired')}}</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <auth-base-button title="Submit" :loading="auth.authLoading"></auth-base-button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import AuthBaseButton from "../button/AuthBaseButton";
    export default {
        name: "LogonForm",
        components: {
            AuthBaseButton
        },
        data() {
            return {
              inputType: "password",
              inputType1: "password",
              inputType2: "password",
              inputType3: "password",
                model: {
                    otp: '',
                    username: '',
                    password: '',
                    passwordConfirmation: '',
                    subscribe: true
                },
            }
        },
        methods: {
            clicked(){
                this.$swal({
                    title: 'Custom animation with Animate.css',
                    showClass: {
                        popup: 'animated slideInLeft faster display-6'
                    },
                    hideClass: {
                        popup: 'animated slideOutRight faster display-6'
                    },
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, delete it!'
                })
            },
            async forgotPassword() {
                await this.$store.dispatch("auth/initiateResetPassword", this.model, { root: false })
            },
            async completeForgotPassword() {
                if(this.minLength !== 'green'||
                    this.maxLength  !== 'green'||
                    this. hasLettersNumbers !== 'green' ||
                    this.hasSpecialXters !== 'green' ||
                    this.hasLowerCase !== 'green' ||
                    this.hasUpperCase !== 'green' ||
                    this.hasSpace !== 'green'
                ) return;
                this.model.email = this.model.username;
                await this.$store.dispatch("auth/completeResetPassword", this.model, { root: false })
                this.model = {
                        otp: '',
                        userEmail: '',
                        password: '',
                        passwordConfirmation: '',
                        subscribe: true
                }
            },

        },
        computed:{
            ...mapState([
                'company',
                'document',
                'auth',
                'auth',
                'officer',
            ]),
            minLength:function(){
                return this.model.password.length >= 8?'green':'red'
            },
            maxLength:function(){
                return this.model.password.length <= 13?'green':'red'
            },
            hasLettersNumbers:function(){
                return ( /[a-zA-Z]/g.test(this.model.password) && /[0-9]/g.test(this.model.password))?'green':'red'
            },
            hasSpecialXters:function(){
                return /[^a-zA-Z0-9]/g.test(this.model.password)?'green':'red'
            },
            hasLowerCase:function(){
                return /[a-z]/g.test(this.model.password)?'green':'red'
            },
            hasUpperCase:function(){
                return /[A-Z]/g.test(this.model.password)?'green':'red'
            },
            hasSpace:function(){
                return !(/\s/g.test(this.model.password))?'green':'red'
            },

        },

        watch: {
            'auth': function() {
                console.log('Hello World!')
            }
        },
        mounted() {
            // this.$store.auth.watch(this.$store.getters.getN, n => {
            //     console.log('watched: ', n)
            // })
        },
    }
</script>

<style scoped>

</style>
