<template>
  <form method="post" @submit.prevent="queryauditlogform">
    <p class="tx-20">Query Audit Log</p>
    <hr>
    <div class="form-group col-md-12">
      <label>Query Type:</label>
      <b-form-select class="form-control col-md-12" v-model="form.Status"
                     :options="[{text: 'User', value:'user'},
                               {text: 'Officer', value:'officer'}]">
      </b-form-select>
    </div>
    <button class="btn btn-brand-02 btn-block">
      <span v-if="false" class="spinner-grow spinner-grow-sm"></span>
      Query Audit Log
    </button>
  </form>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "QueryAuditForm",
  data() {
    return {
      approve: false,
      form: {
        Status : '',
        approverid: localStorage.token
      }
    }
  },
  methods: {
    queryauditlogform: async function(){
    }

  },
  mounted() {

  },
  computed: {
    ...mapState(['auth'])
  }

}
</script>

<style scoped>

</style>
