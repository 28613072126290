<template>
    <div>

        <side-bar v-if="auth.userInfo"></side-bar>

        <div class="content ht-100v pd-0">

            <Header v-if="auth.userInfo" ></Header>

            <router-view></router-view>


        </div>
    </div>
</template>

<script>
    import SideBar from "../../../components/menu/Sidebar";
    import Header from "../../../components/menu/Header";
    import {mapState} from "vuex";
    export default {
        name: "OnBoardingLayout",
        components: {SideBar, Header},
        methods:{

        },
        created() {

        },
        computed:{
            ...mapState([
                'auth',
                'system'
            ])
        }
    }
</script>

<style scoped>

</style>