<template>
    <b-modal id="user-create-modal" :hide-header="true" :hide-footer="true" :centered="true" ref="user-create-modal">
        <div class="modal-body">
            <a href="#" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"
               @click="hide()">
                <span aria-hidden="true">&times;</span>
            </a>
            <div>
                <h4 v-if="model.type === 'edit'">Edit User</h4>
                <h4 v-else>Add New User</h4>
                <b-form v-if="this.user.screen === 'enquiry'" @submit.prevent="enquiry" enctype="multipart/form-data">
                    <b-form-group label="Username *">
                        <b-input type="text" placeholder="Username" required v-model="model.username"/>
                    </b-form-group>
                    <b-form-group label="Subsidiary *">
                        <b-select v-model="model.countryId">
                          <b-select-option value="">Select Subsidiary</b-select-option>
                          <b-select-option value="01">Nigeria</b-select-option>
                          <b-select-option value="02">Ghana</b-select-option>
                          <b-select-option value="03">DRC</b-select-option>
                        </b-select>
                    </b-form-group>
                    <base-button title="Continue" :loading="user.userLoading"/>
                </b-form>
                <b-form v-else @submit.prevent="submit" enctype="multipart/form-data">
                    <b-form-group label="First Name *">
                        <input type="text" class="form-control" @input="filterOutBadChar()" placeholder="First Name" required v-model="model.userFirstName" readonly/>
                    </b-form-group>
                    <b-form-group label="Last Name *">
                        <input type="text" class="form-control" @input="filterOutBadChar()" placeholder="Last Name" required v-model="model.userLastName" readonly/>
                    </b-form-group>
                    <b-form-group label="Phone Number *">
                        <input type="text" class="form-control" @input="filterOutBadChar()" placeholder="Phone Number" required v-model="model.userPhone"/>
                    </b-form-group>
                    <b-form-group label="Username *">
                        <b-input type="text" placeholder="Username" required v-model="model.username" readonly/>
                    </b-form-group>
                    <b-form-group label="Email *">
                        <b-input type="email" placeholder="Email" required v-model="model.userEmail"/>
                    </b-form-group>
                    <b-form-group  label="Role *">
                      <b-select required v-model="model.userRoleId">
                        <b-select-option value="">Select Role</b-select-option>
                        <b-select-option v-for="role in roles" v-bind:key="role.roleId" :value="role.roleId">{{ role.roleName }}</b-select-option>
                      </b-select>
                    </b-form-group>
                    <b-form-group label="User Type *">
                      <b-select required v-model="model.userType">
                        <b-select-option value="">Select User Type</b-select-option>
                        <b-select-option value="HEAD OFFICE">Head Office</b-select-option>
                        <b-select-option value="BRANCH">Branch</b-select-option>
                      </b-select>
                    </b-form-group>
                    <b-form-group v-if="model.type === 'edit'" label="Status *">
                      <b-select required v-model="model.userStatus">
<!--                        <b-select-option value="PENDING">Pending</b-select-option>-->
                        <b-select-option value="ACTIVE">Active</b-select-option>
                        <b-select-option value="INACTIVE">Inactive</b-select-option>
                        <b-select-option value="LOCKED">Lock</b-select-option>
                        <b-select-option value="PENDING">Pending</b-select-option>
                        <b-select-option value="DELETED">Delete</b-select-option>
                      </b-select>
                    </b-form-group>
<!--                    <b-form-group label="Status">-->
<!--                        <b-select required v-model="model.employerGradeSalaryItemType">-->
<!--                            <b-select-option  value="ACTIVE">ACTIVE</b-select-option>-->
<!--                            <b-select-option  value="DELETED">DELETE</b-select-option>-->
<!--                        </b-select>-->
<!--                    </b-form-group>-->
                    <base-button title="Submit" :loading="user.userLoading"/>
                </b-form>
            </div>
        </div><!-- modal-body -->
    </b-modal>
</template>
<script>
    import { mapState } from "vuex"
    import BaseButton from "../button/BaseButton";
    export default {
        props:['show','row'],
        components:{BaseButton},
        name: 'ConfirmModal',
        data(){
            return{
                password: '',
                model:{
                  countryId: '',
                  userFirstName: '',
                  userLastName: '',
                  userPhone: '',
                  username: '',
                  userEmail: '',
                  userRoleId: '',
                  userType: '',
                  userMaker: '',
                  setPassword: 'YES',
                  userStatus: '',
                  source: 'WEB'
                },
            }
        },
        methods:{
            hide() {
                this.$refs['user-create-modal'].hide()
            },
            filterOutBadChar(){
              // this.model.userFirstName = this.model.userFirstName.replace(/[^A-Z0-9\s]/i, '')
              // this.model.userLastName = this.model.userLastName.replace(/[^A-Z0-9\s]/i, '')
              this.model.userPhone = this.model.userPhone.replace(/[^0-9\s]/i, '')
            },
            async enquiry(){
              await this.$store.dispatch("user/updateAdUser", this.model, {root: false}).then(()=>{
                // eslint-disable-next-line no-unused-vars
                let name = []
                if (this.user.adUser.responseCode === '00') name = this.user.adUser.displayName.split(' ')
                this.model.userFirstName = name[0]
                this.model.userLastName = name[name.length-1]
                this.model.userPhone = this.user.adUser.mobileNo
                this.model.username = this.user.adUser.username
                this.model.userEmail = this.user.adUser.email
              });
              //this.hide();
            },
            async submit(){
                this.model.userMaker = this.auth.userInfo.userId
                this.model.source = 'WEB'
                if (this.model.type === 'edit')
                  await this.$store.dispatch("user/editUser", this.model, {root: false});
                else {
                  //this.model.userId = this.row.roleId
                  await this.$store.dispatch("user/createUser", this.model, {root: false});
                }
                this.hide();
                this.loadData()
                let self = this;
                // eslint-disable-next-line no-unused-vars
                Object.keys(this.model).forEach(function(key,index) {
                    self.model[key] = '';
                });

            },
            loadData(){
              this.$store.dispatch('user/updateUsers', {
                readAll: 'YES',
                source: 'WEB'
              })
            },
            hideauth: function () {
                this.password = '';
                this.$refs['user-create-modal'].hide();
            },
            currentDate(){
                let date = new Date()
                date = this.$moment(date.toDateString()).format('YYYY-MM-DD')
                console.log(date)
                return date
            },
        },
        mounted() {
            //this.model = this.row readSkillCategory
          //this.$store.dispatch('role/readSkillCategory',{readAll:"YES"})
        },
        computed:{
            ...mapState(['grade','auth','role','user']),
          roles(){
            return  this.role.roles.data?this.role.roles.data:[]
          },
        },
        watch: {
            show: function () {
                let self = this;
              // eslint-disable-next-line no-unused-vars
                Object.keys(this.model).forEach(function(key,index) {
                  self.model[key] = '';
                });
                if (this.type !== 'edit') this.$store.commit("user/updateScreen", 'enquiry')
                this.$store.dispatch('role/updateRoles',{readAll:"YES"})
                // eslint-disable-next-line no-unused-vars
                Object.keys(this.row).forEach(function(key,index) {
                    self.model[key] = self.row[key];
                });
                this.$refs['user-create-modal'].show()
            }
        },
    }
</script>
