<template>
  <div class="row">
    <div class="col-md-12 mg-t-10">
      <div class="card ht-100p">
        <div class="card-header d-flex align-items-center justify-content-between">
          <h6 class="mg-b-0">{{ this.type | titleCase }} Role</h6>
          <div class="d-flex tx-18">

            <!--                        <a href="" class="link-03 lh-0"><i class="icon ion-md-refresh"></i></a>-->
            <!--                        <a href="" class="link-03 lh-0 mg-l-10"><i class="icon ion-md-more"></i></a>-->
          </div>
        </div>
        <p v-if="role.privilegeLoading" align="center" class="mt-3"><bar-loader  class="custom-class" color="#6d0606" loading="loading" :size="150" sizeUnit="px"></bar-loader></p>
        <b-form v-else class="p-4" @submit.prevent="createRole">
          <b-form-row>
            <b-col md="6">
              <b-form-group label-for="roleName" label="Role Name *">
                <input class="form-control" required id="roleName" v-model="model.roleName" @input="filterOutBadChar()">
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-for="roleLimit" label="Transaction Limit *">
                <b-input required type="number" step="0.01" id="roleLimit" v-model="model.roleTrnLimit"></b-input>
              </b-form-group>
            </b-col>
            <b-col md="12" style="bottom: 10px;">
              <b-check-group style="font-size: 12px;" v-model="privilegeIds">
                <span v-for="object in Object.keys(privileges)" v-bind:key="object">
                  <p style="margin-top: 25px; font-weight: bolder; color: #0168fa;">{{ object | titleCase }}</p>
                  <b-check v-for="privilege in  privileges[object]" :value="privilege.privilegeId" v-bind:key="privilege.privilegeId">
                    <span v-if="privilege.privilegeType === 'CHECKER'">
                      {{object + ' ' + privilege.privilegeType | titleCase}}
                    </span>
                    <span v-else>
                      {{privilege.privilegeType+' '+object | titleCase}}
                    </span>
                  </b-check>
                  &nbsp;
                </span>
              </b-check-group>
            </b-col>
            <base-button :loading="role.roleLoading" title="Save"/>
          </b-form-row>
        </b-form>
      </div><!-- card -->
    </div>
  </div><!-- row -->
</template>

<script>
// import FlotChart from "../FlotChart";
import {mapState} from 'vuex'
import BaseButton from "@/components/button/BaseButton";
export default {
  name: "TopCards",
  props:['type', 'row'],
  components:{
    BaseButton
  },
  data(){
    return {
      privilegeIds:[],
      value: [
        { name: 'Javascript', code: 'js' }
      ],
      options: [

      ],
      model:{
        roleName:'',
        roleTrnLimit:'',
        rpRoleId:'',
        roleId:'',
        rpMaker:'',
        rpPrivilegeId:[],
        roleMaker:''
      },
      roles: ['initiator','verifier','authorizer']
    };
  },
  methods: {
    filterOutBadChar(){
      this.init.roleName = this.init.roleName.replace(/[^A-Z0-9\s]/i, '')
    },
    showAlert(title, message) {
      const options = {title: title, size: 'sm'}
      this.$dialogs.alert(message, options)
          .then()
    },
    createRole(){
      this.model.rpPrivilegeId = []
      this.model.roleMaker = this.auth.userInfo.userId
      this.model.rpMaker = this.auth.userInfo.userId
      for (let i = 0; i < this.privilegeIds.length; i++ ){
        this.model.rpPrivilegeId.push({privilegeId: this.privilegeIds[i]})
      }
      console.log(this.model)
      this.$store.dispatch('role/createRole', this.model)
    },
    groupBy(array, key){
      // Return the end result
      return array.reduce((result, currentValue) => {
        // If an array already present for key, push it to the array. Else create an array and push the object
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
            currentValue
        );
        // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
        return result;
      }, {}); // empty object is the initial value for result object
    },
    addTag (newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }
      this.options.push(tag)
      this.value.push(tag)
    }
  },
  computed:{
    ...mapState([
      'auth',
      'role',
    ]),
    privileges(){
      return this.groupBy(this.role.privileges.data?this.role.privileges.data:[], 'privilegeModuleName')
    }
  },
  mounted() {
    if (this.type === 'edit') {
      this.$store.dispatch('role/updateRolePrivileges', {roleId: this.row.roleId}).then(() => {
        this.privilegeIds = []
        for (let i = 0; i < this.role.rolePrivileges.privileges.length; i++) {
          this.privilegeIds.push(this.role.rolePrivileges.privileges[i].privilegeId)
        }
        this.model.rpPrivilegeId = this.role.rolePrivileges.privileges
        this.model.roleTrnLimit = this.role.rolePrivileges.roleTrnLimit
        this.model.rpRoleId = this.role.rolePrivileges.roleId
        this.model.roleId = this.role.rolePrivileges.roleId
        this.model.roleName = this.role.rolePrivileges.roleName
        this.model.roleStatus = this.role.rolePrivileges.roleStatus
      })
    }
    this.$store.dispatch('role/updatePrivileges', {readAll: 'YES'})
  }
}
</script>

<style scoped>
@media only screen and (min-width: 900px) {
  .top-card {
    width: 103%;
  }
}
@media only screen and (min-width: 707px) and (max-width: 900px) {
  .top-card {
    width: 104%;
  }
}

@media only screen and (min-width: 517px)  and (max-width: 707px) {
  .top-card {
    width: 105%;
  }
}

@media only screen and (min-width: 451px)  and (max-width: 517px) {
  .top-card {
    width: 106%;
  }
}

@media only screen  and (max-width: 451px) {
  .top-card {
    width: 107%;
  }
}
.h-100 {
  height: 100vh !important;
}
@media only screen and  (max-width: 991px) {
  .extra{
    display: none;
  }
}
.image-input{
  display: block;
  width: 100%;
  height: 300px;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

.placeholder{
  background: #F0F0F0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica,serif;
}

.placeholder:hover{
  background: #E0E0E0
}

.file-input{
  display: none;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>