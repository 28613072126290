import apiClient from "./BaseService";

export default {
  callAccountBalancesApi(payload) {
    payload.orgId = localStorage.orgID;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.post("account/balances",payload);
  }
};
