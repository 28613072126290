<template>
    <div class="content ht-100v pd-0">
        <div class="content-body pd-10">
            <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                <div>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                            <li class="breadcrumb-item">
                                <router-link to="#">Loan</router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Loan Schedules</li>
                        </ol>
                    </nav>
                    <h4 class="mg-b-0 tx-spacing--1">View Loan Schedules</h4>
                    <p class="tx-color-03 tx-12 mg-b-0">{{auth.userInfo.userFirstName}} {{auth.userInfo.userLastName}}</p>
                    <p class="tx-color-03 tx-12 mg-b-0"><strong>Last login:</strong> {{auth.userInfo.userLastLogin | moment('dddd, MMMM Do YYYY, h:mm:ss a')}}</p>
                </div>
                <div class="d-none d-md-block">
                    <download-excel
                            :data="loans"
                            class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
                        <PrinterIcon class="mg-r-5"/>{{$t('extra.downloadExcel')}}
                    </download-excel>
                    <download-excel
                            type="csv"
                            :data="loans"
                            class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
                        <PrinterIcon class="mg-r-5"/>{{$t('extra.downloadCsv')}}
                    </download-excel>

                </div>
            </div>
            <base-top-cards :carditems="cards"/>
            <hr>

            <base-table
                    :items="schedules"
                    :fields="fields"
                    :show-title="true"
                    :is-busy="loan.loanScheduleLoading"
                    class="mt-2"
                    filter-mode="balances"
            ></base-table>
        </div>
    </div>
</template>

<script>
    import BaseTopCards from "../../components/card/BaseTopCards";
    import BaseTable from "../../components/table/BaseTable";
    import { PrinterIcon } from 'vue-feather-icons'
    import {mapState} from 'vuex'

    export default {
        name: 'Loan',
        data() {
            return {
                fields: [
                    { key: 'serial', label: 'S/N', sortable: true, sortDirection: 'desc' },
                    { key: 'repaymentReference', label: 'Reference', sortable: true, sortDirection: 'desc' },
                    { key: 'repaymentDueDate', label: 'Due Date', sortable: true, class: 'text-left' },
                    { key: 'repaymentStartBalance', label: 'Start Balance', sortable: true, class: 'text-right' },
                    { key: 'repaymentAmountDue', label: 'Amount Due', sortable: true, class: 'text-right' },
                    { key: 'repaymentInterest', label: 'Interest', sortable: true, sortDirection: 'desc', class: 'text-right' },
                    { key: 'repaymentTotal', label: 'Total', sortable: true, class: 'text-right' },
                    // { key: 'lienAmount', label: 'Lien Amount', sortable: true, class: 'text-left' },
                    { key: 'repaymentBalance', label: 'Balance', sortable: true, class: 'text-left' },
                    { key: 'loanApprovalDate', label: 'Approval Date', sortable: true, class: 'text-left' },
                    { key: 'repaymentStatus', label: 'Status', sortable: true, class: 'text-left' },
                    { key: 'actions', label: 'Action', sortable: true, class: 'text-center' }
                ],
            }
        },
        components: {
            BaseTable,
            BaseTopCards,
            PrinterIcon,


        },
        computed: {
            cards:{
                get(){
                    return [
                        {
                            id: 0,
                            name: 'Total Borrowers',
                            number: this.loan.loans.totalBorrowers,// == null?'0.00':'GHS '+this.numberWithCommas(this.totalGhs),
                            color: 'bg-primary'
                        },
                        {
                            id: 1,
                            name: 'Total Loans',
                            number: this.loan.loans.totalLoans,// == null?'0.00':'GBP '+this.numberWithCommas(this.totalGbp),
                            color: 'bg-orange'
                        },
                        {
                            id: 2,
                            name: 'Performing Loans',
                            number: this.loan.loans.performingLoan,// == null?'0.00':'USD '+this.numberWithCommas(this.totalUsd),
                            color: 'bg-success'
                        },
                        {
                            id: 3,
                            name: 'Non-Performing Loans',
                            number: this.loan.loans.nonPerformingLoan,// == null?'0.00':'EUR '+this.numberWithCommas(this.totalEur),
                            color: 'bg-black-8'
                        }
                    ]
                },
                set(){

                }
            },
            loans(){
                return  this.loan.loans.data
            },
            schedules(){
                return  this.loan.schedules.data
            },
            authobj: function () {
                return this.$store.state.auth.response
            },
            ...mapState([
                'loan',
                'auth'
            ])
        },
        methods: {
            numberWithCommas(x) {
                let parts = x.toString().split(".");
                if (parts[1] == null)  parts[1] = '00'
                else if (parts[1].length === 0) parts[1] = parts[1]+'00'
                else if (parts[1].length === 1) parts[1] = parts[1]+'0'
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            },
        },
        created() {
            this.$store.dispatch("loan/updateLoans", {orgID: localStorage.orgID}, {root: false}).then()
            this.$store.dispatch("loan/loanSchedules", {repaymentLoanId: localStorage.repaymentLoanId}, {root: false}).then()
        }
    }


</script>

<style scoped>

</style>
