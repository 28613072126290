<template>
    <b-modal id="entries-preview-modal" :hide-header="true" :hide-footer="true" :centered="true" ref="entries-preview-modal">
        <div class="modal-body">
            <a href="#" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"
               @click="hide()">
                <span aria-hidden="true">&times;</span>
            </a>
            <div>
<!--              <a href="#" @click="showModal = !showModal"><b-icon-back/> Back</a>-->
<!--              <br>-->
              <a href="#" @click="navigate(row.aeCode)"><b-icon-pencil/> Edit Entries</a>
              <b-card>
                <sub-base-table
                    style="margin-top: -30px;"
                    :fields="fields"
                    :items="accountingRule.slab.entries"
                    :show-title="false"
                    filter-mode="accountingRuleEntries"
                ></sub-base-table>
              </b-card>
              <p v-if="!accountingRule.accountingRuleLoading" style="text-align: center; margin-top: 10px;">
                <b-button style="margin-right: 5px;"  @click="navigate(row.aeCode)" variant="warning">Edit Entries</b-button>
              </p>
              <p v-else style="text-align: center; margin-top: 10px;">
                <b-spinner variant="primary"/>
              </p>
            </div>
        </div><!-- modal-body -->
<!--        <accounting-rule-modal :show="showModal" :row="row"/>-->
    </b-modal>
</template>
<script>
    import { mapState } from "vuex"
    // import BaseTable from "@/components/table/BaseTable";
    import SubBaseTable from "@/components/table/SubBaseTable";
    // import AccountingRuleModal from "@/components/modal/AccountingRuleModal";
    // import BaseButton from "../button/BaseButton";
    export default {
        props:['show','row','slabModel'],
        components:{SubBaseTable},
        name: 'EntriesPreviewModal',
        data(){
            return{
                showModal: false,
                password: '',
                model:{
                  cqId: '',
                  cqJson: '',
                  cqType: '',
                  cqMode: '',
                  cqBankCode: '',
                  cqReason: '',
                  cqChecker: ''
                },
              fields: [
                { key: 'entryDrCr', label: 'DrCr', sortable: true, sortDirection: 'desc' },
                { key: 'entryAeCode', label: 'Code', sortable: true, sortDirection: 'desc' },
                { key: 'entryAccount', label: 'Account', sortable: true, sortDirection: 'desc' },
                { key: 'entryAmount', label: 'Amount', sortable: true, sortDirection: 'desc' },
                { key: 'entryAmountType', label: 'AmountType', sortable: true, sortDirection: 'desc' }
              ],
            }
        },
        methods:{
          navigate(_id){
            console.log(_id)
            this.$router.push({ name: 'CreateAccountingRule', params: { aeCode: _id, row: this.row }, query: { aeCode: _id } })
          },
          // eslint-disable-next-line no-unused-vars
            isJson(_json){
              let reg = new RegExp('^[0-9]+$')
              console.log(reg.test(_json.toString()))
              if (reg.test(_json.toString())) return false
              if (typeof _json === 'object') return true
              try {
                JSON.parse(_json.toString())
                console.log('>>>>>>>>>',_json.toString())
              }catch (e){
                return false
              }
              return true
            },
            hide() {
                this.$refs['entries-preview-modal'].hide()
            },
            async submit(_mode){
                this.model.cqId = this.row.cqId
                this.model.cqJson = this.row.cqJson
                this.model.cqType = this.row.cqType
                this.model.cqMode = _mode
                this.model.cqChecker = this.auth.userInfo.userId
                this.model.cqBankCode = '000000'
                await this.$store.dispatch("queue/authorizeQueue", this.model, {root: false});
                this.hide();
                this.loadData()
                // let self = this;
                // eslint-disable-next-line no-unused-vars
                // Object.keys(this.model).forEach(function(key,index) {
                //     self.model[key] = '';
                // });

            },
            loadData(){
              this.$store.dispatch('queue/updateQueues', {cqMode: 'READ',source: 'WEB'})
            },
            hideauth: function () {
                this.password = '';
                this.$refs['entries-preview-modal'].hide();
            },
            currentDate(){
                let date = new Date()
                date = this.$moment(date.toDateString()).format('YYYY-MM-DD')
                console.log(date)
                return date
            },
        },
        mounted() {
            //this.model = this.row readSkillCategory
          //this.$store.dispatch('role/readSkillCategory',{readAll:"YES"})
        },
        computed:{
            ...mapState(['auth','accountingRule']),
          roles(){
            return  this.role.roles.data?this.role.roles.data:[]
          },
        },
        watch: {
            show: function () {
                let self = this;
              // eslint-disable-next-line no-unused-vars
                Object.keys(this.model).forEach(function(key,index) {
                  self.model[key] = '';
                });
                this.$store.dispatch("accountingRule/updateSlab", {slabAeCode: this.slabModel.slabAeCode ,slabCode: this.slabModel.slabCode})
                // this.$store.dispatch('role/updateRoles',{readAll:"YES"})
                // eslint-disable-next-line no-unused-vars
                Object.keys(this.row).forEach(function(key,index) {
                    self.model[key] = self.row[key];
                });
                console.log(">>>>>>>>>>>>>>>><<<<<<<<<<<<<<<<<")
                this.$refs['entries-preview-modal'].show()
            }
        },
    }
</script>
