<template>
    <div>
        <form v-if="auth.regScreen === 'reg'" @submit.prevent = "register()">
            <!--TODO STEP 1-->
            <div v-if="screen === 'stepOne'" class="row">
                <div class="col-12">
                    <h6>Register</h6>
                    <div class="form-group">
                        <div class="input-group">
                            <input id="firstname" v-model="model.userFirstName" @input="filterOutBadChar()"  name="userFirstName" type="text" class="form-input" required/>
                            <label class="input-label" for="firstname">
                                <span class="label-span">{{$t('auth.userFirstName')}}</span>
                            </label>
                        </div>
                        <p v-if="!model.userFirstName" class="input-error">{{$t('auth.userFirstNameIsRequired')}}.</p>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <div class="input-group">
                            <input id="lastname" v-model="model.userLastName" @input="filterOutBadChar()"  name="userLastName" type="text" class="form-input" required/>
                            <label class="input-label" for="lastname">
                                <span class="label-span">{{$t('auth.userLastName')}}</span>
                            </label>
                        </div>
                        <p v-if="!model.userLastName" class="input-error">{{$t('auth.userLastNameIsRequired')}}.</p>
                    </div>
                </div>
    <!--            <button type="submit" class="app-btn btn btn-primary center-block col-5">Register</button>-->
    <!--            <span class="col-7"></span>-->
                <div class="col-12">
                    <span class="app-btn btn btn-primary center-block" @click="toScreenTwo()">Next <b-icon-arrow-right></b-icon-arrow-right></span>
                </div>
            </div>
            <!--TODO STEP 2-->
            <div v-if="screen === 'stepTwo'" class="row">
                <div class="col-12">
                    <h6>Register</h6>
                    <div class="form-group">
                        <div class="input-group">
                            <input id="email" v-model="model.email" type="email" name="email" class="form-input" required/>
                            <label class="input-label" for="email">
                                <span class="label-span">{{$t('auth.email')}}</span>
                            </label>
                        </div>
                        <p v-if="!model.email" class="input-error">{{$t('auth.emailIsRequired')}}.</p>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <div class="input-group">
                            <select id="countryCode" v-model="model.countryCodeOriginal" name="countryCode" class="form-input" required>
                                <option v-for="country in auth.country" :value="country.dial_code" v-bind:key="country.code">{{country.dial_code}}</option>
                            </select>
<!--                            <input id="countryCode" v-model="model.countryCode" type="text" name="countryCode" class="form-input" required/>-->
                            <label class="input-label" for="countryCode">
                                <span class="label-span">{{$t('auth.countryCode')}}</span>
                            </label>
                        </div>
                        <p v-if="!model.countryCode" class="input-error">{{$t('auth.phoneNumberIsRequired')}}.</p>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <div class="input-group">
                            <input id="phone" v-model="model.phone" type="text" name="phoneNumber" class="form-input" required/>
                            <label class="input-label" for="phone">
                                <span class="label-span">{{$t('auth.phoneNumber')}}</span>
                            </label>
                        </div>
                        <p v-if="!model.phone" class="input-error">{{$t('auth.phoneNumberIsRequired')}}.</p>
                    </div>
                </div>
                <div class="col-5">
                    <button  @click="screen = 'stepOne'" class="app-btn btn btn-primary center-block"><b-icon-arrow-left></b-icon-arrow-left> Previous</button>
                </div>
                <span class="col-2"></span>
                <div class="col-5">
                    <button  @click="toScreenThree()" class="app-btn btn btn-primary center-block">Next <b-icon-arrow-right></b-icon-arrow-right></button>
                </div>
            </div>
            <!--TODO STEP 3-->
            <div v-if="screen === 'stepThree'" class="row">
                <div class="col-12">
                    <h6>Register</h6>
                    <div class="form-group">
                      <span style="display: flex">
                        <div class="input-group">
                            <input id="password" v-model="model.password" :type="inputType" minlength="8" maxlength="13" required  name="password" class="form-input"/>
                            <label class="input-label" style="border-top-right-radius: 0;border-bottom-right-radius: 0;" for="password">
                                <span class="label-span">{{$t('auth.password')}}</span>
                            </label>
                        </div>
                        <b-input-group-text style="border-top-left-radius: 0;border-bottom-left-radius: 0; cursor: pointer; height: 50px">
                          <b-icon-eye v-if="inputType === 'password'" @click="inputType = 'text'" />
                          <b-icon-eye-slash v-else @click="inputType = 'password'" />
                        </b-input-group-text>
                      </span>
                        <p v-if="!model.password" class="input-error">{{$t('auth.passwordIsRequired')}}.</p>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                      <span style="display: flex">
                        <div class="input-group">
                            <input id="passwordConfirmation" v-model="model.passwordConfirmation"  minlength="6" maxlength="13" required  name="passwordConfirmation" :type="inputType1" class="form-input"/>
                            <label class="input-label" style="border-top-right-radius: 0;border-bottom-right-radius: 0;" for="passwordConfirmation">
                                <span class="label-span">{{$t('auth.repeatPassword')}}</span>
                            </label>
                        </div>
                        <b-input-group-text style="border-top-left-radius: 0;border-bottom-left-radius: 0; cursor: pointer; height: 50px">
                          <b-icon-eye v-if="inputType1 === 'password'" @click="inputType1 = 'text'" />
                          <b-icon-eye-slash v-else @click="inputType1 = 'password'" />
                        </b-input-group-text>
                      </span>
                      <p v-if="!model.passwordConfirmation" class="input-error">{{$t('auth.repeatPasswordIsRequired')}}.</p>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <h6 class="h6">Password Requirement:</h6>
                        <ul >
                            <li :style="'color:'+minLength">Minimum length: 8</li>
                            <li :style="'color:'+maxLength">Maximum length: 13</li>
                            <li :style="'color:'+hasLettersNumbers">Must contain letters and numbers</li>
                            <li :style="'color:'+hasLowerCase">Must contain a lowercase letter</li>
                            <li :style="'color:'+hasUpperCase">Must contain an uppercase letter</li>
                            <li :style="'color:'+hasSpecialXters">Must contain special characters</li>
                            <li :style="'color:'+hasSpace">Must not contain white space</li>
                        </ul>
                    </div>
                </div>
<!--                <div class="col-12">-->
                <div class="col-5">
                    <span  @click="screen = 'stepTwo'" class="app-btn btn btn-primary center-block"><b-icon-arrow-left></b-icon-arrow-left> Previous</span>
                </div>
                    <span class="col-2"></span>
<!--                    <button  @click="screen = 'stepThree'" class="app-btn btn btn-primary center-block col-5"> Register<b-icon-arrow-right></b-icon-arrow-right></button>-->
                    <auth-base-button :title="$t('auth.register')" :loading="auth.authLoading" class="center-block col-5"></auth-base-button>
<!--                </div>-->
            </div>
        </form>
        <form v-if="auth.regScreen === 'otp'" @submit.prevent = "OTPVerification()">
        <!--TODO OTP VERIFICATION-->
        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <div class="input-group">
                        <input style="text-align:center" id="otp" v-model="model.otp" type="text" class="form-input" :placeholder="$t('auth.otpPlaceholder')"/>
                        <label class="input-label" for="otp">
                            <span class="label-span">OTP</span>
                        </label>
                    </div>
                   <p v-if="!model.otp" class="i nput-error">{{$t('auth.otpIsRequired')}}.</p>
                </div>
            </div>
            <div class="col-12">
                <auth-base-button @click="OTPVerification()" :title="$t('auth.verifyOtp')" :loading="auth.authLoading"></auth-base-button>
            </div>
        </div>
    </form>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import AuthBaseButton from "../button/AuthBaseButton";
    export default {
        name: "RegisterForm",
        components: {
            AuthBaseButton
        },
        data() {
            return {
              inputType1:"",
              inputType:"",
                model: {
                    email: '',
                    userFirstName: '',
                    userLastName: '',
                    middleName: '',
                    pin: '1234',
                    password: '',
                    passwordConfirmation: '',
                    phone: '',
                    token: '',
                    username: '',
                    uniqueRef: '',
                    createAccount: 'YES',
                    countryCode: '+234',
                    countryCodeOriginal: '+234',
                    source: 'WEB',
                    roles : ['1']
                },
                screen:'stepOne'
            }
        },
        methods: {
            filterOutBadChar(){
                this.model.userFirstName = this.model.userFirstName.replace(/[^A-Z0-9]/i, '')
                this.model.userLastName = this.model.userLastName.replace(/[^A-Z0-9]/i, '')
                this.model.middleName = this.model.userLastName.replace(/[^A-Z0-9]/i, '')
                console.log("Hey",this.model.userFirstName)
            },
            async register() {
                if(this.minLength !== 'green'||
                    this.maxLength  !== 'green'||
                    this. hasLettersNumbers !== 'green' ||
                    this.hasSpecialXters !== 'green' ||
                    this.hasLowerCase !== 'green' ||
                    this.hasUpperCase !== 'green' ||
                    this.hasSpace !== 'green'
                ) return;
                if (this.model.userFirstName && this.model.userLastName && this.model.email && this.model.phone) {
                    this.model.dialogs = this.$dialogs;
                    this.model.countryCode = this.model.countryCodeOriginal.replace('+','')
                    await this.$store.dispatch("auth/initiateEnrollment", this.model, {root: false});
                    this.model.uniqueRef = this.auth.response.uniqueref;
                    this.model.username = this.model.phone;
                    // console.log(this.model)
                    //console.log(this.auth.response);
                }
            },
            async OTPVerification() {
                this.model.dialogs = this.$dialogs;
                await this.$store.dispatch("auth/completeEnrollment", this.model, { root: false })
                //if (this.auth.response.responsecode === '00') this.$router.push('/');
            },
            isNumberKey(evt){
                let charCode = (evt.which) ? evt.which : event.keyCode
                return !(charCode > 31 && (charCode < 48 || charCode > 57));

            },
            toScreenTwo(){
                if (this.model.userFirstName && this.model.userLastName)
                    this.screen = 'stepTwo'
            },
            toScreenThree(){
                if (this.model.email && this.model.phone)
                    this.screen = 'stepThree'
            },

        },
        computed:{
            ...mapState([
                'company',
                'document',
                'auth',
                'auth',
                'officer',
            ]),
            minLength:function(){
                return this.model.password.length >= 8?'green':'red'
            },
            maxLength:function(){
                return this.model.password.length <= 13?'green':'red'
            },
            hasLettersNumbers:function(){
                return ( /[a-zA-Z]/g.test(this.model.password) && /[0-9]/g.test(this.model.password))?'green':'red'
            },
            hasSpecialXters:function(){
                return /[^a-zA-Z0-9]/g.test(this.model.password)?'green':'red'
            },
            hasLowerCase:function(){
                return /[a-z]/g.test(this.model.password)?'green':'red'
            },
            hasUpperCase:function(){
                return /[A-Z]/g.test(this.model.password)?'green':'red'
            },
            hasSpace:function(){
                return !(/\s/g.test(this.model.password))?'green':'red'
            },

        },

        watch: {
            'auth': function() {
                // console.log('Hello World!')
            }
        },
        mounted() {
            // this.$store.auth.watch(this.$store.getters.getN, n => {
            //     console.log('watched: ', n)
            // })
        },
    }
</script>

<style scoped>

</style>
