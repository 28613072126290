<template>
  <div class="content ht-100v pd-0">
    <div class="content-body pd-10">
      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
        <div>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-style1 mg-b-10">
              <li class="breadcrumb-item">
                <router-link to="#">Report Menu</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Report - {{reportName}}</li>
            </ol>
          </nav>
          <h4 class="mg-b-0 tx-spacing--1">{{reportName}}</h4>
          <p class="tx-color-03 tx-12 mg-b-0">{{auth.userInfo.userFirstName}} {{auth.userInfo.userLastName}}</p>
          <p class="tx-color-03 tx-12 mg-b-0"><strong>Last login:</strong> {{auth.userInfo.userLastLogin | moment('dddd, MMMM Do YYYY, h:mm:ss a')}}</p>
        </div>
        <div class="d-none d-md-block">
          <download-excel
              type="excel"
              :data="reports"
              class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
            <PrinterIcon class="mg-r-5"/>Download Excel
          </download-excel>
          <download-excel
              type="csv"
              :data="reports"
              class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
            <PrinterIcon class="mg-r-5"/>Download CSV
          </download-excel>
        </div>
      </div>
      <report-query-form/>
      <br>
      <base-table
          :items="reports"
          :fields="report.fields"
          filter-mode="intrabankMultiple"
          :is-busy="report.reportsLoading">
      </base-table>
      <b-modal id="queryaudit" ref="queryaudit" hide-header hide-footer>
        <QueryAuditForm />
      </b-modal>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import { PrinterIcon } from 'vue-feather-icons'
import BaseTable from "../../components/table/BaseTable";
import QueryAuditForm from "@/components/form/QueryAuditLogForm";
import ReportQueryForm from "../../components/form/ReportQueryForm";

export default {
  name: "ReportEngine",
  components:{QueryAuditForm, BaseTable, ReportQueryForm,PrinterIcon },
  data(){
    return{
      fields: [
        // {key: 'actionsReport', label: 'Actions'}
      ],
    }
  },
  methods:{
    loadFields(_json){
      let val = _json[0];
      let fields = []
      for(let j in val){
        let sub_key = j;
        // eslint-disable-next-line no-unused-vars
        let sub_val = val[j];
        fields.unshift({key: sub_key, label: sub_key, sortable: true, class: 'text-left'})
        console.log(sub_key);
      }
      this.$store.commit('report/updateFields', this.fields)
    }
  },
  computed : {
    ...mapState(['company','report','auth']),
    reports:{
      get(){
        // console.log(this.report.reports)
        let rep =this.report.reportSingle
        this.loadFields(rep)
        return this.report.reportSingle
      },
      set(val){
        this.$store.commit('report/updateReports',val)
      }
    },
    reportName(){
      return localStorage.reportName
    },
  },
  created(){

  }
}
</script>

<style scoped>

</style>