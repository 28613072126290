<template>
    <b-modal id="image-upload-modal" :hide-header="true" :hide-footer="true" :centered="true" ref="image-upload-modal">
        <div class="modal-body">
            <a href="#" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"
               @click="hide()">
                <span aria-hidden="true">&times;</span>
            </a>
            <div>
                <h4>{{ title }}</h4>
                <p class="tx-color-03">Please, continue to download</p>
                <button v-if="!loading" @click="downloadBase64" class="app-btn btn-primary center-block">continue</button>
                <button v-else disabled class="app-btn btn btn-primary center-block">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </button>
            </div>
        </div><!-- modal-body -->
    </b-modal>
</template>

<script>
    import {mapState} from 'vuex'
    export default {
        name: 'DownloadReceiptModal',
        props:['uploadType','isLoading','show','title','row'],
        data() {
            return {
                model:{
                    data: '',
                    base64: ''
                },
                file: {
                    accountnumber: '',
                    file: '',
                    amount: '',
                    status: false,
                    bank: {},
                    narration: '',
                    uploadedFiles: [],
                    uploadError: null,
                    currentStatus: null,
                    uploadFieldName: 'photos',
                },
            }
        },
        components: {
          // BaseButton,
            // BaseTable,
            // BaseTopCards,
            // PrinterIcon,
            // PlusIcon,
        },
        computed: {
            grades(){
                return  this.grade.grades
            },
            authobj: function () {
                return this.$store.state.auth.response
            },
            loading: function () {
                return this.$store.state.cashPickup.cashPickupLoading
            },
            ...mapState([
                'grade',
                'auth'
            ])
        },
        methods: {
            uploadFile(event) {
                let input = this.$refs.file;
                console.log(input.files[0])
                console.log(event)
                this.handleUpload(event)
            },
            downloadBase64() {
              this.$store.dispatch("cashPickup/createTransactionReceipt",
                  {
                    transactionReference:this.row.transactionContractReference,
                    transactionType:'CP',
                    imtoCode:this.auth.userInfo.userMtoCode
                  }, {root: false}).then()
              },
            async handleUpload(event) {
                const file = event.target.files[0];
                console.log('file.target.result >>> ', file.target)
                const reader = new FileReader;
                try {
                    reader.onload = e => {
                        console.log('e.target.result >>>> ', e.target.result)
                        this.model.base64 = e.target.result
                    };
                  reader.readAsDataURL(file);
                } catch (e) {
                    console.warn(e.message)
                }
            },
            loadData(){
                this.$store.dispatch(this.readType, this.readData, {root: false}).then()
            },
            hide() {
                this.$refs['image-upload-modal'].hide()
            },
            onSelect({name, iso2, dialCode}) {
                this.model.employeeCountryCode =  dialCode
                console.log(name, iso2, dialCode);
            }
        },
        created() {
            this.loadData()
        },
        watch: {
            show: function () {
                //let self = this;
                // eslint-disable-next-line no-unused-vars
                // Object.keys(this.uploadData).forEach(function(key,index) {
                //     self.model[key] = self.uploadData[key];
                // });
                this.$refs['image-upload-modal'].show()
            }
        },
    }


</script>

<style scoped>
    .dropbox {
        outline: 2px dashed grey; /* the dash box */
        outline-offset: -10px;
        background: lightcyan;
        color: dimgray;
        padding: 10px 10px;
        min-height: 200px; /* minimum height */
        position: relative;
        cursor: pointer;
    }

    .input-file {
        opacity: 0; /* invisible but it's there! */
        width: 100%;
        height: 100%;
        position: absolute;
        cursor: pointer;
    }

    .dropbox:hover {
        background: lightblue; /* when mouse over to the drop zone, change color */
    }

    .dropbox p {
        font-size: 1.2em;
        text-align: center;
        padding: 50px 0;
    }
</style>
