import apiClient from "./BaseService";

export default {
  callLogonApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgID;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    // return apiClient.post("auth/login", {payload: btoa(btoa(btoa(btoa(JSON.stringify(payload)))))});
    return apiClient.post("auth/login", payload);
  },
  callValidateHardTokenApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgID;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.post("auth/validateHardToken", payload);
  },
  callLogoutApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgID;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.post("auth/logout", payload);
  },
  callInitiateEnrollmentApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgID;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.post("enrollment", payload);
  },
  callCompleteEnrollmentApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgID;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.post("enrollment", payload);
  },
  callInitiateResetPasswordApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgID;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.post("auth/initiatePasswordReset", payload);
  },
  callCompleteResetPasswordApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgID;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.post("auth/completePasswordReset", payload);
  },
  callResendOtpApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgID;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.post("resendOtp", payload);
  },
  callVerifyTokenApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgID;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.post("auth/validateToken", payload);
  },
  callChangePasswordApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgID;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.post("user/changeDefaultPassword", payload);
  },
  callGetUsersApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgID;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.post("userReadAll", payload);
  },
};
