<template>
    <div>
        <button v-if="loading === true" disabled :class="btnClass">
            <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Loading...
        </button>
        <button v-else  :class="btnClass">{{title}}</button>
    </div>
</template>

<script>
    export default {
        props:['loading','title','variant'],
        name: "BaseButton",
        computed:{
          btnClass(){
            if (this.variant === 'success') return 'btn btn-success btn-block'
            else return 'btn btn-brand-02 btn-block'
          }
        }
    }
</script>

<style scoped>

</style>