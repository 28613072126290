<template>
    <b-modal id="checkers-modal" :hide-header="true" :hide-footer="true" :centered="true" ref="checkers-modal">
        <div class="modal-body">
            <a href="#" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"
               @click="hide()">
                <span aria-hidden="true">&times;</span>
            </a>
            <div>
              <b-card>
                <ul v-if="row.cqJson != null">
                  <li v-for="(value, key) in JSON.parse(row.cqJson)" :key="key">
                    <span v-if="!isJson(value)">
                      <b>{{ key | camelToSentenceCase }}</b>: {{ value }}
                    </span>
                    <span v-else-if="value.length < 1">
                      <ul>
                        <li v-for="(val, ky) in value" :key="ky">
                          {{ ky }}: {{ val }}
                        </li>
                      </ul>
                    </span>
                    <span v-else>
                      {{ key }}:
                      <sub-base-table
                          style="margin-top: -30px;"
                          :fields="fields"
                          :items="value"
                          :show-title="false"
                          filter-mode="accountingRuleEntries"
                      ></sub-base-table>
                    </span>
                  </li>
                </ul>
                <b-form-group label="Reason">
                  <textarea class="form-control" v-model="model.cqReason" @input="filterOutBadChar()"></textarea>
                </b-form-group>
              </b-card>
              <span v-if="!row.isMaker">
                <p v-if="!queue.queueLoading" style="text-align: center; margin-top: 10px;">
                  <b-button style="margin-right: 5px;" @click="submit('REJECTED')" variant="danger" v-if="row.cqStatus === 'PENDING'">Reject</b-button>
                  <b-button style="margin-left: 5px;"  @click="submit('AUTHORIZE')" variant="success" v-if="row.cqStatus === 'PENDING'">Authorize</b-button>
                </p>
                <p v-else style="text-align: center; margin-top: 10px;">
                  <b-spinner variant="primary"/>
                </p>
              </span>
            </div>
        </div><!-- modal-body -->
    </b-modal>
</template>
<script>
    import { mapState } from "vuex"
    import SubBaseTable from "@/components/table/SubBaseTable";
    // import BaseButton from "../button/BaseButton";
    export default {
        props:['show','row'],
        components:{SubBaseTable},
        name: 'CheckerModal',
        data(){
            return{
                password: '',
                model:{
                  cqId: '',
                  cqJson: '',
                  cqType: '',
                  cqMode: '',
                  cqBankCode: '',
                  cqReason: '',
                  cqChecker: ''
                },
                selfRow:{}
            }
        },
        methods:{
          filterOutBadChar(){
            this.model.cqReason = this.model.cqReason.replace(/[^A-Z0-9\s]/i, '')
          },
          // eslint-disable-next-line no-unused-vars
            isJson(_json){
              if (_json == null) return false
              if (typeof _json === 'boolean') return false
              let reg = new RegExp('^[0-9]+$')
              console.log(reg.test(_json.toString()))
              if (reg.test(_json.toString().replace('.',''))) return false
              if (typeof _json === 'object') return true
              try {
                JSON.parse(_json.toString())
                console.log('>>>>>>>>>',_json.toString())
              }catch (e){
                return false
              }
              return true
            },
            hide() {
                this.$refs['checkers-modal'].hide()
            },
            async submit(_mode){
                this.model.cqId = this.row.cqId
                this.model.cqJson = this.row.cqJson
                this.model.cqType = this.row.cqType
                this.model.cqMode = _mode
                this.model.cqChecker = this.auth.userInfo.userId
                this.model.cqBankCode = '000000'
                await this.$store.dispatch("queue/authorizeQueue", this.model, {root: false});
                if(this.queue.response.responseCode === "00") {
                  //this.selfRow = JSON.stringify(this.model)
                  this.hide();
                  this.loadData()
                  this.model={
                    cqId: '',
                    cqJson: '',
                    cqType: '',
                    cqMode: '',
                    cqBankCode: '',
                    cqReason: '',
                    cqChecker: ''
                  }
                }
                // let self = this;
                // eslint-disable-next-line no-unused-vars
                // Object.keys(this.model).forEach(function(key,index) {
                //     self.model[key] = '';
                // });

            },
            loadData(){
              this.$store.dispatch("queue/updateQueues", {cqMode: 'READ',source: 'WEB', isMaker: 'NO', solId: this.auth.userInfo.soleId, userId: this.auth.userInfo.userId}, {root: false}).then()
              this.$store.dispatch("queue/updateMakerQueues", {cqMode: 'READ',source: 'WEB', isMaker: 'YES', solId: this.auth.userInfo.soleId, userId: this.auth.userInfo.userId}, {root: false}).then()
            },
            hideauth: function () {
                this.password = '';
                this.$refs['checkers-modal'].hide();
            },
            currentDate(){
                let date = new Date()
                date = this.$moment(date.toDateString()).format('YYYY-MM-DD')
                console.log(date)
                return date
            },
        },
        mounted() {
            //this.model = this.row readSkillCategory
          //this.$store.dispatch('role/readSkillCategory',{readAll:"YES"})
        },
        computed:{
            ...mapState(['queue','auth','role','user']),
          roles(){
            return  this.role.roles.data?this.role.roles.data:[]
          },
          fields(){
              if (this.row.cqType === 'ACCOUNTING_RULE_ENTRY' || this.row.cqType === 'ACCOUNTING_RULE')
                return  [
                  { key: 'entryDrCr', label: 'DrCr', sortable: true, sortDirection: 'desc' },
                  // { key: 'entryAeCode', label: 'Code', sortable: true, sortDirection: 'desc' },
                  { key: 'entryAccount', label: 'Account', sortable: true, sortDirection: 'desc' },
                  { key: 'entryAmount', label: 'Amount', sortable: true, sortDirection: 'desc' },
                  { key: 'entryAmountType', label: 'AmountType', sortable: true, sortDirection: 'desc' }
                ]
            else return null
          }
        },
        watch: {
            show: function () {
                let self = this;
              // eslint-disable-next-line no-unused-vars
                Object.keys(this.model).forEach(function(key,index) {
                  self.model[key] = '';
                });
                console.log('row>>>', self.row)
                // if (this.type !== 'edit') this.$store.commit("user/updateScreen", 'enquiry')
                // this.$store.dispatch('role/updateRoles',{readAll:"YES"})
                // eslint-disable-next-line no-unused-vars
                Object.keys(this.row).forEach(function(key,index) {
                    self.model[key] = self.row[key];
                });
                this.$refs['checkers-modal'].show()
            }
        },
    }
</script>
