<template>
    <b-modal id="image-upload-modal" :hide-header="true" :hide-footer="true" :centered="true" ref="image-upload-modal">
        <div class="modal-body">
            <a href="#" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"
               @click="hide()">
                <span aria-hidden="true">&times;</span>
            </a>
            <div>
                <h4>{{ title }}</h4>
                <p class="tx-color-03">Upload file for processing</p>
                <b-form v-if="!model.base64" @submit.prevent="" enctype="multipart/form-data">
                    <b-form-group>
                        <div class="dropbox">
                            <input type="file" class="input-file" id="files" :name="file.uploadFieldName"
                                   :disabled="isLoading" accept="image/jpeg, image/png, image/jpg"
                                   @change="uploadFile" ref="file">
                            <p v-if="!isLoading">
                                Drag your image(.jpeg, .jpg, .png) here to begin<br> or click to browse
                            </p>
                            <p v-if="isLoading">
                                Uploading image(.jpeg, .jpg, .png)...
                            </p>
                        </div>
                    </b-form-group>
                </b-form>
                <div v-else>
                  <a target="_blank" :href="model.base64"><img :src="model.base64" width="100%" alt=""/></a>
                  <b-row>&nbsp;&nbsp;&nbsp;&nbsp;<div><button @click="model.base64 = ''" class="app-btn btn-secondary center-block">cancel</button></div>&nbsp;
                    <div>
                      <button v-if="!loading" @click="uploadBase64" class="app-btn btn-primary center-block">upload</button>
                      <button v-else disabled class="app-btn btn btn-primary center-block">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      </button>
                    </div>
                  </b-row>
                </div>
            </div>
        </div><!-- modal-body -->
    </b-modal>
</template>

<script>
    import {mapState} from 'vuex'
    export default {
        name: 'UploadImageModal',
        props:['uploadType','isLoading','show','title','row'],
        data() {
            return {
                model:{
                    data: '',
                    base64: ''
                },
                file: {
                    accountnumber: '',
                    file: '',
                    amount: '',
                    status: false,
                    bank: {},
                    narration: '',
                    uploadedFiles: [],
                    uploadError: null,
                    currentStatus: null,
                    uploadFieldName: 'photos',
                },
            }
        },
        components: {
          // BaseButton,
            // BaseTable,
            // BaseTopCards,
            // PrinterIcon,
            // PlusIcon,
        },
        computed: {
            grades(){
                return  this.grade.grades
            },
            authobj: function () {
                return this.$store.state.auth.response
            },
            loading: function () {
                return this.$store.state.cashPickup.cashPickupLoading
            },
            ...mapState([
                'grade',
                'auth'
            ])
        },
        methods: {
            uploadFile(event) {
                let input = this.$refs.file;
                console.log(input.files[0])
                console.log(event)
                this.handleUpload(event)
            },
            uploadBase64() {
              this.$store.dispatch("cashPickup/uploadTransactionReceipt",
                  {
                    encodedReceipt:this.model.base64,
                    transactionReference:this.row.transactionContractReference,
                    transactionType:'CP',
                    imtoCode:this.auth.userInfo.userMtoCode
                  }, {root: false}).then()
              },
            async handleUpload(event) {
                const file = event.target.files[0];
                console.log('file.target.result >>> ', file.target)
                const reader = new FileReader;
                try {
                    reader.onload = e => {
                        console.log('e.target.result >>>> ', e.target.result)
                        this.model.base64 = e.target.result
                    };
                  reader.readAsDataURL(file);
                } catch (e) {
                    console.warn(e.message)
                }
            },
            loadData(){
                this.$store.dispatch(this.readType, this.readData, {root: false}).then()
            },
            hide() {
                this.$refs['image-upload-modal'].hide()
            },
            onSelect({name, iso2, dialCode}) {
                this.model.employeeCountryCode =  dialCode
                console.log(name, iso2, dialCode);
            }
        },
        created() {
            this.loadData()
        },
        watch: {
            show: function () {
                //let self = this;
                // eslint-disable-next-line no-unused-vars
                // Object.keys(this.uploadData).forEach(function(key,index) {
                //     self.model[key] = self.uploadData[key];
                // });
                this.$refs['image-upload-modal'].show()
            }
        },
    }


</script>

<style scoped>
    .dropbox {
        outline: 2px dashed grey; /* the dash box */
        outline-offset: -10px;
        background: lightcyan;
        color: dimgray;
        padding: 10px 10px;
        min-height: 200px; /* minimum height */
        position: relative;
        cursor: pointer;
    }

    .input-file {
        opacity: 0; /* invisible but it's there! */
        width: 100%;
        height: 100%;
        position: absolute;
        cursor: pointer;
    }

    .dropbox:hover {
        background: lightblue; /* when mouse over to the drop zone, change color */
    }

    .dropbox p {
        font-size: 1.2em;
        text-align: center;
        padding: 50px 0;
    }
</style>
