import apiClient from "./BaseService";

export default {
  callReadRolesApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgID;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.post("role/read", payload);
  },
  callCreatRoleApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgID;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.post("role/create", payload);
  },
  callAssignPrivilegesApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgID;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.post("rolePrivilege/create", payload);
  },
  callReadPrivilegesApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgID;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.post("privilege/read", payload);
  },
  callReadRolePrivilegesApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgID;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.post("role/readSingle", payload);
  },
};
