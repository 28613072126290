<template>
    <div class="app-wrap">
        <div class="row">
            <div class="col-12 col-md-6">
                <section class="left-side">
                    <div class="app-nav">
                        <nav class="navbar navbar-light">
                            <a class="navbar-brand" href="/">
<!--                                <img src="https://assets-base.s3.amazonaws.com/img/firstbank/logo.png" alt="FBNBank Logo">-->
                            </a>
                        </nav>
                    </div>

                    <div class="app-form">
                        <h1 class="title">FBN MTO</h1>
                        <LogonForm />
                    </div>
                    <p class="alt-links text-center">
<!--                        <span>-->
<!--                            {{$t('auth.dontHaveAnAccount')}}-->
<!--                        </span>-->
                        <span>
<!--                            <router-link to="/register"><a class="alt-link">{{$t('auth.signUp')}}</a></router-link> |-->
                            <router-link to="/forgot-password" class="tx-13"><a class="alt-link">{{$t('extra.forgotPassword')}}?</a></router-link>
                        </span>
                    </p>

                    <p class="divide"></p>
                    <div class="social-links text-center">
                        <a href="#" target="_blank" class="social-link">
                            <svg viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21.244 30.618H9.37399C6.88875 30.6151 4.50615 29.6265 2.74881 27.8692C0.991475 26.1119 0.00291121 23.7292 0 21.244V9.37399C0.00291121 6.88875 0.991475 4.50615 2.74881 2.74881C4.50615 0.991475 6.88875 0.00291121 9.37399 0H21.244C23.7294 0.00264658 26.1123 0.99108 27.8698 2.74844C29.6274 4.50581 30.6161 6.88857 30.619 9.37399V21.244C30.6161 23.7294 29.6274 26.1122 27.8698 27.8696C26.1123 29.6269 23.7294 30.6154 21.244 30.618ZM9.37399 2.29199C7.49655 2.29437 5.69672 3.04131 4.36926 4.36896C3.0418 5.6966 2.29512 7.49655 2.293 9.37399V21.244C2.29538 23.1213 3.04217 24.921 4.3696 26.2484C5.69703 27.5758 7.49673 28.3226 9.37399 28.325H21.244C23.1215 28.3229 24.9214 27.5762 26.2491 26.2487C27.5767 24.9213 28.3236 23.1214 28.326 21.244V9.37399C28.3239 7.49638 27.5771 5.6963 26.2494 4.36862C24.9217 3.04095 23.1216 2.29411 21.244 2.29199H9.37399Z" fill="url(#paint0_radial)"/>
                                <path d="M15.309 23.054C13.7772 23.054 12.2797 22.5998 11.0061 21.7487C9.73243 20.8977 8.73975 19.6881 8.15355 18.2729C7.56735 16.8577 7.41397 15.3004 7.71282 13.798C8.01166 12.2956 8.74929 10.9156 9.83245 9.83245C10.9156 8.74929 12.2956 8.01166 13.798 7.71282C15.3004 7.41397 16.8577 7.56735 18.2729 8.15355C19.6881 8.73975 20.8977 9.73243 21.7487 11.0061C22.5998 12.2797 23.054 13.7772 23.054 15.309C23.0516 17.3623 22.2348 19.331 20.7829 20.7829C19.3309 22.2349 17.3623 23.0516 15.309 23.054ZM15.309 9.911C14.241 9.911 13.1969 10.2277 12.3089 10.8211C11.4209 11.4144 10.7287 12.2578 10.32 13.2445C9.91131 14.2312 9.80438 15.317 10.0127 16.3645C10.2211 17.412 10.7354 18.3742 11.4906 19.1294C12.2458 19.8846 13.208 20.3989 14.2555 20.6072C15.303 20.8156 16.3888 20.7087 17.3755 20.2999C18.3622 19.8912 19.2056 19.1991 19.7989 18.3111C20.3923 17.4231 20.709 16.379 20.709 15.311C20.709 13.8788 20.1401 12.5053 19.1274 11.4926C18.1147 10.4799 16.7411 9.911 15.309 9.911Z" fill="url(#paint1_radial)"/>
                                <path d="M25.914 6.72098C25.914 7.11995 25.7957 7.50996 25.574 7.84167C25.3523 8.17339 25.0373 8.4319 24.6686 8.58453C24.3 8.73717 23.8944 8.77707 23.5031 8.69916C23.1119 8.62125 22.7524 8.42906 22.4704 8.14688C22.1884 7.8647 21.9964 7.50519 21.9186 7.11386C21.8409 6.72254 21.881 6.31697 22.0338 5.94843C22.1866 5.57988 22.4453 5.26494 22.7771 5.04343C23.109 4.82192 23.499 4.70378 23.898 4.70398C24.1628 4.70398 24.4251 4.75617 24.6697 4.85754C24.9144 4.95892 25.1367 5.10749 25.3239 5.2948C25.5111 5.48211 25.6596 5.70448 25.7608 5.94919C25.8621 6.1939 25.9141 6.45615 25.914 6.72098Z" fill="url(#paint2_radial)"/>
                                <defs>
                                    <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-2.74353 31.9739) scale(56.4921)">
                                        <stop stop-color="#F9ED41"/>
                                        <stop offset="0.241" stop-color="#FF833D"/>
                                        <stop offset="0.401" stop-color="#EE5468"/>
                                        <stop offset="0.489" stop-color="#E7407B"/>
                                        <stop offset="1" stop-color="#0028FF"/>
                                    </radialGradient>
                                    <radialGradient id="paint1_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-2.74353 31.9739) scale(56.4921)">
                                        <stop stop-color="#F9ED41"/>
                                        <stop offset="0.241" stop-color="#FF833D"/>
                                        <stop offset="0.401" stop-color="#EE5468"/>
                                        <stop offset="0.489" stop-color="#E7407B"/>
                                        <stop offset="1" stop-color="#0028FF"/>
                                    </radialGradient>
                                    <radialGradient id="paint2_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-2.74353 31.9739) scale(56.4921)">
                                        <stop stop-color="#F9ED41"/>
                                        <stop offset="0.241" stop-color="#FF833D"/>
                                        <stop offset="0.401" stop-color="#EE5468"/>
                                        <stop offset="0.489" stop-color="#E7407B"/>
                                        <stop offset="1" stop-color="#0028FF"/>
                                    </radialGradient>
                                </defs>
                            </svg>
                        </a>

                        <a href="#" target="_blank" class="social-link">
                            <svg viewBox="0 0 33 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M33 0.0100074C31.5639 1.02498 29.9735 1.80163 28.29 2.31001C27.3869 1.27162 26.1867 0.535541 24.8518 0.201263C23.5168 -0.133016 22.1114 -0.0493761 20.8255 0.440877C19.5396 0.93113 18.4352 1.80436 17.6617 2.94253C16.8881 4.0807 16.4826 5.42894 16.5 6.80501V8.30501C13.864 8.37336 11.2519 7.78873 8.89651 6.60318C6.54112 5.41763 4.51548 3.66796 3 1.51001C3 1.51001 -3 15.01 10.5 21.01C7.41079 23.107 3.73074 24.1584 0 24.01C13.5 31.51 30 24.01 30 6.76001C29.9989 6.34052 29.9588 5.92204 29.88 5.51001C31.4101 4.00162 32.4904 2.09729 33 0.0100074Z" fill="#4AA1EC"/>
                            </svg>
                        </a>

                        <a href="#" target="_blank" class="social-link">
                            <svg viewBox="0 0 20 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.018 20.25L19.018 13.735H12.766V9.507C12.766 7.725 13.639 5.987 16.439 5.987H19.281V0.44C17.6126 0.170553 15.9268 0.023494 14.237 0C9.091 0 5.723 3.12 5.723 8.769V13.734H0V20.25H5.723V36H12.766V20.25H18.018Z" fill="#415A94"/>
                            </svg>
                        </a>

                        <a href="#" target="_blank" class="social-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" fill="#415A94"/>
                            </svg>
                        </a>
                    </div>
                    <div class="social-links text-center">
                        FBN MTO@{{currentYear}}
                    </div>
                </section>
            </div>
            <div class="col-12 col-md-6 d-none d-md-block">
                <section class="right-side">
                    <div class="slider-container">
                        <div class="slider-control left inactive"></div>
                        <div class="slider-control right"></div>
                        <ul class="slider-pagi"></ul>
                        <div class="slider">
                            <div class="slide slide-0 active">
                                <div class="slide__bg"></div>

                                <div class="slide__content">

                                    <svg class="slide__overlay" viewBox="0 0 1012 1083" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g id="slide-one">
                                            <g id="Frame" clip-path="url(#clip0)">
                                                <path id="Vector" d="M1012 0H0V1083H1012V0Z" fill="#E5E5E5"/>
                                                <g id="first-slide">
                                                    <g id="first-bg">
                                                        <path class="slide__overlay" id="Vector_2" d="M1012 0H0V1083H1012V0Z" fill="#002F5E"/>
                                                    </g>
                                                    <g id="top-graphics">
                                                        <g id="first-element7">
                                                            <g id="Group" filter="url(#filter0_d)">
                                                                <path id="Vector_3" d="M986.053 168.72C1033.94 168.72 1079.88 187.745 1113.74 221.61C1147.6 255.475 1166.63 301.405 1166.63 349.297C1166.63 397.189 1147.61 443.12 1113.74 476.985C1079.88 510.849 1033.95 529.875 986.053 529.875C938.161 529.875 892.231 510.849 858.366 476.985C824.501 443.12 805.476 397.189 805.476 349.297C805.476 301.405 824.501 255.475 858.366 221.61C892.231 187.745 938.161 168.72 986.053 168.72Z" fill="#FBB414"/>
                                                            </g>
                                                            <path id="Vector_2_2" d="M987.916 249.666C1014.5 249.666 1040 260.229 1058.8 279.03C1077.6 297.831 1088.17 323.33 1088.17 349.918C1088.17 376.505 1077.6 402.004 1058.8 420.804C1040 439.604 1014.5 450.165 987.916 450.166C961.329 450.165 935.831 439.603 917.031 420.803C898.231 402.003 887.668 376.505 887.667 349.918C887.667 323.33 898.229 297.831 917.029 279.03C935.83 260.23 961.328 249.667 987.916 249.666Z" fill="#002F5E"/>
                                                        </g>
                                                        <g id="first-element10">
                                                            <g id="Group_2">
                                                                <path id="Vector_3_2" d="M82.2349 463.326C82.2349 466.691 80.8981 469.919 78.5184 472.298C76.1388 474.678 72.9113 476.015 69.5459 476.015C66.1806 476.015 62.9531 474.678 60.5734 472.298C58.1938 469.919 56.8569 466.691 56.8569 463.326C56.8569 459.961 58.1938 456.733 60.5734 454.353C62.9531 451.974 66.1806 450.637 69.5459 450.637C72.9113 450.637 76.1388 451.974 78.5184 454.353C80.8981 456.733 82.2349 459.961 82.2349 463.326Z" fill="white"/>
                                                                <path id="Vector_4" d="M42.2709 463.326C42.2709 466.691 40.9341 469.919 38.5544 472.298C36.1748 474.678 32.9473 476.015 29.5819 476.015C26.2166 476.015 22.9891 474.678 20.6095 472.298C18.2298 469.919 16.8929 466.691 16.8929 463.326C16.8929 459.961 18.2298 456.733 20.6095 454.353C22.9891 451.974 26.2166 450.637 29.5819 450.637C32.9473 450.637 36.1748 451.974 38.5544 454.353C40.9341 456.733 42.2709 459.961 42.2709 463.326Z" fill="#FBB414"/>
                                                                <path id="Vector_5" d="M82.2349 423.362C82.2349 426.727 80.8981 429.955 78.5184 432.334C76.1388 434.714 72.9113 436.051 69.5459 436.051C66.1806 436.051 62.9531 434.714 60.5734 432.334C58.1938 429.955 56.8569 426.727 56.8569 423.362C56.8569 419.997 58.1938 416.769 60.5734 414.389C62.9531 412.01 66.1806 410.673 69.5459 410.673C72.9113 410.673 76.1388 412.01 78.5184 414.389C80.8981 416.769 82.2349 419.997 82.2349 423.362Z" fill="#FBB414"/>
                                                            </g>
                                                            <g id="Group_3">
                                                                <path id="Vector_6" d="M58.4569 381.029C58.4569 384.394 59.7938 387.622 62.1734 390.001C64.5531 392.381 67.7806 393.718 71.1459 393.718C74.5112 393.718 77.7387 392.381 80.1184 390.001C82.498 387.622 83.8349 384.394 83.8349 381.029C83.8349 377.664 82.498 374.436 80.1184 372.056C77.7387 369.677 74.5112 368.34 71.1459 368.34C67.7806 368.34 64.5531 369.677 62.1734 372.056C59.7938 374.436 58.4569 377.664 58.4569 381.029Z" fill="white"/>
                                                                <path id="Vector_7" d="M98.4209 381.029C98.4209 384.394 99.7578 387.622 102.138 390.001C104.517 392.381 107.745 393.718 111.11 393.718C114.475 393.718 117.703 392.381 120.082 390.001C122.462 387.622 123.799 384.394 123.799 381.029C123.799 377.664 122.462 374.436 120.082 372.056C117.703 369.677 114.475 368.34 111.11 368.34C107.745 368.34 104.517 369.677 102.138 372.056C99.7578 374.436 98.4209 377.664 98.4209 381.029Z" fill="#FBB414"/>
                                                                <path id="Vector_8" d="M58.4569 341.065C58.4569 344.43 59.7938 347.658 62.1734 350.037C64.5531 352.417 67.7806 353.754 71.1459 353.754C74.5112 353.754 77.7387 352.417 80.1184 350.037C82.498 347.658 83.8349 344.43 83.8349 341.065C83.8349 337.7 82.498 334.472 80.1184 332.092C77.7387 329.713 74.5112 328.376 71.1459 328.376C67.7806 328.376 64.5531 329.713 62.1734 332.092C59.7938 334.472 58.4569 337.7 58.4569 341.065Z" fill="#FBB414"/>
                                                            </g>
                                                        </g>
                                                        <g id="first-element6">
                                                            <g id="Group_4">
                                                                <g id="Group_5">
                                                                    <path id="Vector_9" d="M944.235 104.326C944.235 107.691 942.898 110.919 940.519 113.298C938.139 115.678 934.911 117.015 931.546 117.015C928.181 117.015 924.953 115.678 922.574 113.298C920.194 110.919 918.857 107.691 918.857 104.326C918.857 100.961 920.194 97.7332 922.574 95.3535C924.953 92.9739 928.181 91.637 931.546 91.637C934.911 91.637 938.139 92.9739 940.519 95.3535C942.898 97.7332 944.235 100.961 944.235 104.326Z" fill="white"/>
                                                                    <path id="Vector_10" d="M904.271 104.326C904.271 107.691 902.934 110.919 900.555 113.298C898.175 115.678 894.947 117.015 891.582 117.015C888.217 117.015 884.989 115.678 882.61 113.298C880.23 110.919 878.893 107.691 878.893 104.326C878.893 100.961 880.23 97.7332 882.61 95.3535C884.989 92.9739 888.217 91.637 891.582 91.637C894.947 91.637 898.175 92.9739 900.555 95.3535C902.934 97.7332 904.271 100.961 904.271 104.326Z" fill="#FBB414"/>
                                                                    <path id="Vector_11" d="M944.235 64.362C944.235 67.7273 942.898 70.9548 940.519 73.3345C938.139 75.7141 934.911 77.051 931.546 77.051C928.181 77.051 924.953 75.7141 922.574 73.3345C920.194 70.9548 918.857 67.7273 918.857 64.362C918.857 60.9967 920.194 57.7692 922.574 55.3895C924.953 53.0099 928.181 51.673 931.546 51.673C934.911 51.673 938.139 53.0099 940.519 55.3895C942.898 57.7692 944.235 60.9967 944.235 64.362Z" fill="#FBB414"/>
                                                                </g>
                                                                <g id="Group_6">
                                                                    <path id="Vector_12" d="M920.457 22.029C920.457 25.3943 921.794 28.6218 924.174 31.0015C926.553 33.3811 929.781 34.718 933.146 34.718C936.511 34.718 939.739 33.3811 942.119 31.0015C944.498 28.6218 945.835 25.3943 945.835 22.029C945.835 18.6637 944.498 15.4362 942.119 13.0565C939.739 10.6769 936.511 9.34 933.146 9.34C929.781 9.34 926.553 10.6769 924.174 13.0565C921.794 15.4362 920.457 18.6637 920.457 22.029Z" fill="white"/>
                                                                    <path id="Vector_13" d="M960.421 22.029C960.421 25.3943 961.758 28.6218 964.138 31.0015C966.517 33.3811 969.745 34.718 973.11 34.718C976.475 34.718 979.703 33.3811 982.082 31.0015C984.462 28.6218 985.799 25.3943 985.799 22.029C985.799 18.6637 984.462 15.4362 982.082 13.0565C979.703 10.6769 976.475 9.34 973.11 9.34C969.745 9.34 966.517 10.6769 964.138 13.0565C961.758 15.4362 960.421 18.6637 960.421 22.029Z" fill="#FBB414"/>
                                                                </g>
                                                            </g>
                                                        </g>
                                                        <g id="first-element1">
                                                            <path id="Vector_14" d="M0 243.693V3.29401L120.2 123.494L0 243.693Z" fill="#FBB414"/>
                                                        </g>
                                                        <g id="first-element2">
                                                            <path id="Vector_15" d="M137 243.693V3.29401L257.2 123.494L137 243.693Z" fill="white"/>
                                                        </g>
                                                        <g id="first-element8">
                                                            <path id="Vector_16" d="M311.006 451.488L480.994 281.5V451.488H311.006Z" fill="#FBB414"/>
                                                            <path id="Vector_17" d="M448.006 451.488L617.994 281.5V451.488H448.006Z" fill="#FBB414"/>
                                                        </g>
                                                        <g id="first-element3">
                                                            <path id="Vector_18" d="M336.543 0.690002H569.04L452.792 116.938L336.543 0.690002Z" fill="#FBB414"/>
                                                        </g>
                                                        <g id="first-element4">
                                                            <path id="Vector_19" d="M569.042 233.476H336.545L452.793 117.228L569.042 233.476Z" fill="white"/>
                                                        </g>
                                                        <g id="first-element5">
                                                            <g id="Group_7">
                                                                <path id="Vector_20" d="M625.025 110.031H682.11V52.946H625.025V110.031Z" fill="#FBB414"/>
                                                                <path id="Vector_21" d="M625.025 181.847H682.11V124.762H625.025V181.847Z" fill="#FBB414"/>
                                                                <path id="Vector_22" d="M698.683 110.031H755.768V52.946H698.683V110.031Z" fill="#FBB414"/>
                                                                <path id="Vector_23" d="M698.683 181.847H755.768V124.762H698.683V181.847Z" fill="#FBB414"/>
                                                                <path id="Vector_24" d="M639.757 94.378H666.458V67.677H639.757V94.378Z" fill="white"/>
                                                                <path id="Vector_25" d="M639.757 166.195H666.458V139.494H639.757V166.195Z" fill="white"/>
                                                                <path id="Vector_26" d="M713.415 94.378H740.116V67.677H713.415V94.378Z" fill="white"/>
                                                                <path id="Vector_27" d="M713.415 166.195H740.116V139.494H713.415V166.195Z" fill="white"/>
                                                            </g>
                                                        </g>
                                                        <g id="first-element9">
                                                            <g id="Group_8">
                                                                <path id="Vector_28" d="M262.517 304.866L245.668 288.017L228.819 304.866L245.668 321.715L262.517 304.866Z" fill="#FBB414"/>
                                                                <path id="Vector_29" d="M283.714 283.669L266.865 266.82L250.016 283.669L266.865 300.518L283.714 283.669Z" fill="#FBB414"/>
                                                                <path id="Vector_30" d="M240.777 283.126L223.928 266.277L207.079 283.126L223.928 299.975L240.777 283.126Z" fill="#FBB414"/>
                                                                <path id="Vector_31" d="M261.974 261.929L245.125 245.08L228.276 261.929L245.125 278.778L261.974 261.929Z" fill="#FBB414"/>
                                                                <path id="Vector_32" d="M253.549 305.137L245.669 297.256L237.788 305.137L245.669 313.018L253.549 305.137Z" fill="white"/>
                                                                <path id="Vector_33" d="M274.746 283.94L266.865 276.059L258.985 283.94L266.865 291.821L274.746 283.94Z" fill="white"/>
                                                                <path id="Vector_34" d="M231.809 283.396L223.928 275.516L216.047 283.396L223.928 291.277L231.809 283.396Z" fill="white"/>
                                                                <path id="Vector_35" d="M253.005 262.199L245.125 254.319L237.244 262.199L245.125 270.08L253.005 262.199Z" fill="white"/>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="Rectangle 4 1">
                                                    <path id="Vector_36" d="M1012 604H0V1083H1012V604Z" fill="#012C58"/>
                                                </g>
                                            </g>
                                        </g>
                                        <defs>
                                            <filter id="filter0_d" x="799.476" y="165.72" width="373.154" height="373.155" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                                <feOffset dy="3"/>
                                                <feGaussianBlur stdDeviation="3"/>
                                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"/>
                                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                                            </filter>
                                            <clipPath id="clip0">
                                                <rect width="1012" height="1083" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>

                                    <div class="slide__text">
                                        <p class="slide__text-img text-center">
                                          <span>
                                            <img src="https://assets-base.s3.amazonaws.com/img/firstbank/cib/ft.png" alt="Funds Transfer">
                                          </span>
                                        </p>
                                        <h2 class="slide__text-heading text-white" style="color: white;">{{$t('slider.sliderOneHeader')}}</h2>
                                        <p class="slide__text-desc">
                                            {{$t('slider.sliderOneText')}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="slide slide-1 ">
                                <div class="slide__bg"></div>
                                <div class="slide__content">

                                    <svg class="slide__overlay" viewBox="0 0 1012 1080" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g id="second-slide" clip-path="url(#clip0)">
                                            <g id="s-bg">
                                                <g id="second-bg">
                                                    <path class="slide__overlay-path"  id="Vector" d="M0 0H1012V1080H0V0Z" fill="#FBB414"/>
                                                </g>
                                                <g id="second-element10">
                                                    <g id="Group" filter="url(#filter0_d)">
                                                        <path id="Vector_2" d="M66.0531 165.72C113.945 165.72 159.875 184.745 193.74 218.61C227.605 252.475 246.63 298.405 246.63 346.297V346.297C246.63 394.189 227.605 440.12 193.74 473.985C159.876 507.849 113.945 526.875 66.0531 526.875C18.161 526.875 -27.7695 507.849 -61.6342 473.985C-95.4989 440.12 -114.524 394.189 -114.524 346.297V346.297C-114.524 298.405 -95.4986 252.475 -61.6339 218.61C-27.7691 184.745 18.1612 165.72 66.0531 165.72V165.72Z" fill="#002F5E"/>
                                                    </g>
                                                    <path id="Vector_3" d="M67.916 246.666C94.5042 246.667 120.003 257.229 138.804 276.03C157.605 294.831 168.167 320.33 168.167 346.918V346.918C168.166 373.506 157.603 399.004 138.803 417.804C120.002 436.604 94.5035 447.165 67.916 447.166V447.166C41.3288 447.165 15.8309 436.603 -2.96922 417.803C-21.7693 399.003 -32.3317 373.505 -32.333 346.918V346.918C-32.3327 320.33 -21.7708 294.831 -2.97064 276.031C15.8295 257.23 41.3281 246.667 67.916 246.666V246.666Z" fill="#FBB414"/>
                                                </g>
                                                <g id="second-element7">
                                                    <g id="Group_2">
                                                        <path id="Vector_4" d="M1002.24 460.326C1002.24 463.691 1000.9 466.919 998.519 469.298C996.139 471.678 992.911 473.015 989.546 473.015V473.015C986.181 473.015 982.953 471.678 980.574 469.298C978.194 466.919 976.857 463.691 976.857 460.326V460.326C976.857 456.961 978.194 453.733 980.574 451.353C982.953 448.974 986.181 447.637 989.546 447.637V447.637C992.911 447.637 996.139 448.974 998.519 451.353C1000.9 453.733 1002.24 456.961 1002.24 460.326V460.326Z" fill="white"/>
                                                        <path id="Vector_5" d="M962.271 460.326C962.271 463.691 960.934 466.919 958.555 469.298C956.175 471.678 952.947 473.015 949.582 473.015V473.015C946.217 473.015 942.989 471.678 940.61 469.298C938.23 466.919 936.893 463.691 936.893 460.326V460.326C936.893 456.961 938.23 453.733 940.61 451.353C942.989 448.974 946.217 447.637 949.582 447.637V447.637C952.947 447.637 956.175 448.974 958.555 451.353C960.934 453.733 962.271 456.961 962.271 460.326V460.326Z" fill="#012C58"/>
                                                        <path id="Vector_6" d="M1002.24 420.362C1002.24 423.727 1000.9 426.955 998.519 429.334C996.139 431.714 992.911 433.051 989.546 433.051V433.051C986.181 433.051 982.953 431.714 980.574 429.334C978.194 426.955 976.857 423.727 976.857 420.362V420.362C976.857 416.997 978.194 413.769 980.574 411.389C982.953 409.01 986.181 407.673 989.546 407.673V407.673C992.911 407.673 996.139 409.01 998.519 411.389C1000.9 413.769 1002.24 416.997 1002.24 420.362V420.362Z" fill="#012C58"/>
                                                    </g>
                                                    <g id="Group_3">
                                                        <path id="Vector_7" d="M978.457 378.029C978.457 381.394 979.794 384.622 982.174 387.002C984.553 389.381 987.781 390.718 991.146 390.718V390.718C994.511 390.718 997.739 389.381 1000.12 387.002C1002.5 384.622 1003.84 381.394 1003.84 378.029V378.029C1003.84 374.664 1002.5 371.436 1000.12 369.057C997.739 366.677 994.511 365.34 991.146 365.34C987.781 365.34 984.553 366.677 982.174 369.057C979.794 371.436 978.457 374.664 978.457 378.029V378.029Z" fill="white"/>
                                                        <path id="Vector_8" d="M978.457 338.065C978.457 341.43 979.794 344.658 982.174 347.037C984.553 349.417 987.781 350.754 991.146 350.754V350.754C994.511 350.754 997.739 349.417 1000.12 347.037C1002.5 344.658 1003.84 341.43 1003.84 338.065V338.065C1003.84 334.7 1002.5 331.472 1000.12 329.092C997.739 326.713 994.511 325.376 991.146 325.376C987.781 325.376 984.553 326.713 982.174 329.092C979.794 331.472 978.457 334.7 978.457 338.065V338.065Z" fill="#012C58"/>
                                                    </g>
                                                </g>
                                                <g id="second-element6">
                                                    <path id="Vector_9" d="M904 240.694V0.294226L783.801 120.494L904 240.694Z" fill="#012C58"/>
                                                </g>
                                                <g id="second-element5">
                                                    <path id="Vector_10" d="M767 240.694V0.294226L646.801 120.494L767 240.694Z" fill="white"/>
                                                </g>
                                                <g id="second-element9">
                                                    <path id="Vector_11" d="M311.006 448.488L480.994 278.5V448.488H311.006Z" fill="#002F5E"/>
                                                    <path id="Vector_12" d="M448.006 448.488L617.994 278.5V448.488H448.006Z" fill="#002F5E"/>
                                                </g>
                                                <g id="second-element3">
                                                    <path id="Vector_13" d="M336.543 -2.31006H569.04L452.792 113.938L336.543 -2.31006Z" fill="#002F5E"/>
                                                </g>
                                                <g id="second-element4">
                                                    <path id="Vector_14" d="M569.042 230.476H336.545L452.793 114.228L569.042 230.476Z" fill="white"/>
                                                </g>
                                                <g id="second-element1">
                                                    <g id="Group_4">
                                                        <path id="Vector_15" d="M17.589 101.326C17.589 104.691 18.9259 107.919 21.3055 110.298C23.6852 112.678 26.9127 114.015 30.278 114.015V114.015C33.6434 114.015 36.8708 112.678 39.2505 110.298C41.6301 107.919 42.967 104.691 42.967 101.326V101.326C42.967 97.9606 41.6301 94.7331 39.2505 92.3535C36.8708 89.9738 33.6434 88.637 30.278 88.637C26.9127 88.637 23.6852 89.9738 21.3055 92.3535C18.9259 94.7331 17.589 97.9606 17.589 101.326H17.589Z" fill="white"/>
                                                        <path id="Vector_16" d="M57.553 101.326C57.553 104.691 58.8899 107.919 61.2695 110.298C63.6492 112.678 66.8767 114.015 70.242 114.015V114.015C73.6074 114.015 76.8349 112.678 79.2145 110.298C81.5942 107.919 82.931 104.691 82.931 101.326V101.326C82.931 97.9606 81.5942 94.7331 79.2145 92.3535C76.8349 89.9738 73.6074 88.637 70.242 88.637C66.8767 88.637 63.6492 89.9738 61.2695 92.3535C58.8899 94.7331 57.553 97.9606 57.553 101.326V101.326Z" fill="#002F5E"/>
                                                        <path id="Vector_17" d="M17.589 61.3619C17.589 64.7272 18.9259 67.9547 21.3055 70.3343C23.6852 72.714 26.9127 74.0509 30.278 74.0509V74.0509C33.6434 74.0509 36.8708 72.714 39.2505 70.3343C41.6301 67.9547 42.967 64.7272 42.967 61.3619V61.3619C42.967 57.9965 41.6301 54.769 39.2505 52.3894C36.8708 50.0097 33.6434 48.6729 30.278 48.6729C26.9127 48.6729 23.6852 50.0097 21.3055 52.3894C18.9259 54.769 17.589 57.9965 17.589 61.3619H17.589Z" fill="#002F5E"/>
                                                    </g>
                                                    <g id="Group_5">
                                                        <path id="Vector_18" d="M41.367 19.0291C41.367 22.3944 40.0301 25.6219 37.6505 28.0016C35.2708 30.3812 32.0433 31.7181 28.678 31.7181V31.7181C25.3127 31.7181 22.0852 30.3812 19.7055 28.0016C17.3259 25.6219 15.989 22.3944 15.989 19.0291V19.0291C15.989 15.6638 17.3259 12.4363 19.7055 10.0566C22.0852 7.67696 25.3127 6.34009 28.678 6.34009V6.34009C32.0433 6.34009 35.2708 7.67696 37.6505 10.0566C40.0301 12.4363 41.367 15.6638 41.367 19.0291V19.0291Z" fill="white"/>
                                                        <path id="Vector_19" d="M1.40302 19.0291C1.40302 22.3944 0.0661516 25.6219 -2.3135 28.0016C-4.69315 30.3812 -7.92064 31.7181 -11.286 31.7181V31.7181C-14.6513 31.7181 -17.8788 30.3812 -20.2585 28.0016C-22.6381 25.6219 -23.975 22.3944 -23.975 19.0291V19.0291C-23.975 15.6638 -22.6381 12.4363 -20.2585 10.0566C-17.8788 7.67696 -14.6513 6.34009 -11.286 6.34009V6.34009C-7.92064 6.34009 -4.69315 7.67696 -2.3135 10.0566C0.0661516 12.4363 1.40302 15.6638 1.40302 19.0291V19.0291Z" fill="#FBB414"/>
                                                    </g>
                                                </g>
                                                <g id="second-element2">
                                                    <g id="Group_6">
                                                        <path id="Vector_20" d="M336.799 49.947H279.714V107.032H336.799V49.947Z" fill="#002F5E"/>
                                                        <path id="Vector_21" d="M336.799 121.763H279.714V178.848H336.799V121.763Z" fill="#002F5E"/>
                                                        <path id="Vector_22" d="M263.141 49.947H206.056V107.032H263.141V49.947Z" fill="#002F5E"/>
                                                        <path id="Vector_23" d="M263.141 121.763H206.056V178.848H263.141V121.763Z" fill="#002F5E"/>
                                                        <path id="Vector_24" d="M322.067 64.679H295.366V91.38H322.067V64.679Z" fill="white"/>
                                                        <path id="Vector_25" d="M322.067 136.495H295.366V163.196H322.067V136.495Z" fill="white"/>
                                                        <path id="Vector_26" d="M248.409 64.679H221.708V91.38H248.409V64.679Z" fill="white"/>
                                                        <path id="Vector_27" d="M248.409 136.495H221.708V163.196H248.409V136.495Z" fill="white"/>
                                                    </g>
                                                </g>
                                                <g id="Group_7">
                                                    <g id="Group_8">
                                                        <path id="Vector_28" d="M837.517 301.866L820.668 285.017L803.819 301.866L820.668 318.714L837.517 301.866Z" fill="#012C58"/>
                                                        <path id="Vector_29" d="M858.714 280.669L841.865 263.82L825.016 280.669L841.865 297.517L858.714 280.669Z" fill="#012C58"/>
                                                        <path id="Vector_30" d="M815.777 280.126L798.928 263.277L782.079 280.126L798.928 296.974L815.777 280.126Z" fill="#012C58"/>
                                                        <path id="Vector_31" d="M836.974 258.929L820.125 242.08L803.276 258.929L820.125 275.777L836.974 258.929Z" fill="#012C58"/>
                                                        <path id="Vector_32" d="M828.549 302.137L820.669 294.256L812.788 302.137L820.669 310.018L828.549 302.137Z" fill="white"/>
                                                        <path id="Vector_33" d="M849.746 280.94L841.865 273.059L833.985 280.94L841.865 288.821L849.746 280.94Z" fill="white"/>
                                                        <path id="Vector_34" d="M806.809 280.396L798.928 272.516L791.047 280.396L798.928 288.277L806.809 280.396Z" fill="white"/>
                                                        <path id="Vector_35" d="M828.005 259.199L820.125 251.319L812.244 259.199L820.125 267.08L828.005 259.199Z" fill="white"/>
                                                    </g>
                                                </g>
                                                <g id="Group 1">
                                                    <path id="Vector_36" d="M1012 601H0V1080H1012V601Z" fill="#F2AE14"/>
                                                </g>
                                            </g>
                                        </g>
                                        <defs>
                                            <filter id="filter0_d" x="-120.524" y="162.72" width="373.154" height="373.155" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                                <feOffset dy="3"/>
                                                <feGaussianBlur stdDeviation="3"/>
                                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"/>
                                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                                            </filter>
                                            <clipPath id="clip0">
                                                <rect width="1012" height="1080" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>


                                    <div class="slide__text">
                                        <p class="slide__text-img text-center">
                                          <span>
                                            <img src="https://assets-base.s3.amazonaws.com/img/firstbank/cib/acct.png" alt="Funds Transfer">
                                          </span>
                                        </p>
                                        <h2 class="slide__text-heading">{{$t('slider.sliderTwoHeader')}}</h2>
                                        <p class="slide__text-desc">
                                            {{$t('slider.sliderTwoText')}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="slide slide-2">
                                <div class="slide__bg"></div>
                                <div class="slide__content">

                                    <svg class="slide__overlay" viewBox="0 0 1012 1080" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g id="third-slide" clip-path="url(#clip0)">
                                            <g id="third-slide_2">
                                                <g id="t-bg">
                                                    <path id="third-bg" d="M0 0H1012V1080H0V0Z" fill="#BEBEBE"/>
                                                    <g id="third-element2">
                                                        <g id="Group" filter="url(#filter0_d)">
                                                            <path class="slide__overlay-path" id="Vector" d="M494.053 -180.28C541.945 -180.28 587.875 -161.255 621.74 -127.39C655.605 -93.5253 674.63 -47.595 674.63 0.296967V0.296967C674.63 48.1891 655.605 94.1196 621.74 127.985C587.876 161.849 541.945 180.875 494.053 180.875C446.161 180.875 400.231 161.849 366.366 127.985C332.501 94.1196 313.476 48.1891 313.476 0.296967V0.296967C313.476 -47.595 332.502 -93.5253 366.366 -127.39C400.231 -161.255 446.161 -180.28 494.053 -180.28V-180.28Z" fill="#002F5E"/>
                                                        </g>
                                                        <path id="Vector_2" d="M495.916 -99.334C522.504 -99.3335 548.003 -88.771 566.804 -69.9702C585.605 -51.1694 596.167 -25.6702 596.167 0.918015V0.918015C596.166 27.5056 585.603 53.0037 566.803 71.8036C548.002 90.6036 522.504 101.165 495.916 101.166V101.166C469.329 101.165 443.831 90.6028 425.031 71.8029C406.231 53.0031 395.668 27.5052 395.667 0.918015V0.918015C395.667 -25.6699 406.229 -51.1687 425.029 -69.9695C443.83 -88.7703 469.328 -99.3329 495.916 -99.334V-99.334Z" fill="#FBB414"/>
                                                    </g>
                                                    <g id="third-element1">
                                                        <g id="Group_2">
                                                            <path id="Vector_3" d="M117.235 135.326C117.235 138.691 115.898 141.919 113.519 144.298C111.139 146.678 107.911 148.015 104.546 148.015V148.015C101.181 148.015 97.9532 146.678 95.5736 144.298C93.1939 141.919 91.8571 138.691 91.8571 135.326V135.326C91.8571 131.961 93.1939 128.733 95.5736 126.353C97.9532 123.974 101.181 122.637 104.546 122.637V122.637C107.911 122.637 111.139 123.974 113.519 126.353C115.898 128.733 117.235 131.961 117.235 135.326V135.326Z" fill="#FBB414"/>
                                                            <path id="Vector_4" d="M77.2711 135.326C77.2711 138.691 75.9342 141.919 73.5545 144.298C71.1749 146.678 67.9474 148.015 64.5821 148.015V148.015C61.2167 148.015 57.9892 146.678 55.6096 144.298C53.2299 141.919 51.8931 138.691 51.8931 135.326V135.326C51.8931 131.961 53.2299 128.733 55.6096 126.353C57.9892 123.974 61.2167 122.637 64.5821 122.637V122.637C67.9474 122.637 71.1749 123.974 73.5545 126.353C75.9342 128.733 77.2711 131.961 77.2711 135.326V135.326Z" fill="#012C58"/>
                                                            <path id="Vector_5" d="M117.235 95.3619C117.235 98.7272 115.898 101.955 113.519 104.334C111.139 106.714 107.911 108.051 104.546 108.051V108.051C101.181 108.051 97.9532 106.714 95.5736 104.334C93.1939 101.955 91.8571 98.7272 91.8571 95.3619V95.3619C91.8571 91.9965 93.1939 88.769 95.5736 86.3894C97.9532 84.0097 101.181 82.6729 104.546 82.6729V82.6729C107.911 82.6729 111.139 84.0097 113.519 86.3894C115.898 88.769 117.235 91.9965 117.235 95.3619V95.3619Z" fill="#012C58"/>
                                                        </g>
                                                        <g id="Group_3">
                                                            <path id="Vector_6" d="M93.457 53.0291C93.457 56.3944 94.7939 59.6219 97.1736 62.0016C99.5532 64.3812 102.781 65.7181 106.146 65.7181V65.7181C109.511 65.7181 112.739 64.3812 115.119 62.0016C117.498 59.6219 118.835 56.3944 118.835 53.0291V53.0291C118.835 49.6638 117.498 46.4363 115.119 44.0566C112.739 41.677 109.511 40.3401 106.146 40.3401C102.781 40.3401 99.5532 41.677 97.1736 44.0566C94.7939 46.4363 93.457 49.6638 93.457 53.0291V53.0291Z" fill="#FBB414"/>
                                                            <path id="Vector_7" d="M133.421 53.0291C133.421 56.3944 134.758 59.6219 137.138 62.0016C139.517 64.3812 142.745 65.7181 146.11 65.7181V65.7181C149.475 65.7181 152.703 64.3812 155.082 62.0016C157.462 59.6219 158.799 56.3944 158.799 53.0291V53.0291C158.799 49.6638 157.462 46.4363 155.082 44.0566C152.703 41.677 149.475 40.3401 146.11 40.3401C142.745 40.3401 139.517 41.677 137.138 44.0566C134.758 46.4363 133.421 49.6638 133.421 53.0291V53.0291Z" fill="#FBB414"/>
                                                            <path id="Vector_8" d="M93.457 13.065C93.457 16.4303 94.7939 19.6578 97.1736 22.0375C99.5532 24.4171 102.781 25.754 106.146 25.754V25.754C109.511 25.754 112.739 24.4171 115.119 22.0375C117.498 19.6578 118.835 16.4303 118.835 13.065V13.065C118.835 9.69964 117.498 6.47215 115.119 4.0925C112.739 1.71285 109.511 0.375977 106.146 0.375977C102.781 0.375977 99.5532 1.71285 97.1736 4.0925C94.7939 6.47215 93.457 9.69964 93.457 13.065V13.065Z" fill="#012C58"/>
                                                        </g>
                                                    </g>
                                                    <g id="third-element4">
                                                        <path id="Vector_9" d="M1070.49 210L900.506 379.988H1070.49V210Z" fill="#002F5E"/>
                                                    </g>
                                                    <g id="third-element5">
                                                        <path id="Vector_10" d="M1070.49 347L900.506 516.988H1070.49V347Z" fill="#002F5E"/>
                                                    </g>
                                                    <g id="third-element8">
                                                        <g id="Group_4">
                                                            <path id="Vector_11" d="M140.517 305.866L123.668 289.017L106.819 305.866L123.668 322.714L140.517 305.866Z" fill="#012C58"/>
                                                            <path id="Vector_12" d="M161.714 284.669L144.865 267.82L128.016 284.669L144.865 301.517L161.714 284.669Z" fill="#012C58"/>
                                                            <path id="Vector_13" d="M118.777 284.126L101.928 267.277L85.0789 284.126L101.928 300.974L118.777 284.126Z" fill="#012C58"/>
                                                            <path id="Vector_14" d="M139.974 262.929L123.125 246.08L106.276 262.929L123.125 279.777L139.974 262.929Z" fill="#012C58"/>
                                                            <path id="Vector_15" d="M131.549 306.137L123.669 298.256L115.788 306.137L123.669 314.018L131.549 306.137Z" fill="white"/>
                                                            <path id="Vector_16" d="M152.746 284.94L144.865 277.059L136.985 284.94L144.865 292.821L152.746 284.94Z" fill="white"/>
                                                            <path id="Vector_17" d="M109.809 284.396L101.928 276.516L94.0472 284.396L101.928 292.277L109.809 284.396Z" fill="white"/>
                                                            <path id="Vector_18" d="M131.005 263.199L123.125 255.319L115.244 263.199L123.125 271.08L131.005 263.199Z" fill="white"/>
                                                        </g>
                                                    </g>
                                                    <g id="Group 2">
                                                        <path id="Vector_19" d="M1012 601H0V1080H1012V601Z" fill="#B7B7B7"/>
                                                    </g>
                                                    <g id="third-element7">
                                                        <path id="Vector_20" d="M405 453.693V213.294L525.2 333.494L405 453.693Z" fill="#012C58"/>
                                                    </g>
                                                    <g id="third-element6">
                                                        <path id="Vector_21" d="M542 453.693V213.294L662.2 333.494L542 453.693Z" fill="#FBB414"/>
                                                    </g>
                                                    <g id="third-element9">
                                                        <path id="Vector_22" d="M146.05 479.5V601.33L85.1349 540.415L146.05 479.5Z" fill="#002F5E"/>
                                                    </g>
                                                    <g id="third-element10">
                                                        <path id="Vector_23" d="M24.067 601.33V479.5L84.9821 540.415L24.067 601.33Z" fill="#FBB414"/>
                                                    </g>
                                                    <g id="third-element3">
                                                        <g id="Group_5">
                                                            <path id="Vector_24" d="M794.025 136.031H851.11V78.946H794.025V136.031Z" fill="#002F5E"/>
                                                            <path id="Vector_25" d="M794.025 207.847H851.11V150.762H794.025V207.847Z" fill="#002F5E"/>
                                                            <path id="Vector_26" d="M867.683 136.031H924.768V78.946H867.683V136.031Z" fill="#002F5E"/>
                                                            <path id="Vector_27" d="M867.683 207.847H924.768V150.762H867.683V207.847Z" fill="#002F5E"/>
                                                            <path id="Vector_28" d="M808.757 120.378H835.458V93.6769H808.757V120.378Z" fill="white"/>
                                                            <path id="Vector_29" d="M808.757 192.195H835.458V165.494H808.757V192.195Z" fill="white"/>
                                                            <path id="Vector_30" d="M882.415 120.378H909.116V93.6769H882.415V120.378Z" fill="white"/>
                                                            <path id="Vector_31" d="M882.415 192.195H909.116V165.494H882.415V192.195Z" fill="white"/>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                        <defs>
                                            <filter id="filter0_d" x="307.476" y="-183.28" width="373.154" height="373.155" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                                <feOffset dy="3"/>
                                                <feGaussianBlur stdDeviation="3"/>
                                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"/>
                                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                                            </filter>
                                            <clipPath id="clip0">
                                                <rect width="1012" height="1080" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>



                                    <div class="slide__text">
                                        <p class="slide__text-img text-center">
                    <span>
                      <img src="https://assets-base.s3.amazonaws.com/img/firstbank/cib/collection.png" alt="Funds Transfer">
                    </span>
                                        </p>
                                        <h2 class="slide__text-heading">{{$t('slider.sliderThreeHeader')}}</h2>
                                        <p class="slide__text-desc">
                                            {{$t('slider.sliderThreeText')}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
    import LogonForm from "../../components/form/LogonForm";
    import "../../assets/js/lumbex"
    export default {
        name: "Logon",
        components:{
            LogonForm
            //LogonForm
        },
        data(){
            return{
            }
        },
        computed:{
            currentYear: function () {
                return new Date().getFullYear()
            }
        },
        mounted() {
            //console.log('authLoading>>> ', this.system.appLoading)
        }
    }
</script>

<style scoped>
    @import "../../assets/style/css/lucas.css";
    @import "../../assets/style/css/luminous.css";
    /*@import "../../assets/style/css/output.css";*/
    /*@import "../../assets/style/css/svg.css";*/
</style>
