import RoleService from "@/service/RoleService";
import swal from "sweetalert";
import router from "@/router";

//export const namespaced = true

export const state = {
    roles: {
        totalRoles: "0",
        totalPendingRoles: "0",
        totalActiveRoles: "0",
        totalDisabledRoles: "0",
        data: []
    },
    privileges: {
        totalRoles: "0",
        totalPendingRoles: "0",
        totalActiveRoles: "0",
        totalDisabledRoles: "0",
        data: []
    },
    rolePrivileges: {
        totalRoles: "0",
        totalPendingRoles: "0",
        totalActiveRoles: "0",
        totalDisabledRoles: "0",
        privileges: []
    },
    response:{},
    roleLoading: false,
    privilegeLoading: false

}


export const getters = {

}


export const mutations = {
    updateResponse:(state, payload) =>{
        state.response = payload
    },

    updateRoles: (state, payload) =>{
        state.roles = payload
    },

    updatePrivileges: (state, payload) =>{
        state.privileges = payload
    },

    updateRolePrivileges: (state, payload) =>{
        state.rolePrivileges = payload
    },

    updateRoleLoading: (state, payload) =>{
        state.roleLoading = payload
    },

    updatePrivilegeLoading: (state, payload) =>{
        state.privilegeLoading = payload
    },
}


export const actions = {
    updateRoles: ({ commit, state }, payload)=>{
        if(state.roles.data.length < 1)
            commit("updateRoleLoading", true)
        return RoleService.callReadRolesApi(payload)
            .then(response => {
                commit("updateRoleLoading", false)
                if (response.data.responseCode === "00") {
                    let responseData = response.data;
                    commit("updateRoles", responseData)
                }

            })
            .catch(error => {
                commit("updateRoleLoading", false)
                alert(error)
                // throw error;
            });
    },

    createRole: ({ commit }, payload)=>{
        commit("updateRoleLoading", true)
        return RoleService.callCreatRoleApi(payload)
            .then(response => {
                commit("updateRoleLoading", false)
                let responseData = response.data
                if (payload.roleId == null || payload.roleId === '')
                    if (responseData.responseCode === "00" && responseData.responseMessage !== 'Activity Queued Awaiting Authorization') {
                        payload.rpRoleId = responseData.roleId
                        RoleService.callAssignPrivilegesApi(payload)
                            .then(response => {
                                commit("updateRoleLoading", false)
                                let responseData = response.data
                                if (responseData.responseCode === "00") {
                                    swal('Success!', responseData.responseMessage, 'success').then(()=>{
                                        router.push('/roles')
                                    })
                                }else{
                                    swal('Error!', responseData.responseMessage, 'error')
                                }
                            })
                            .catch(error => {
                                commit("updateRoleLoading", false)
                                alert(error)
                                // throw error;
                            })
                        //swal('Success!', responseData.responseMessage, 'success')
                    }else if (responseData.responseCode === "00"){
                        swal('Success!', responseData.responseMessage, 'success').then(()=>{
                            router.push('/roles')
                        })
                    }else {
                        swal('Error!', responseData.responseMessage, 'error').then()
                    }
                else{
                    if (responseData.responseCode === "00")
                        swal('Success!', responseData.responseMessage, 'success').then(()=>{
                            router.push('/roles')
                        })
                    else
                        swal('Error!', responseData.responseMessage, 'error').then()
                }
            })
            .catch(error => {
                commit("updateRoleLoading", false)
                alert(error)
                // throw error;
            });
    },

    updatePrivileges: ({ commit }, payload)=>{
        commit("updatePrivilegeLoading", true)
        return RoleService.callReadPrivilegesApi(payload)
            .then(response => {
                commit("updatePrivilegeLoading", false)
                if (response.data.responseCode === "00") {
                    let responseData = response.data;
                    commit("updatePrivileges", responseData)
                }

            })
            .catch(error => {
                commit("updatePrivilegeLoading", false)
                alert(error)
                // throw error;
            });
    },

    updateRolePrivileges: ({ commit }, payload)=>{
        //if(state.roles.data.length < 1)
            commit("updatePrivilegeLoading", true)
        return RoleService.callReadRolePrivilegesApi(payload)
            .then(response => {
                commit("updatePrivilegeLoading", false)
                if (response.data.responseCode === "00") {
                    let responseData = response.data;
                    commit("updateRolePrivileges", responseData)
                }

            })
            .catch(error => {
                commit("updatePrivilegeLoading", false)
                alert(error)
                // throw error;
            });
    },

    updateRole: ({ commit }, payload)=>{
        commit("updateRole",payload)
    },

    reset: ({ commit }, payload)=>{
        commit("reset",payload)
    }

}
