import AccountingRuleService from "../../service/AccountingRuleService";
import swal from 'sweetalert'
//import router from "../../router";



export const state = {
    accountingRules:{
        responseCode: '',
        responseMessage: '',
        data: []
    },
    accountingRule:{
        responseCode: '',
        responseMessage: ''
    },
    entries:{
        responseCode: '',
        responseMessage: '',
        data: []
    },
    slabs:{
        responseCode: '',
        responseMessage: '',
        data: []
    },
    slab:{
        responseCode: '',
        responseMessage: '',
        entries: []
    },
    accountingRuleLoading: false,
    response:{
        responseCode: '',
        responseMessage: ''
    }

}

export const mutations = {
    updateEntries: (state, payload) =>{
        state.entries = payload
    },
    updateAccountingRules: (state, payload) =>{
        state.accountingRules = payload
    },
    addEntryToSlab: (state, payload) =>{
        state.slab.entries.push(payload)
    },
    updateResponse: (state, payload) =>{
        state.response = payload
    },
    removeEntryFromSlab: (state, payload) =>{
        console.log(payload)
        console.log(state.slab.entries)
        let element = state.slab.entries.filter(it=>it.entryId === payload)[0]
        let index = state.slab.entries.indexOf(element)
        state.slab.entries.splice(index,1)
        console.log(state.slab.entries)
    },
    updateAccountingRule: (state, payload) =>{
        state.accountingRule = payload
    },
    updateSlabs: (state, payload) =>{
        state.slabs = payload
    },
    updateSlab: (state, payload) =>{
        state.slab = payload
    },
    updateAccountingRuleLoading: (state, payload) =>{
        state.accountingRuleLoading = payload
    },
}

export const actions = {
    updateAccountingRules: ({ commit, state }, payload)=>{
        if(state.accountingRules.data.length < 1)
            commit("updateAccountingRuleLoading", true)
        return AccountingRuleService.callAccountRuleReadApi(payload)
            .then(response => {
                commit("updateAccountingRuleLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updateAccountingRuleLoading", false)
                    let responseData = response.data;
                    commit("updateAccountingRules", responseData)
                }

            })
            .catch(error => {
                commit("updateAccountingRuleLoading", false)
                alert(error)
                // throw error;
            });
    },

    updateAccountingRule: ({ commit }, payload)=>{
        commit("updateAccountingRuleLoading", true)
        return AccountingRuleService.callAccountingRuleReadSingleApi(payload)
            .then(response => {
                commit("updateAccountingRuleLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updateAccountingRuleLoading", false)
                    let responseData = response.data;
                    commit("updateAccountingRule", responseData)
                }

            })
            .catch(error => {
                commit("updateAccountingRuleLoading", false)
                alert(error)
                // throw error;
            });
    },

    createAccountingRule: ({ commit }, payload)=>{
        commit("updateAccountingRuleLoading", true)
        return AccountingRuleService.callAccountingRuleCreateApi(payload)
            .then(response => {
                commit("updateAccountingRuleLoading", false)
                let responseData = response.data
                commit("updateResponse", responseData)
                if (responseData.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success')
                }else{
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            .catch(error => {
                commit("updateResponse", {})
                commit("updateAccountingRuleLoading", false)
                alert(error)
                // throw error;
            });
    },

    deleteAccountingRule: ({ commit }, payload)=>{
        commit("updateAccountingRuleLoading", false)
        return AccountingRuleService.callAccountingRuleDeleteApi(payload)
    },

    updateSlabs: ({ commit, state }, payload)=>{
        if(state.slabs.data.length < 1)
            commit("updateAccountingRuleLoading", true)
        return AccountingRuleService.callReadSlabApi(payload)
            .then(response => {
                commit("updateAccountingRuleLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updateAccountingRuleLoading", false)
                    let responseData = response.data;
                    commit("updateSlabs", responseData)
                }

            })
            .catch(error => {
                commit("updateAccountingRuleLoading", false)
                alert(error)
                // throw error;
            });
    },

    updateSlab: ({ commit }, payload)=>{
        commit("updateAccountingRuleLoading", true)
        return AccountingRuleService.callReadSingleSlabApi(payload)
            .then(response => {
                commit("updateAccountingRuleLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updateAccountingRuleLoading", false)
                    let responseData = response.data;
                    commit("updateSlab", responseData)
                }

            })
            .catch(error => {
                commit("updateAccountingRuleLoading", false)
                alert(error)
                // throw error;
            });
    },

    createSlab: ({ commit }, payload)=>{
        commit("updateAccountingRuleLoading", true)
        return AccountingRuleService.callCreateSlabApi(payload)
            .then(response => {
                commit("updateAccountingRuleLoading", false)
                let responseData = response.data
                if (responseData.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success')
                }else{
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            .catch(error => {
                commit("updateAccountingRuleLoading", false)
                alert(error)
                // throw error;
            });
    },

    createEntries: ({ commit }, payload)=>{
        commit("updateAccountingRuleLoading", true)
        return AccountingRuleService.callAccountingRuleEntrySubmitApi(payload)
            .then(response => {
                commit("updateAccountingRuleLoading", false)
                let responseData = response.data
                commit("updateResponse", responseData)
                if (responseData.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success')
                }else{
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            .catch(error => {
                commit("updateResponse", {})
                commit("updateAccountingRuleLoading", false)
                alert(error)
                // throw error;
            });
    },
}