<template>
  <div class="content ht-100v pd-0">
    <div class="content-body pd-10">
      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
        <div>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-style1 mg-b-10">
              <li class="breadcrumb-item">
                <router-link to="#">Reports</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">List of periodic Reports</li>
            </ol>
          </nav>
          <h4 class="mg-b-0 tx-spacing--1">List of Periodic Reports</h4>
          <p class="tx-color-03 tx-12 mg-b-0">{{auth.userInfo.userFirstName}} {{auth.userInfo.userLastName}}</p>
          <p class="tx-color-03 tx-12 mg-b-0"><strong>Last login:</strong> {{auth.userInfo.userLastLogin | moment('dddd, MMMM Do YYYY, h:mm:ss a')}}</p>
        </div>
        <div class="d-none d-md-block">
          <download-excel
              :data="periodicReports"
              class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
            <PrinterIcon class="mg-r-5"/>{{$t('extra.downloadExcel')}}
          </download-excel>
          <download-excel
              type="csv"
              :data="periodicReports"
              class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
            <PrinterIcon class="mg-r-5"/>{{$t('extra.downloadCsv')}}
          </download-excel>
          <button :style="`display:${roleUtil.authorizePrivilege('REPORT', 'CREATE')?'':'none'};`" @click="showModal = !showModal" class="btn btn-sm pd-x-15 btn-secondary btn-uppercase mg-l-5">Add New Periodic Report</button>
          <button @click="generatePdf" style="background-color: #6d0606; border-color: #6d0606;" class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
            <span> <file-icon class="wd-10 mg-r-5"/> Download Pdf</span>
          </button>
        </div>
      </div>
      <base-top-cards :carditems="cards"/>
      <hr>
      <periodic-report-modal :show="showModal" :row="{}"/>
      <periodic-report :reports="periodicReports" ref="childComponent" />
      <base-table
          :items="periodicReports"
          :fields="fields"
          :show-title="true"
          :is-busy="user.userLoading"
          class="mt-2"
          filter-mode="audit"
      ></base-table>
    </div>
  </div>
</template>

<script>
import BaseTopCards from "../../components/card/BaseTopCards";
import BaseTable from "../../components/table/BaseTable";
//import PartnerModal from "@/components/modal/PartnerModal";
import PeriodicReportModal from "../../components/modal/PeriodicReportModal";
// import BaseButton from "../../components/button/BaseButton";
import { PrinterIcon } from 'vue-feather-icons'
import {mapState} from 'vuex'
import {RoleUtil} from "@/utility/RoleUtil";
import PeriodicReport from "./ReportPdf/PeriodicReportInvoice";

export default {
  name: 'periodicReports',
  data() {
    return {
      showModal: true,
      fields: [
        {key: 'serial', label: 'S/N', sortable: true, class: 'text-left'},
        // {key: 'country', label: 'Country Code', sortable: true, class: 'text-left'},
        {key: 'scheduleReportId', label: 'Report Name', sortable: true, class: 'text-left'},
        {key: 'scheduleFrequency', label: 'Frequency', sortable: true, class: 'text-left'},
        {key: 'scheduleFormat', label: 'File Format', sortable: true, class: 'text-left'},
        {key: 'scheduleCount', label: 'Count', sortable: true, class: 'text-left'},
        {key: 'scheduleStatus', label: 'Status', sortable: true, class: 'text-left'},
        {key: 'scheduleCreatedAt', label: 'Created At', sortable: true, class: 'text-left'},
        {key: 'actionsReportScheduler', label: 'Actions'}

      ],
      model:{
        auditTrailFirstName:'',
        auditTrailLastName:'',
        auditTrailEmail:'',
        auditTrailChecker:''
      }
    }
  },
  components: {
    PeriodicReport,
    PeriodicReportModal,
    BaseTable,
    BaseTopCards,
    PrinterIcon,

  },
  computed: {
    roleUtil(){
      return RoleUtil
    },
    cards:{
      get(){
        return [
          {
            id: 0,
            name: 'Total Periodic Reports',
            number: this.periodicReports.length,// == null?'0.00':'GHS '+this.numberWithCommas(this.totalGhs),
            color: 'bg-primary'
          },
          {
            id: 1,
            name: 'Total Pending Periodic Reports',
            number: this.periodicReports.filter(it => it.cqStatus === 'PENDING' ).length,// == null?'0.00':'GBP '+this.numberWithCommas(this.totalGbp),
            color: 'bg-orange'
          },
          {
            id: 2,
            name: 'Total Active Periodic Reports',
            number: this.periodicReports.filter(it => it.cqStatus === 'ACTIVE' ).length,// == null?'0.00':'USD '+this.numberWithCommas(this.totalUsd),
            color: 'bg-success'
          },
          {
            id: 3,
            name: 'Total Inactive Periodic Reports',
            number: this.periodicReports.filter(it => it.cqStatus === 'DECLINED' ).length,// == null?'0.00':'EUR '+this.numberWithCommas(this.totalEur),
            color: 'bg-black-8'
          }
        ]
      },
      set(){

      }
    },
    periodicReports(){
      return  this.report.periodicReports
    },
    authobj: function () {
      return this.$store.state.report.periodicReports
    },
    ...mapState([
      'auth',
      'user',
      'report'
    ])
  },
  methods: {
    generatePdf(){
      // window.resizeTo(300, 300).print();
      this.$refs.childComponent.generatePdf();
    },
    hideModal(){
      this.$refs['new-periodicReport-modal'].hide();
    },
    createChecker: async function () {
      await this.$store.dispatch("auth/initiateEnrollment", this.model, {root: true}).then();
      this.$refs['new-periodicReport-modal'].hide();
      await this.$store.dispatch("auth/updateAuditTrails", {
        request: 'auditTrails'
      }, {root: true}).then();
    },
    numberWithCommas(x) {
      let parts = x.toString().split(".");
      if (parts[1] == null)  parts[1] = '00'
      else if (parts[1].length === 0) parts[1] = parts[1]+'00'
      else if (parts[1].length === 1) parts[1] = parts[1]+'0'
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    },
  },
  created() {
    this.$store.dispatch("report/readPeriodicReport", {readAll: 'YES',source: 'WEB'}, {root: false}).then()
  }
}


</script>

<style scoped>

</style>
