<template>
  <div class="content ht-100v pd-0">
    <div class="content-body pd-10">
      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
        <div>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-style1 mg-b-10">
              <li class="breadcrumb-item">
                <router-link to="#">Transaction</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">All Transaction Rules</li>
            </ol>
          </nav>
          <h4 class="mg-b-0 tx-spacing--1">View All Transaction Rules</h4>
          <p class="tx-color-03 tx-12 mg-b-0">{{auth.userInfo.userFirstName}} {{auth.userInfo.userLastName}}</p>
          <p class="tx-color-03 tx-12 mg-b-0"><strong>Last login:</strong> {{auth.userInfo.userLastLogin | moment('dddd, MMMM Do YYYY, h:mm:ss a')}}</p>
        </div>
        <div class="d-none d-md-block">
          <download-excel
              :data="screenings"
              class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
            <PrinterIcon class="mg-r-5"/>{{$t('extra.downloadExcel')}}
          </download-excel>
          <download-excel
              type="csv"
              :data="screenings"
              class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
            <PrinterIcon class="mg-r-5"/>{{$t('extra.downloadCsv')}}
          </download-excel>
        </div>
      </div>
      <base-top-cards :carditems="cards"/>
      <hr>
      <div class="row">
        <div class="col-md-12 mg-t-10">
          <div class="card ht-100p">
            <div class="card-header d-flex align-items-center justify-content-between">
              <h6 class="mg-b-0">Create Rule</h6>
              <div class="d-flex tx-18"></div>
            </div>
            <form class="p-xl-2" style="padding: 10px;"  @submit.prevent="createTransactionScreening">
              <strong>Set up Transaction Rule</strong><br><br>
              <p style="line-height: 0;">Decide the conditions for the Screening Rule.</p><br>
              <p><code>{{queriesMain}}</code></p><br>
<!--              <p style="text-align: center;"><code v-if="report.columnsLoading">Columns Loading...</code></p>-->
              <b-form-row>
                <b-form-group class="col-md-3" >
                  <b-input v-model="model1.screeningCode" maxlength="10" required placeholder="Code"/>
                </b-form-group>
                <b-form-group class="col-md-3" >
                  <b-select required v-model="model1.screeningType">
                    <b-select-option value="">Select Screening Type</b-select-option>
                    <b-select-option value="DC">Direct Credit</b-select-option>
                    <b-select-option value="DC_INTER_BANK">Direct Credit (Other Bank)</b-select-option>
                    <b-select-option value="CP">Cash Pickup</b-select-option>
                  </b-select>
                </b-form-group>
                <b-form-group class="col-md-6" >
                  <b-input v-model="model1.screeningDescription" maxlength="140" required placeholder="Description"/>
                </b-form-group>
              </b-form-row>
              <div v-for="(query, index) in queries" v-bind:key="index" class="form-row">
                <div class="col-md-3">
                  <div class="form-group">
                    <label>Column Name:</label>
                    <multiselect
                        v-model="query.columnName"
                        :options="options"
                        placeholder="Column Name"
                        required
                        @change="formatCondition()"
                    ></multiselect>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label>Query Type:</label>
                    <multiselect
                        v-model="query.queryType"
                        :options="options2"
                        placeholder="Query Type"
                        required
                        @change="formatCondition()"
                    ></multiselect>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label>Keyword:&nbsp;
                      <input value="text" v-model="query.keywordType" id="text" type="radio" name="keywordType">&nbsp;
                      <label for="text">Text</label>&nbsp;&nbsp;&nbsp;
                      <input value="function" v-model="query.keywordType" id="function" type="radio" name="keywordType">&nbsp;
                      <label for="function">Function</label></label>

                    <b-input v-if="query.keywordType === 'text'" required type="text" v-model="query.keyword" @change="formatCondition()"/>
                    <b-select v-else required v-model="query.keyword">
                      <b-select-option value="">Select Function</b-select-option>
                      <b-select-option value="@CURRENT_DATE">Current Date</b-select-option>
                      <b-select-option value="@CURRENT_MONTH">Current Month</b-select-option>
                      <b-select-option value="@CURRENT_YEAR">Current Year</b-select-option>
                    </b-select>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label >Join Type</label>
                    <b-select
                        id="join"
                        :type="'text'"
                        v-model="query.join"
                        :label="'JOIN'"
                        :placeholder="'JOIN'"
                        required
                        class="form-control"
                        @change="addMoreQuery(query.join, index)"
                    >
                      <option value="NONE">NONE</option>
                      <option value="OR">OR</option>
                      <option value="AND">AND</option>
                    </b-select>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-2">
                  <span style="width: 100%; cursor: pointer" @click="$router.go(-1)" class="btn btn-secondary btn-">Back</span>
                </div>
                <div class="col-md-8"></div>
                <div :style="`display:${roleUtil.authorizePrivilege('TRANSACTION_RULE', 'CREATE')?'block':'none'};`" class="col-md-2">
                  <base-button title="Submit" :loading="screening.screeningLoading"/>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <base-table
          :items="screenings"
          :fields="fields"
          :show-title="true"
          :is-busy="false"
          class="mt-2"
          filter-mode="balances"
      ></base-table>
    </div>
  </div>
</template>

<script>
import BaseTopCards from "../../components/card/BaseTopCards";
import BaseButton from "@/components/button/BaseButton";
import BaseTable from "@/components/table/BaseTable";
import { PrinterIcon } from 'vue-feather-icons'
import {mapState} from 'vuex'
import {RoleUtil} from "@/utility/RoleUtil";

export default {
  name: 'Transaction',
  data() {
    return {
      fields: [
        { key: 'serial', label: 'S/N', sortable: true, sortDirection: 'desc' },
        { key: 'screeningCode', label: 'Code', sortable: true, sortDirection: 'desc' },
        { key: 'screeningType', label: 'Type', sortable: true, class: 'text-left' },
        { key: 'screeningDescription', label: 'Title', sortable: true, sortDirection: 'desc' },
        { key: 'screeningRule', label: 'Query', sortable: true, class: 'text-left' },
        { key: 'screeningCreatedAt', label: 'Date', sortable: true, class: 'text-left' },
        { key: 'screeningStatus', label: 'Status', sortable: true, class: 'text-left' },
        { key: 'actionsScreening', label: 'Actions', sortable: true, class: 'text-left' }
      ],
      screen: 'one',
      // options: ['transMtoCode','transSourceAmount','transDestinationAmount','transRate','transSourceCurrency',
      //   'transSourceCountry','transDestinationCountry','transDestinationCurrency','transReceiver','screeningStatus'
      // ],
      selectAll: false,
      selectedColumns:[],
      options2: ['LIKE', '=', '<>', '>', '<', '<=', '>='],
      options3: ['NONE', 'AND', 'OR'],
      queries: [
          { columnName: '', queryType: '', keyword: '', join: '', keywordType: 'text' }
      ],
      queriesMain: "",
      model1:{
        screeningType:'',
        screeningCode:'',
        screeningDescription:'',
        screeningMaker:'',
        screeningRule:''
      },
      model:{
        reportColumn1:'',
        reportColumn2:'',
        reportTableType:'table',
        reportTableType2:'table',
        reportId: '',
        reportName: '',
        reportDescription: '',
        reportOwner: 'SYSTEM',
        reportBankCode: '000000',
        reportStatus: '',
        reportCategory: '',
        reportType: '',
        reportCreatedAt: '',
        reportFieldList: '',
        reportCondition: '',
        reportMaker: 'SYSTEM',
        reportChecker: 'SYSTEM',
        reportTable: '',
        requestType: 'create',
        reportUpdatedAt: '',
        reportJoinTable: '',
        reportJoinType: '',
        reportJoinOn: ''
      },
      limitEdit: false,
      passwordEdit: false,
      accountEdit: false,
    }
  },
  components: {
    BaseTopCards,
    BaseButton,
    PrinterIcon,
    BaseTable,
  },
  computed: {
    roleUtil(){
      return RoleUtil
    },
    options(){
      let opt = []
      console.log(this.screening.transactionScreeningColumns)
      for (let i = 0; i < this.screening.transactionScreeningColumns.columns.length; i++)
        opt.push(this.screening.transactionScreeningColumns.columns[i].columnName)
      return opt
    },
    cards:{
      get(){
        return [
          {
            id: 0,
            name: 'Total Rules',
            number: this.totalCount,// == null?'0.00':'GHS '+this.numberWithCommas(this.totalGhs),
            color: 'bg-primary'
          },
          {
            id: 1,
            name: 'AML Screened',
            number: this.totalPending,// == null?'0.00':'GBP '+this.numberWithCommas(this.totalGbp),
            color: 'bg-orange'
          },
          {
            id: 2,
            name: 'Successful',
            number: this.totalPaid,// == null?'0.00':'USD '+this.numberWithCommas(this.totalUsd),
            color: 'bg-success'
          },
          {
            id: 3,
            name: 'Unsuccessful',
            number: this.totalReceived,// == null?'0.00':'EUR '+this.numberWithCommas(this.totalEur),
            color: 'bg-black-8'
          }
        ]
      },
      set(){

      }
    },
    screenings(){
      return  this.screening.transactionScreenings.data
    },
    authobj: function () {
      return this.$store.state.auth.response
    },
    ...mapState([
      'screening',
      'auth'
    ]),
    totalCount(){
      let count = 0
      if (this.screening.transactionScreenings.data != null)
        count = this.screening.transactionScreenings.data.length
      return count
    },
    totalPaid(){
      let count = 0.00
      if (this.screening.transactionScreenings.data != null)
        count = this.screening.transactionScreenings.data.reduce((init, curr) => (
            init+=parseFloat(curr.screeningStatus.toLowerCase()==='posted'?'1':'0')
        ),0)
      return count
    },
    totalPending(){
      let count = 0.00
      if (this.screening.transactionScreenings.data != null)
        count = this.screening.transactionScreenings.data.reduce((init, curr) => (
            init+=parseFloat(curr.screeningStatus.toLowerCase()==='aml gate'?'1':'0')
        ),0)
      return count
    },
    totalReceived(){let count = 0.00
      if (this.screening.transactionScreenings.data != null)
        count = this.screening.transactionScreenings.data.reduce((init, curr) => (
            init+=parseFloat(curr.screeningStatus.toLowerCase()==='received'?'1':'0')
        ),0)
      return count
    },
  },
  methods: {
    numberWithCommas(x) {
      let parts = x.toString().split('.');
      if (parts[1] == null)  parts[1] = '00'
      else if (parts[1].length === 0) parts[1] = parts[1]+'00'
      else if (parts[1].length === 1) parts[1] = parts[1]+'0'
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    },
    addMoreQuery(_item, _index) {
      //this.formatCondition()
      let index = _index + 1;
      console.log('currentIndex', _index);
      console.log('nextIndex', index);
      console.log('length', this.queries.length);
      if (_item !== 'NONE') {
        if (this.queries.length <= index) {
          this.queries.push({
            columnName: '',
            queryType: '',
            keyword: '',
            join: ''
          });
        }
      } else {
        this.queries.splice(index, this.queries.length - index);
      }
      this.formatCondition();
    },
    formatCondition() {
      let query = "";
      // eslint-disable-next-line no-unused-vars
      let join = "";
      console.log("keyword>>>>>>>>")
      for (let i = 0; i < this.queries.length; i++) {
        console.log("keyword>>>>>>>>",this.queries[i].keyword)
        console.log("keyword>>>>>>>>")
        if (this.queries[i].queryType === 'LIKE') {
          if (this.queries[i].join === 'NONE') join = '';
          else join = this.queries[i].join;
          if (this.queries[i].keyword === '@CURRENT_DATE')
            query = query+'CAST('+this.queries[i].columnName+' AS DATE) ' +this.queries[i].queryType +" %CAST(GETDATE() AS DATE)% " + join + " ";
          else if (this.queries[i].keyword === '@CURRENT_MONTH')
            query = query+'CAST(FORMAT('+this.queries[i].columnName+',\'yyyyMM\') AS INT) ' +this.queries[i].queryType +" %CAST(FORMAT(GETDATE(),'yyyyMM') AS INT)% " + join + " ";
          else if (this.queries[i].keyword === '@CURRENT_YEAR')
            query = query+'CAST(FORMAT('+this.queries[i].columnName+',\'yyyy\') AS INT) ' +this.queries[i].queryType +" %CAST(FORMAT(GETDATE(),'yyyy') AS INT)% " + join + " ";
          else
            query = query+this.queries[i].columnName+' ' +this.queries[i].queryType +" '%"+this.queries[i].keyword + "%' " + join + " ";

          //CAST(C.transactionCreatedAt AS DATE)
          // CAST(FORMAT(GETDATE(),'yyyyMM') AS INT)
          console.log('looping>>>>::', query);
        } else {
          if (this.queries[i].join === 'NONE') join = '';
          else join = this.queries[i].join;
          if (this.queries[i].keyword === '@CURRENT_DATE')
            query = query+'CAST('+this.queries[i].columnName+' AS DATE) ' +this.queries[i].queryType +" CAST(GETDATE() AS DATE) " + join + " ";
          else if (this.queries[i].keyword === '@CURRENT_MONTH')
            query = query+'CAST(FORMAT('+this.queries[i].columnName+',\'yyyyMM\') AS INT) ' +this.queries[i].queryType +" CAST(FORMAT(GETDATE(),'yyyyMM') AS INT) " + join + " ";
          else if (this.queries[i].keyword === '@CURRENT_YEAR')
            query = query+'CAST(FORMAT('+this.queries[i].columnName+',\'yyyy\') AS INT) ' +this.queries[i].queryType +" CAST(FORMAT(GETDATE(),'yyyy') AS INT) " + join + " ";
          else
            query = query+this.queries[i].columnName+' ' +this.queries[i].queryType +" '"+this.queries[i].keyword + "' " + join + " ";
          // query =
          //     query +
          //     this.queries[i].columnName +
          //     ' ' +
          //     this.queries[i].queryType +
          //     " '" +
          //     this.queries[i].keyword +
          //     "' " +
          //     join +
          //     ' ';
          console.log("looping>>>>::",query)
        }
      }
      this.queriesMain = query;
      // console.log("statements>>>>>>",query)
      // console.log("queries>>>>>>>>>",this.queries)
    },
    createTransactionScreening(){
      //if (this.)
      this.model1.screeningRule = this.queriesMain
      this.model1.screeningMaker = this.auth.userInfo.userId
      this.$store.dispatch('screening/createTransactionScreening', this.model1, {root: false}).then()
      this.model1 = {
        screeningCode:'',
        screeningDescription:'',
        screeningMaker:'',
        screeningRule:''
      }
    }
  },
  created() {
    this.$store.dispatch('screening/updateTransactionScreenings', {readAll:'YES'}, {root: false}).then()
    this.$store.dispatch('screening/updateTransactionScreeningColumns', {reportBankCode:'000000', reportName: 'TBL_MTO_DC_CONTRACT'}, {root: false}).then()
  }
}


</script>

<style scoped>

</style>
