import store from "@/store/store";
export class RoleUtil {
    static PRIVILEGES = [
        {
            "pid": 1,
            "privilegeId": "0000000001",
            "privilegeModuleName": "SANDBOX",
            "privilegeModuleId": "0000000001",
            "privilegeType": "CREATE",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-05-04 03:28:52.383",
            "privilegeUpdatedAt": "2021-05-04 03:28:52.383",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 2,
            "privilegeId": "0000000002",
            "privilegeModuleName": "SANDBOX",
            "privilegeModuleId": "0000000001",
            "privilegeType": "READ",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-05-04 03:28:52.383",
            "privilegeUpdatedAt": "2021-05-04 03:28:52.383",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 3,
            "privilegeId": "0000000003",
            "privilegeModuleName": "SANDBOX",
            "privilegeModuleId": "0000000001",
            "privilegeType": "UPDATE",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-05-04 03:28:52.383",
            "privilegeUpdatedAt": "2021-05-04 03:28:52.383",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 4,
            "privilegeId": "0000000004",
            "privilegeModuleName": "SANDBOX",
            "privilegeModuleId": "0000000001",
            "privilegeType": "CHECKER",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-05-04 03:28:52.383",
            "privilegeUpdatedAt": "2021-05-04 03:28:52.383",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 5,
            "privilegeId": "0000000005",
            "privilegeModuleName": "DIRECT_CREDIT",
            "privilegeModuleId": "0000000001",
            "privilegeType": "CREATE",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-05-04 03:30:21.853",
            "privilegeUpdatedAt": "2021-05-04 03:30:21.853",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 6,
            "privilegeId": "0000000006",
            "privilegeModuleName": "DIRECT_CREDIT",
            "privilegeModuleId": "0000000001",
            "privilegeType": "READ",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-05-04 03:30:21.853",
            "privilegeUpdatedAt": "2021-05-04 03:30:21.853",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 7,
            "privilegeId": "0000000007",
            "privilegeModuleName": "DIRECT_CREDIT",
            "privilegeModuleId": "0000000001",
            "privilegeType": "UPDATE",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-05-04 03:30:21.853",
            "privilegeUpdatedAt": "2021-05-04 03:30:21.853",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 8,
            "privilegeId": "0000000008",
            "privilegeModuleName": "DIRECT_CREDIT",
            "privilegeModuleId": "0000000001",
            "privilegeType": "CHECKER",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-05-04 03:30:21.857",
            "privilegeUpdatedAt": "2021-05-04 03:30:21.857",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 9,
            "privilegeId": "0000000009",
            "privilegeModuleName": "MTO",
            "privilegeModuleId": "0000000001",
            "privilegeType": "CREATE",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-05-04 03:31:09.573",
            "privilegeUpdatedAt": "2021-05-04 03:31:09.573",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 10,
            "privilegeId": "0000000010",
            "privilegeModuleName": "MTO",
            "privilegeModuleId": "0000000001",
            "privilegeType": "READ",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-05-04 03:31:09.573",
            "privilegeUpdatedAt": "2021-05-04 03:31:09.573",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 11,
            "privilegeId": "0000000011",
            "privilegeModuleName": "MTO",
            "privilegeModuleId": "0000000001",
            "privilegeType": "UPDATE",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-05-04 03:31:09.573",
            "privilegeUpdatedAt": "2021-05-04 03:31:09.573",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 12,
            "privilegeId": "0000000012",
            "privilegeModuleName": "MTO",
            "privilegeModuleId": "0000000001",
            "privilegeType": "CHECKER",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-05-04 03:31:09.577",
            "privilegeUpdatedAt": "2021-05-04 03:31:09.577",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 13,
            "privilegeId": "0000000013",
            "privilegeModuleName": "MTO_PARAMETER",
            "privilegeModuleId": "0000000001",
            "privilegeType": "CREATE",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-05-04 03:31:40.983",
            "privilegeUpdatedAt": "2021-05-04 03:31:40.983",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 14,
            "privilegeId": "0000000014",
            "privilegeModuleName": "MTO_PARAMETER",
            "privilegeModuleId": "0000000001",
            "privilegeType": "READ",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-05-04 03:31:40.983",
            "privilegeUpdatedAt": "2021-05-04 03:31:40.983",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 15,
            "privilegeId": "0000000015",
            "privilegeModuleName": "MTO_PARAMETER",
            "privilegeModuleId": "0000000001",
            "privilegeType": "UPDATE",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-05-04 03:31:40.983",
            "privilegeUpdatedAt": "2021-05-04 03:31:40.983",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 16,
            "privilegeId": "0000000016",
            "privilegeModuleName": "MTO_PARAMETER",
            "privilegeModuleId": "0000000001",
            "privilegeType": "CHECKER",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-05-04 03:31:40.983",
            "privilegeUpdatedAt": "2021-05-04 03:31:40.983",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 17,
            "privilegeId": "0000000017",
            "privilegeModuleName": "REPORT",
            "privilegeModuleId": "0000000001",
            "privilegeType": "CREATE",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-05-04 03:32:03.710",
            "privilegeUpdatedAt": "2021-05-04 03:32:03.710",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 18,
            "privilegeId": "0000000018",
            "privilegeModuleName": "REPORT",
            "privilegeModuleId": "0000000001",
            "privilegeType": "READ",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-05-04 03:32:03.710",
            "privilegeUpdatedAt": "2021-05-04 03:32:03.710",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 19,
            "privilegeId": "0000000019",
            "privilegeModuleName": "REPORT",
            "privilegeModuleId": "0000000001",
            "privilegeType": "UPDATE",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-05-04 03:32:03.710",
            "privilegeUpdatedAt": "2021-05-04 03:32:03.710",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 20,
            "privilegeId": "0000000020",
            "privilegeModuleName": "REPORT",
            "privilegeModuleId": "0000000001",
            "privilegeType": "CHECKER",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-05-04 03:32:03.710",
            "privilegeUpdatedAt": "2021-05-04 03:32:03.710",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 21,
            "privilegeId": "0000000021",
            "privilegeModuleName": "ACCOUNTING_RULE",
            "privilegeModuleId": "0000000001",
            "privilegeType": "CREATE",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-05-04 03:32:30.693",
            "privilegeUpdatedAt": "2021-05-04 03:32:30.693",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 22,
            "privilegeId": "0000000022",
            "privilegeModuleName": "ACCOUNTING_RULE",
            "privilegeModuleId": "0000000001",
            "privilegeType": "READ",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-05-04 03:32:30.693",
            "privilegeUpdatedAt": "2021-05-04 03:32:30.693",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 23,
            "privilegeId": "0000000023",
            "privilegeModuleName": "ACCOUNTING_RULE",
            "privilegeModuleId": "0000000001",
            "privilegeType": "UPDATE",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-05-04 03:32:30.693",
            "privilegeUpdatedAt": "2021-05-04 03:32:30.693",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 24,
            "privilegeId": "0000000024",
            "privilegeModuleName": "ACCOUNTING_RULE",
            "privilegeModuleId": "0000000001",
            "privilegeType": "CHECKER",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-05-04 03:32:30.693",
            "privilegeUpdatedAt": "2021-05-04 03:32:30.693",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 25,
            "privilegeId": "0000000025",
            "privilegeModuleName": "TRANSACTION_RULE",
            "privilegeModuleId": "0000000001",
            "privilegeType": "CREATE",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-05-04 03:33:57.483",
            "privilegeUpdatedAt": "2021-05-04 03:33:57.483",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 26,
            "privilegeId": "0000000026",
            "privilegeModuleName": "TRANSACTION_RULE",
            "privilegeModuleId": "0000000001",
            "privilegeType": "READ",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-05-04 03:33:57.483",
            "privilegeUpdatedAt": "2021-05-04 03:33:57.483",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 27,
            "privilegeId": "0000000027",
            "privilegeModuleName": "TRANSACTION_RULE",
            "privilegeModuleId": "0000000001",
            "privilegeType": "UPDATE",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-05-04 03:33:57.483",
            "privilegeUpdatedAt": "2021-05-04 03:33:57.483",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 28,
            "privilegeId": "0000000028",
            "privilegeModuleName": "TRANSACTION_RULE",
            "privilegeModuleId": "0000000001",
            "privilegeType": "CHECKER",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-05-04 03:33:57.483",
            "privilegeUpdatedAt": "2021-05-04 03:33:57.483",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 29,
            "privilegeId": "0000000029",
            "privilegeModuleName": "AUDIT_TRAIL",
            "privilegeModuleId": "0000000001",
            "privilegeType": "CREATE",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-05-04 03:34:38.823",
            "privilegeUpdatedAt": "2021-05-04 03:34:38.823",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 30,
            "privilegeId": "0000000030",
            "privilegeModuleName": "AUDIT_TRAIL",
            "privilegeModuleId": "0000000001",
            "privilegeType": "READ",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-05-04 03:34:38.823",
            "privilegeUpdatedAt": "2021-05-04 03:34:38.823",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 31,
            "privilegeId": "0000000031",
            "privilegeModuleName": "AUDIT_TRAIL",
            "privilegeModuleId": "0000000001",
            "privilegeType": "UPDATE",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-05-04 03:34:38.823",
            "privilegeUpdatedAt": "2021-05-04 03:34:38.823",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 32,
            "privilegeId": "0000000032",
            "privilegeModuleName": "AUDIT_TRAIL",
            "privilegeModuleId": "0000000001",
            "privilegeType": "CHECKER",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-05-04 03:34:38.823",
            "privilegeUpdatedAt": "2021-05-04 03:34:38.823",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 33,
            "privilegeId": "0000000033",
            "privilegeModuleName": "ROLE",
            "privilegeModuleId": "0000000001",
            "privilegeType": "CREATE",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-05-04 03:35:07.877",
            "privilegeUpdatedAt": "2021-05-04 03:35:07.877",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 34,
            "privilegeId": "0000000034",
            "privilegeModuleName": "ROLE",
            "privilegeModuleId": "0000000001",
            "privilegeType": "READ",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-05-04 03:35:07.877",
            "privilegeUpdatedAt": "2021-05-04 03:35:07.877",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 35,
            "privilegeId": "0000000035",
            "privilegeModuleName": "ROLE",
            "privilegeModuleId": "0000000001",
            "privilegeType": "UPDATE",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-05-04 03:35:07.877",
            "privilegeUpdatedAt": "2021-05-04 03:35:07.877",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 36,
            "privilegeId": "0000000036",
            "privilegeModuleName": "ROLE",
            "privilegeModuleId": "0000000001",
            "privilegeType": "CHECKER",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-05-04 03:35:07.877",
            "privilegeUpdatedAt": "2021-05-04 03:35:07.877",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 37,
            "privilegeId": "0000000037",
            "privilegeModuleName": "USER",
            "privilegeModuleId": "0000000001",
            "privilegeType": "CREATE",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-05-04 03:35:18.367",
            "privilegeUpdatedAt": "2021-05-04 03:35:18.367",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 38,
            "privilegeId": "0000000038",
            "privilegeModuleName": "USER",
            "privilegeModuleId": "0000000001",
            "privilegeType": "READ",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-05-04 03:35:18.367",
            "privilegeUpdatedAt": "2021-05-04 03:35:18.367",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 39,
            "privilegeId": "0000000039",
            "privilegeModuleName": "USER",
            "privilegeModuleId": "0000000001",
            "privilegeType": "UPDATE",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-05-04 03:35:18.367",
            "privilegeUpdatedAt": "2021-05-04 03:35:18.367",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 40,
            "privilegeId": "0000000040",
            "privilegeModuleName": "USER",
            "privilegeModuleId": "0000000001",
            "privilegeType": "CHECKER",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-05-04 03:35:18.367",
            "privilegeUpdatedAt": "2021-05-04 03:35:18.367",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 41,
            "privilegeId": "0000000041",
            "privilegeModuleName": "CASH_PICKUP",
            "privilegeModuleId": "0000000001",
            "privilegeType": "CREATE",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-06-07 08:59:34.873",
            "privilegeUpdatedAt": "2021-06-07 08:59:34.873",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 42,
            "privilegeId": "0000000042",
            "privilegeModuleName": "CASH_PICKUP",
            "privilegeModuleId": "0000000001",
            "privilegeType": "READ",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-06-07 08:59:34.873",
            "privilegeUpdatedAt": "2021-06-07 08:59:34.873",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 43,
            "privilegeId": "0000000043",
            "privilegeModuleName": "CASH_PICKUP",
            "privilegeModuleId": "0000000001",
            "privilegeType": "UPDATE",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-06-07 08:59:34.873",
            "privilegeUpdatedAt": "2021-06-07 08:59:34.873",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 44,
            "privilegeId": "0000000044",
            "privilegeModuleName": "CASH_PICKUP",
            "privilegeModuleId": "0000000001",
            "privilegeType": "CHECKER",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-06-07 08:59:34.873",
            "privilegeUpdatedAt": "2021-06-07 08:59:34.873",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 45,
            "privilegeId": "0000000045",
            "privilegeModuleName": "SANDBOX",
            "privilegeModuleId": "0000000001",
            "privilegeType": "COMPLIANCE",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": "SYSTEM",
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-07-09 11:25:29.303",
            "privilegeUpdatedAt": "2021-07-09 11:25:29.303",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 46,
            "privilegeId": "0000000046",
            "privilegeModuleName": "DIRECT_CREDIT",
            "privilegeModuleId": "0000000001",
            "privilegeType": "COMPLIANCE",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": "SYSTEM",
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-07-09 11:25:29.303",
            "privilegeUpdatedAt": "2021-07-09 11:25:29.303",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 47,
            "privilegeId": "0000000047",
            "privilegeModuleName": "MTO",
            "privilegeModuleId": "0000000001",
            "privilegeType": "COMPLIANCE",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": "SYSTEM",
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-07-09 11:25:29.303",
            "privilegeUpdatedAt": "2021-07-09 11:25:29.303",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 48,
            "privilegeId": "0000000048",
            "privilegeModuleName": "MTO_PARAMETER",
            "privilegeModuleId": "0000000001",
            "privilegeType": "COMPLIANCE",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": "SYSTEM",
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-07-09 11:25:29.303",
            "privilegeUpdatedAt": "2021-07-09 11:25:29.303",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 49,
            "privilegeId": "0000000049",
            "privilegeModuleName": "REPORT",
            "privilegeModuleId": "0000000001",
            "privilegeType": "COMPLIANCE",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": "SYSTEM",
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-07-09 11:25:29.303",
            "privilegeUpdatedAt": "2021-07-09 11:25:29.303",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 50,
            "privilegeId": "0000000050",
            "privilegeModuleName": "ACCOUNTING_RULE",
            "privilegeModuleId": "0000000001",
            "privilegeType": "COMPLIANCE",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": "SYSTEM",
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-07-09 11:25:29.303",
            "privilegeUpdatedAt": "2021-07-09 11:25:29.303",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 51,
            "privilegeId": "0000000051",
            "privilegeModuleName": "TRANSACTION_RULE",
            "privilegeModuleId": "0000000001",
            "privilegeType": "COMPLIANCE",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": "SYSTEM",
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-07-09 11:25:29.303",
            "privilegeUpdatedAt": "2021-07-09 11:25:29.303",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 52,
            "privilegeId": "0000000052",
            "privilegeModuleName": "AUDIT_TRAIL",
            "privilegeModuleId": "0000000001",
            "privilegeType": "COMPLIANCE",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": "SYSTEM",
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-07-09 11:25:29.303",
            "privilegeUpdatedAt": "2021-07-09 11:25:29.303",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 53,
            "privilegeId": "0000000053",
            "privilegeModuleName": "ROLE",
            "privilegeModuleId": "0000000001",
            "privilegeType": "COMPLIANCE",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": "SYSTEM",
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-07-09 11:25:29.303",
            "privilegeUpdatedAt": "2021-07-09 11:25:29.303",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 54,
            "privilegeId": "0000000054",
            "privilegeModuleName": "USER",
            "privilegeModuleId": "0000000001",
            "privilegeType": "COMPLIANCE",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": "SYSTEM",
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-07-09 11:25:29.303",
            "privilegeUpdatedAt": "2021-07-09 11:25:29.303",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 55,
            "privilegeId": "0000000055",
            "privilegeModuleName": "CASH_PICKUP",
            "privilegeModuleId": "0000000001",
            "privilegeType": "COMPLIANCE",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": "SYSTEM",
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-07-09 11:25:29.303",
            "privilegeUpdatedAt": "2021-07-09 11:25:29.303",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 56,
            "privilegeId": "0000000056",
            "privilegeModuleName": "SYSTEM_SETTINGS",
            "privilegeModuleId": "0000000001",
            "privilegeType": "CREATE",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-07-12 08:38:54.060",
            "privilegeUpdatedAt": "2021-07-12 08:38:54.060",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 57,
            "privilegeId": "0000000057",
            "privilegeModuleName": "SYSTEM_SETTINGS",
            "privilegeModuleId": "0000000001",
            "privilegeType": "READ",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-07-12 08:38:54.060",
            "privilegeUpdatedAt": "2021-07-12 08:38:54.060",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 58,
            "privilegeId": "0000000058",
            "privilegeModuleName": "SYSTEM_SETTINGS",
            "privilegeModuleId": "0000000001",
            "privilegeType": "UPDATE",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-07-12 08:38:54.060",
            "privilegeUpdatedAt": "2021-07-12 08:38:54.060",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 59,
            "privilegeId": "0000000059",
            "privilegeModuleName": "SYSTEM_SETTINGS",
            "privilegeModuleId": "0000000001",
            "privilegeType": "CHECKER",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-07-12 08:38:54.060",
            "privilegeUpdatedAt": "2021-07-12 08:38:54.060",
            "privilegeMode": "PUBLIC"
        },
        {
            "pid": 60,
            "privilegeId": "0000000060",
            "privilegeModuleName": "SYSTEM_SETTINGS",
            "privilegeModuleId": "0000000001",
            "privilegeType": "COMPLIANCE",
            "privilegeMaker": "SYSTEM",
            "privilegeChecker": null,
            "privilegeStatus": "ACTIVE",
            "privilegeCreatedAt": "2021-07-12 08:38:54.060",
            "privilegeUpdatedAt": "2021-07-12 08:38:54.060",
            "privilegeMode": "PUBLIC"
        }
    ]

    static authorizeModule(_module){
        // console.log(store.getters['auth/getUser'].privileges)
        let privileges = store.getters['auth/getUser'].privileges.filter(it => it.split("|")[0].toLowerCase() === _module.toLowerCase())
        return privileges.length > 0
    }

    static authorizePrivilege(_module, _privilege){
        let privileges = store.getters['auth/getUser'].privileges.filter(
            it => it.split("|")[0].toLowerCase() === _module.toLowerCase() &&
                it.split("|")[1].toLowerCase() === _privilege.toLowerCase()
        )
        return privileges.length > 0
    }

    static authorizePrivilegeSingle(_privilege){
        let privileges = store.getters['auth/getUser'].privileges.filter(
            it => it.split("|")[1].toLowerCase() === _privilege.toLowerCase()
        )
        return privileges.length > 0
    }
}