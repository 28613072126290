import DashboardService from "@/service/DashboardService";
// import swal from 'sweetalert'
//import router from "../../router";



export const state = {
    analytics:{
        responseCode: "",
        responseMessage: "",
        dataDc: [],
        dataCp: [],
        totalTransactions: 0,
        pendingTransactions: 0,
        requestedTransactions: 0,
        postedTransactions: 0
    },
    metrics: {
        type:'last30',
        startDate:null,
        endDate:null,
    },
    analyticLoading: true

}

export const mutations = {
    updateAnalytics: (state, payload) =>{
        state.analytics = payload
    },
    updateMetrics: (state, payload) =>{
        state.metrics = payload
    },
    updateAnalyticLoading: (state, payload) =>{
        state.analyticLoading = payload
    }
}

export const actions = {
    updateAnalytics: ({ commit, state }, payload)=>{
        if(state.analytics.responseCode === "00")
            commit("updateAnalyticLoading", true)
        return DashboardService.callAnalyticApi(payload)
            .then(response => {
                commit("updateAnalyticLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updateAnalyticLoading", false)
                    let responseData = response.data;
                    commit("updateAnalytics", responseData)
                }

            })
            .catch(error => {
                commit("updateAnalyticLoading", false)
                alert(error)
                // throw error;
            });
    }
}