import Vue from 'vue';
import App from './App.vue';
import './assets/lib/jquery/jquery.min.js';
import store from './store/store';
import i18n from './i18n'
import './filters'
// import '../public/lib/jquery/jquery.min'
// import '../public/lib/perfect-scrollbar/perfect-scrollbar.min'
// import Notifications from 'vue-notification'
import JsonExcel from 'vue-json-excel'

import VueToast from 'vue-toast-notification';
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(VueToast);

import money from 'v-money'

//register directive v-money and component <money>
Vue.use(money, {precision: 4})

Vue.component('downloadExcel', JsonExcel)
// Vue.use(Notifications)

import VueSweetalert2 from 'vue-sweetalert2';

import { VueSpinners } from '@saeris/vue-spinners';

Vue.use(VueSpinners);

import * as Fingerprint2 from 'fingerprintjs2'
Vue.prototype.$fingerprint = Fingerprint2;

import VueGoodWizard from 'vue-good-wizard';

Vue.use(VueGoodWizard);

import ToggleButton from 'vue-js-toggle-button'

Vue.use(ToggleButton);

import AllIosIcon from 'vue-ionicons/dist/ionicons-ios.js'

Vue.use(AllIosIcon)

import FlagsDropdown from 'flags-dropdown-vue'
// Vue.component('flags-dropdown', FlagsDropdown);
// Vue.component( FlagsDropdown);


import NewAccountButton from "./components/button/NewAccountButton";
Vue.use(NewAccountButton);

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

// require
Vue.use(require('vue-cookies'))

// es2015 module
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

// set default config
// Vue.$cookies.config('7d')

// set global cookie
// Vue.$cookies.set('theme','default');
// Vue.$cookies.set('hover-time','1s');

import 'v-slim-dialog/dist/v-slim-dialog.css';
import SlimDialog from 'v-slim-dialog';

Vue.use(SlimDialog);

import vueCountryRegionSelect from 'vue-country-region-select';
Vue.use(vueCountryRegionSelect);

import Multiselect from 'vue-multiselect'

import VueClipboard from 'vue-clipboard2'

VueClipboard.config.autoSetContainer = true; // add this line
Vue.use(VueClipboard)

import Notifications from 'vue-notification'


const moment = require('moment')

require('moment/locale/en-gb')

Vue.use(require('vue-moment'), {
  moment
})

Vue.prototype.moment = moment

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD-MMM-YYYY')
  }
});
/*
or for SSR:
import Notifications from 'vue-notification/dist/ssr.js'
*/

Vue.use(Notifications)

// register globally
Vue.component('multiselect', Multiselect);

import VueElementLoading from 'vue-element-loading';

const options = {
  confirmButtonColor: '#042893',
  cancelButtonColor: '#ff7674',
};

import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

// import component and stylesheet
import AirbnbStyleDatepicker from 'vue-airbnb-style-datepicker';
import 'vue-airbnb-style-datepicker/dist/vue-airbnb-style-datepicker.min.css';

// see docs for available options
const datepickerOptions = {};

// make sure we can use it in our components
Vue.use(AirbnbStyleDatepicker, datepickerOptions);

Vue.use(VueSweetalert2, options);

import router from './router'
import './registerServiceWorker'

import jQuery from 'jquery'
window.jQuery = window.$ = jQuery;

import 'jquery-ui';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap-select'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

import BaseButton from "./components/button/BaseButton";
import AuthBaseButton from "./components/button/AuthBaseButton";

import VueCountryCode from "vue-country-code";

Vue.use(VueCountryCode);

// import baseJs from './assets/_baseJs.js'
// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

import VueTour from 'vue-tour';

require('vue-tour/dist/vue-tour.css');

Vue.use(VueTour);


import IdleVue from 'idle-vue'

const eventsHub = new Vue()

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 300000
})


// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false;

// const $ = require('jquery');

new Vue({
  router,
  BaseButton,
  AuthBaseButton,
  store,
  i18n,
  VuePerfectScrollbar,
  vue2Dropzone,
  NewAccountButton,
  Multiselect,
  FlagsDropdown,
  VueElementLoading,
  onIdle() {
    if (this.$route.meta.authRequired){
      console.log(router.currentRoute.path);
      let timerInterval;
      this.$swal({
        title: '<p style="font-size: 18px">Session Out!</p>',
        html: '<p style="font-size: 15px">Application will log off in less than 15 seconds. click on <b>OK</b> to cancel</p>',
        timer: 15000,
        timerProgressBar: true,
        onBeforeOpen: () => {
        },
        onClose: () => {
          clearInterval(timerInterval)
        }
        // eslint-disable-next-line no-unused-vars
      }).then((result) => {
        /* Read more about handling dismissals below */
        console.log(result)
        if (result.value === true || result.dismiss.toString() === 'backdrop') {
          console.log('session reset')
        }else{
          this.$store.dispatch('auth/logout',{
            username: this.$store.getters['auth/getUser'].username,
            userBankCode:'00000',
            userCountryCode:'234',
            source: 'WEB'
          }).then(()=>{
            localStorage.removeItem('token');
            window.location = window.__env.app.publicPath;
          })
        }
      })
    }
  },
  onActive() {
    // router.push('/')
  },
  render: h => h(App)
}).$mount('#app');

