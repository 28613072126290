import ScreeningService from "@/service/ScreeningService";
import swal from 'sweetalert'
//import router from "../../router";



export const state = {
    transactionScreenings:{
        responseCode: "00",
        responseMessage: "Success",
        data: []
    },
    transactionScreeningColumns:{
        responseCode: "00",
        responseMessage: "Success",
        columns: []
    },
    transactionScreeningLogs:{
        responseCode: "00",
        responseMessage: "Success",
        data: []
    },
    screeningLoading: false,
    response:{}

}

export const mutations = {
    updateTransactionScreenings: (state, payload) =>{
        state.transactionScreenings = payload
    },
    updateTransactionScreeningColumns: (state, payload) =>{
        state.transactionScreeningColumns = payload
    },
    updateTransactionScreeningLogs: (state, payload) =>{
        state.transactionScreeningLogs = payload
    },
    updateScreeningLoading: (state, payload) =>{
        state.screeningLoading = payload
    },
}

export const actions = {
    updateTransactionScreenings: ({ commit, state }, payload)=>{
        if(state.transactionScreenings.data.length < 1)
            commit("updateScreeningLoading", true)
        return ScreeningService.callTransactionScreeningReadApi(payload)
            .then(response => {
                commit("updateScreeningLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updateScreeningLoading", false)
                    let responseData = response.data;
                    commit("updateTransactionScreenings", responseData)
                }

            })
            .catch(error => {
                commit("updateScreeningLoading", false)
                alert(error)
                // throw error;
            });
    },

    updateTransactionScreeningColumns: ({ commit, state }, payload)=>{
        if(state.transactionScreeningColumns.columns.length < 1)
            commit("updateScreeningLoading", true)
        return ScreeningService.callTransactionScreeningReadColumnApi(payload)
            .then(response => {
                commit("updateScreeningLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updateScreeningLoading", false)
                    let responseData = response.data;
                    commit("updateTransactionScreeningColumns", responseData)
                }

            })
            .catch(error => {
                commit("updateScreeningLoading", false)
                alert(error)
                // throw error;
            });
    },

    updateTransactionScreeningLogs: ({ commit }, payload)=>{
        commit("updateScreeningLoading", true)
        return ScreeningService.callTransactionScreeningReadLogApi(payload)
            .then(response => {
                commit("updateScreeningLoading", false)
                if (response.data.responseCode === "00") {
                    let responseData = response.data;
                    commit("updateTransactionScreeningLogs", responseData)
                }

            })
            .catch(error => {
                commit("updateScreeningLoading", false)
                alert(error)
                // throw error;
            });
    },

    createTransactionScreening: ({ commit }, payload)=>{
        commit("updateScreeningLoading", true)
        return ScreeningService.callTransactionScreeningCreateApi(payload)
            .then(response => {
                commit("updateScreeningLoading", false)
                let responseData = response.data
                if (responseData.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success')
                }else{
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            .catch(error => {
                commit("updateScreeningLoading", false)
                alert(error)
                // throw error;
            });
    },

    updateStatusTransactionScreening: ({ commit }, payload)=>{
        commit("updateScreeningLoading", false)
        return ScreeningService.callTransactionScreeningUpdateStatusApi(payload)
    }
}