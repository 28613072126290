<template>
    <div class="content-header">
<!--        <a href="" id="sidebarMenuOpen" class="burger-menu"><i data-feather="arrow-left"></i></a>-->
        <div class="row-fluid">
            <div class="navbar-brand">
<!--                <a href="#" class="aside-logo"><img src="https://rubies-ibank-test-two.s3-us-west-2.amazonaws.com/img/new-rubies/logo.png" height="40px" width="100px"/>-->
                <a href="#" class="aside-logo"><img src="https://assets-base.s3.amazonaws.com/img/firstbank/logo.png" height="40px" width="100px"/>
                </a></div><!-- navbar-brand -->
            <div id="navbarMenu" class="navbar-menu-wrapper">
                <div class="navbar-menu-header">
                    <a href="../index.html" class="df-logo">dash<span>forge</span></a>
                    <a id="mainMenuClose" href=""><i data-feather="x"></i></a>
                </div><!-- navbar-menu-header -->

            </div>
        </div>
        <nav style="display: flex;">
            <nav style="display: flex; z-index: 10;">
                <!--            <template>-->
                <!--                <div class="locale-changer pt-3 form-inline nav-link">-->
                <!--                    <select class="form-control-sm form-group" v-model="$i18n.locale">-->
                <!--                        <label>Language: </label>-->
                <!--                        <option v-for="(lang, i) in system.languages" :key="`Lang${i}`" :value="lang">{{ lang }}</option>-->
                <!--                    </select>-->
                <!--                </div>-->
                <!--            </template>-->
<!--                <languages-dropdown :selected="$i18n.locale" :selected-languages="system.languages" v-on:change="optionSelected"></languages-dropdown>-->
<!--                <img @click="$i18n.locale = 'en'" style="padding-right: 5px; cursor: pointer;" src="../../assets/img/66-664783_file-emojione-1f1ec-1f1e7-svg-england-flag-icon.png" height="30px" width="30">-->
<!--                <img @click="$i18n.locale = 'fr'" style="padding-left: 5px; cursor: pointer;" src="../../assets/img/148-1486577_download-flag-icon-of-france-at-png-format.png" height="30px" width="30">-->
<!--                <b-badge @click="$i18n.locale = 'en'" title="English" variant="danger" style="font-size: 18px; cursor: pointer;">EN</b-badge><span style=" padding-left: 10px;"></span>-->
<!--                <b-badge @click="$i18n.locale = 'fr'" title="French" variant="primary" style="font-size: 18px; cursor: pointer;">FR</b-badge>-->


                <!--            <a href="#" @click="logout()" class="nav-link"><log-out-icon/></a>-->
                <!--            <a href="#" @click="startTour()" class="nav-link"><help-circle-icon></help-circle-icon></a>-->
            </nav>
<!--            <a href="#" class="btn btn-social"><i class="fab fa-facebook"></i></a>-->
<!--            <a href="#" class="btn btn-social"><i class="fab fa-instagram"></i></a>-->
<!--            <a href="#" class="btn btn-social"><i class="fab fa-twitter"></i></a>-->
            <!--            <a href="https://themeforest.net/item/azia-responsive-bootstrap-4-dashboard-template/22983790" class="btn btn-buy"><i data-feather="shopping-bag"></i> <span>Logout</span></a>-->
        </nav><!-- navbar-right -->
    </div><!-- navbar -->
</template>

<script>
    import {mapState} from "vuex"
    // import FlagsDropdown from 'flags-dropdown-vue'
    // import LanguagesDropdown from 'vue-languages-dropdown'
    export default {
        name: "AuthHeader",
        components:{
            // LanguagesDropdown
        },
        data(){
            return{
                selectedLang : "en",
                selectedList : ['en','fr']
            }
        },
        methods:{
            optionSelected: function (data) {
                this.$i18n.locale = data.code
                console.log(data)
            }
        },
        computed:{
            ...mapState([
                'system'
            ])
        }
    }
</script>

<style scoped>

</style>