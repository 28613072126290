<template>
  <div>
    <VueHtml2pdf style="padding: 5%" :float-layout="true" :preview-modal="true" filename="PeriodicReports" pdf-format="a2" pdf-content-width="100%" :manual-pagination="true" :enable-download="true" ref="DownloadComp">
      <section slot="pdf-content">
        <div style="width: 100%;">
          <table style=" width:100%; border:0;">
            <tr>
              <td colspan="2">&nbsp;</td>
            </tr>
            <tr>
              <td style=" width:100%">
                <table style=" width:100%">
                <tr>
                  <td><table style="width:100%" >
<!--                    <tr>-->
<!--                      <td style="font-family:Verdana, Geneva, sans-serif; font-weight:600; font-size:25px;"> Invoice Details</td>-->
<!--                    </tr>-->
<!--                    <tr>-->
<!--                      <td style="font-family:Verdana, Geneva, sans-serif; font-weight:300; font-size:12px;"><span style="font-size: 13px; font-weight: bold"> Invoice Number:</span> {{invoiceDetails.invoice_number}}</td>-->
<!--                    </tr>-->
<!--                    <tr>-->
<!--                      <td style="font-family:Verdana, Geneva, sans-serif; font-weight:300; font-size:12px;"> <span style="font-size: 13px; font-weight: bold"> Invoice Description: </span> {{ invoiceDetails.description }} </td>-->
<!--                    </tr>-->
<!--                    <tr>-->
<!--                      <td style="font-family:Verdana, Geneva, sans-serif; font-weight:300; font-size:12px;"> <span style="font-size: 13px; font-weight: bold"> Invoice Status:</span> {{ invoiceDetails.invoice_status }}</td>-->
<!--                    </tr>-->
<!--                    <tr>-->
<!--                      <td style="font-family:Verdana, Geneva, sans-serif; font-weight:300; font-size:12px;"> <span style="font-size: 13px; font-weight: bold"> Invoice Created At :</span> {{ invoiceDetails.created_at }}</td>-->
<!--                    </tr>-->
<!--                    <tr>-->
<!--                      <td style="font-family:Verdana, Geneva, sans-serif; font-weight:300; font-size:12px;"><span style="font-size: 13px; font-weight: bold"> Invoice Expiry Date: </span> {{ invoiceDetails.expiration_date.date }} </td>-->
<!--                     </tr>-->
<!--                      <tr>-->
<!--                        <td style="font-family:Verdana, Geneva, sans-serif; font-weight:300; font-size:12px;"> <span style="font-size: 13px; font-weight: bold">Invoice Total Amount: </span> {{invoiceDetails.invoice_total_amounts[0].total_amount}}  </td>-->
<!--                      </tr>-->
<!--                    <tr>-->
<!--                      <p style="font-family:Verdana, Geneva, sans-serif; font-weight:300; font-size:12px;"><span style="font-size: 13px; font-weight: bold"> Invoice Narration: </span> {{ invoiceDetails.narration }}</p>-->
<!--                    </tr>-->
<!--                                      <tr>-->
<!--                                      <td style="font-family:Verdana, Geneva, sans-serif; font-weight:300; font-size:12px;">-->
<!--                                        <span style="font-size: 13px; font-weight: bold"> Mda Name: </span>{{invoiceDetails.mda.name}}-->
<!--                                      </td>-->
<!--                                      </tr>-->
<!--                                      <tr>-->
<!--                                        <td style="font-family:Verdana, Geneva, sans-serif; font-weight:300; font-size:12px;"> <span style="font-size: 13px; font-weight: bold"> Mda Branch Address: </span> {{invoiceDetails.mda.address}} </td>-->
<!--                                      </tr>-->
<!--                                      <tr>-->
<!--                                        <td style="font-family:Verdana, Geneva, sans-serif; font-weight:300; font-size:12px;"> <span style="font-size: 13px; font-weight: bold"> Payment Date: </span> {{invoiceDetails.payment.date_processed}} </td>-->
<!--                                      </tr>-->
<!--                                      <tr>-->
<!--                                        <td style="font-family:Verdana, Geneva, sans-serif; font-weight:300; font-size:12px;"> <span style="font-size: 13px; font-weight: bold">Payment Status: </span> {{invoiceDetails.payment.status_text}} </td>-->
<!--                                      </tr>-->
<!--                                      <tr>-->
<!--                                        <td style="font-family:Verdana, Geneva, sans-serif; font-weight:300; font-size:12px;"> <span style="font-size: 13px; font-weight: bold">Payment Reference Number:</span> {{invoiceDetails.payment.reference}}</td>-->
<!--                                      </tr>-->
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                  </table></td>
                </tr>
              </table>
              </td>
              <td width="51%" valign="top"><table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tr>
                  <td style="font-family:Verdana, Geneva, sans-serif; font-weight:300; font-size:12px;" align="right"></td>
                </tr>
                <tr>
                  <td style="font-family:Verdana, Geneva, sans-serif; font-weight:300; font-size:12px;"  align="right">&nbsp;</td>
                </tr>
<!--                <tr>-->
<!--                  <td style="font-family:Verdana, Geneva, sans-serif; font-weight:600; font-size:25px;" align="right">Payer Details</td>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                  <td style="font-family:Verdana, Geneva, sans-serif; font-weight:400; font-size:15px;" align="right"><span style="font-size: 13px; font-weight: bold"> First Name: </span> first_name </td>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                  <td style="font-family:Verdana, Geneva, sans-serif; font-weight:400; font-size:15px;" align="right"><span style="font-size: 13px; font-weight: bold">Last Name: </span> last_name </td>-->
<!--                </tr>-->
              </table>
              </td>
            </tr>
            <tr>
              <td colspan="2"></td>
            </tr>
            <tr>
              <td colspan="2">
                <table>
                  <tr>
                    <td style="font-family:Verdana, Geneva, sans-serif; font-weight:600; font-size:12px; border-top:1px solid #333; border-bottom:1px solid #333; border-left:1px solid #333; border-right:1px solid #333;" >Report Schedule Id</td>
                    <td style="font-family:Verdana, Geneva, sans-serif; font-weight:600; font-size:12px; border-top:1px solid #333; border-bottom:1px solid #333; border-right:1px solid #333;" >Report Schedule Report Id</td>
                    <td style="font-family:Verdana, Geneva, sans-serif; font-weight:600; font-size:12px; border-top:1px solid #333; border-bottom:1px solid #333; border-right:1px solid #333;" width="25%" align="center">Schedule Frequency</td>
                    <td style="font-family:Verdana, Geneva, sans-serif; font-weight:600; font-size:12px; border-top:1px solid #333; border-bottom:1px solid #333; border-right:1px solid #333; border-right:1px solid #333;" width="15%" align="center">Schedule  Format</td>
                    <td style="font-family:Verdana, Geneva, sans-serif; font-weight:600; font-size:12px; border-top:1px solid #333; border-bottom:1px solid #333; border-right:1px solid #333; border-right:1px solid #333;" width="15%" align="center">Schedule Count</td>
                    <td style="font-family:Verdana, Geneva, sans-serif; font-weight:600; font-size:12px; border-top:1px solid #333; border-bottom:1px solid #333; border-right:1px solid #333; border-right:1px solid #333;" width="15%" align="center">Schedule Time</td>
                    <td style="font-family:Verdana, Geneva, sans-serif; font-weight:600; font-size:12px; border-top:1px solid #333; border-bottom:1px solid #333; border-right:1px solid #333; border-right:1px solid #333;" width="15%" align="center">Schedule Email</td>
                    <td style="font-family:Verdana, Geneva, sans-serif; font-weight:600; font-size:12px; border-top:1px solid #333; border-bottom:1px solid #333; border-right:1px solid #333; border-right:1px solid #333;" width="15%" align="center">Schedule Status</td>
                    <td style="font-family:Verdana, Geneva, sans-serif; font-weight:600; font-size:12px; border-top:1px solid #333; border-bottom:1px solid #333; border-right:1px solid #333; border-right:1px solid #333;" width="15%" align="center">Schedule Created At</td>
                    <td style="font-family:Verdana, Geneva, sans-serif; font-weight:600; font-size:12px; border-top:1px solid #333; border-bottom:1px solid #333; border-right:1px solid #333; border-right:1px solid #333;" width="15%" align="center">Schedule Updated At</td>
                  </tr>
                    <tr v-for="(item, index) in reports" :key = index>
                        <td style="font-family:Verdana, Geneva, sans-serif; font-weight:300; font-size:12px; border-bottom:1px solid #333; border-left:1px solid #333; border-right:1px solid #333;" height="32" align="center">
                          {{ item.scheduleId }}  </td>
                        <td style="font-family:Verdana, Geneva, sans-serif; font-weight:300; font-size:12px; border-bottom:1px solid #333; border-right:1px solid #333;" align="center">
                          {{ item.scheduleReportId }} </td>
                        <td style="font-family:Verdana, Geneva, sans-serif; font-weight:300; font-size:12px; border-bottom:1px solid #333; border-right:1px solid #333;" align="center">
                          {{ item.scheduleFrequency }} </td>
                        <td style="font-family:Verdana, Geneva, sans-serif; font-weight:300; font-size:12px; border-bottom:1px solid #333; border-right:1px solid #333; border-right:1px solid #333;" align="center">
                          {{ item.scheduleFormat}}</td>
                        <td style="font-family:Verdana, Geneva, sans-serif; font-weight:300; font-size:12px; border-bottom:1px solid #333; border-right:1px solid #333; border-right:1px solid #333;" align="center">
                          {{ item.scheduleCount }}</td>
                        <td style="font-family:Verdana, Geneva, sans-serif; font-weight:300; font-size:12px; border-bottom:1px solid #333; border-right:1px solid #333; border-right:1px solid #333;" align="center">
                          {{ item.scheduleTime }}</td>
                        <td style="font-family:Verdana, Geneva, sans-serif; font-weight:300; font-size:12px; border-bottom:1px solid #333; border-right:1px solid #333; border-right:1px solid #333;" align="center">
                          {{item.scheduleEmail}}</td>
                      <td style="font-family:Verdana, Geneva, sans-serif; font-weight:300; font-size:12px; border-bottom:1px solid #333; border-right:1px solid #333; border-right:1px solid #333;" align="center">
                          {{item.scheduleStatus}}</td>
                        <td style="font-family:Verdana, Geneva, sans-serif; font-weight:300; font-size:12px; border-bottom:1px solid #333; border-right:1px solid #333; border-right:1px solid #333;" align="center">
                          {{ item.scheduleCreatedAt }}</td>
                        <td style="font-family:Verdana, Geneva, sans-serif; font-weight:300; font-size:12px; border-bottom:1px solid #333; border-right:1px solid #333; border-right:1px solid #333;" align="center">
                          {{ item.scheduleUpdatedAt }}</td>
                    </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>
      </section>
    </VueHtml2pdf>
  </div>

</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
export default {
  name: "PeriodicReport",
  components: {
    VueHtml2pdf
  },
  props: {
    reports: {type: Object, required: true}
  },
  methods:{
    generatePdf(){
      this.$refs.DownloadComp.generatePdf()
    }


  }
}
</script>

<style scoped>

</style>