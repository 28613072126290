import LoanService from "../../service/LoanService";
import swal from 'sweetalert'
//import router from "../../router";



export const state = {
    loans:{
        responseCode: "00",
        responseMessage: "Success",
        totalBorrowers: "0",
        totalLoans: "0",
        performingLoan: "0",
        nonPerformingLoan: "0",
        data: []
    },
    schedules:{
        responseCode: "00",
        responseMessage: "Success",
        data: []
    },
    loanLoading: false,
    chartLoading: false,
    metrics: {
        type:'last30',
        startDate:null,
        endDate:null,
    },
    loanChart: {
        responseCode: "00",
        responseMessage: "Success",
        data: [],
        gender: [],
        state: []
    },
    loanScheduleLoading: false,
    response:{}

}

export const mutations = {
    updateLoanSchedule: (state, payload) =>{
        state.schedules = payload
    },
    updateLoans: (state, payload) =>{
        state.loans = payload
    },
    updateMetrics: (state, payload) =>{
        state.metrics = payload
    },
    updateLoanLoading: (state, payload) =>{
        state.loanLoading = payload
    },
    updateLoanScheduleLoading: (state, payload) =>{
        state.loanScheduleLoading = payload
    },
    updateResponse: (state, payload) =>{
        state.response = payload
    },
    updateLoanChart: (state, payload) =>{
        state.loanChart = payload
    },
    updateChartLoading: (state, payload) =>{
        state.chartLoading = payload
    },
}

export const actions = {
    updateLoans: ({ commit, state }, payload)=>{
        if(state.loans.data.length < 1)
            commit("updateLoanLoading", true)
        return LoanService.callLoanReadAllApi(payload)
            .then(response => {
                commit("updateLoanLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updateLoanLoading", false)
                    let responseData = response.data;
                    commit("updateLoans", responseData)
                }

            })
            .catch(error => {
                commit("updateLoanLoading", false)
                alert(error)
                // throw error;
            });
    },
    createLoan: ({ commit }, payload)=>{
        commit("updateLoanLoading", true)
        return LoanService.callLoanCreateApi(payload)
            .then(response => {
                commit("updateLoanLoading", false)
                let responseData = response.data
                if (responseData.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success')
                }else{
                    swal('Error!', responseData.responseMessage, 'error')
                }
            })
            .catch(error => {
                commit("updateLoanLoading", false)
                alert(error)
                // throw error;
            });
    },
    loanSchedules: ({ commit }, payload)=>{
        //if(state.schedules.data.length < 1)
            commit("updateLoanScheduleLoading", true)
        return LoanService.callLoanSchedulesApi(payload)
            .then(response => {
                commit("updateLoanScheduleLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updateLoanScheduleLoading", false)
                    let responseData = response.data;
                    commit("updateLoanSchedule", responseData)
                }

            })
            .catch(error => {
                commit("updateLoanScheduleLoading", false)
                alert(error)
                // throw error;
            });
    },

    updateLoanCharts: ({ commit, state }, payload)=>{
        if(state.loanChart.data.length < 1)
            commit("updateChartLoading", true)
        return LoanService.callLoanChartApi(payload)
            .then(response => {
                commit("updateChartLoading", false)
                if (response.data.responseCode === "00") {
                    let responseData = response.data;
                    commit("updateLoanChart", responseData)
                }

            })
            .catch(error => {
                commit("updateChartLoading", false)
                alert(error)
                // throw error;
            });
    },
}