<template>
    <div class="content-body mt-10">
        <div class="container pd-x-0">
            <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                <div>
                  <h4 class="mg-b-0 tx-spacing--1">FBN MTO</h4>
                </div>
                <div class="d-none d-md-block">
                </div>
            </div>

            <!--TODO TOP CARDS-->
            <imto-top-card  v-if="auth.userInfo.userType === 'IMTO'"></imto-top-card>
            <main-top-card v-else></main-top-card>


        </div><!-- container -->
    </div>
</template>

<script>
    // @ is an alias to /src
    import MainTopCard from "../../components/card/MainTopCard";
    import ImtoTopCard from "@/components/card/ImtoTopCard";
    import {mapState} from "vuex";
    // import  { FileIcon } from  "vue-feather-icons"
    export default {
        name: 'MainDashboard',
        components:{
            MainTopCard,
            ImtoTopCard,
            // FileIcon
        },
        data(){
            return{
                model:{
                    activeCompany:{}
                },
                langs: ['en', 'fr'],
            }
        },
        computed:{
            ...mapState([
                'auth'
            ])
        },
        methods:{
            navigate(_route){
                this.makeToast('danger')
                console.log(_route)
                //this.$router.push(_route)
            },
            makeToast(variant = null) {
                this.$bvToast.toast('Toast body content', {
                    title: `Variant ${variant || 'default'}`,
                    variant: variant,
                    solid: true
                })
            }

        }
    }
</script>
