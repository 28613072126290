<template>
    <div class="row">
        <!--  TODO SYSTEM SETTINGS -->

        <div class="col-md-6 mg-t-10">
            <div class="card ht-100p">
                <div class="card-header d-flex align-items-center justify-content-between">
                    <h6 class="mg-b-0">{{$t('sidebar.changePassword')}}</h6>
                    <div class="d-flex tx-18"></div>
                </div>
                <form class="p-xl-2" style="padding: 10px;"  @submit.prevent="saveSystemSetting()">
                    <div class="form-row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Number of Password Trials:</label>
                                <input type="number" :readonly="!systemSettingsEdit" v-model="model.configNoOfPasswordTrials" class="form-control" name="configNoOfPasswordTrials" placeholder="Number of Password Trials">
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                              <label>Number of Reusable Old Password:</label>
                                <input type="number" :readonly="!systemSettingsEdit" v-model="model.configNoOfReusableOldPassword" class="form-control" name="configNoOfReusableOldPassword"  placeholder="Number of Reusable Old Password">
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                              <label>Days to Change Password:</label>
                                <input type="number" :readonly="!systemSettingsEdit" v-model="model.configPasswordChangeDays" class="form-control" name="configPasswordChangeDays"  placeholder="Days to change password">
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                              <label>Maximum Days for Inactivity:</label>
                                <input type="number" :readonly="!systemSettingsEdit" v-model="model.configInactivityDayLimit" class="form-control" name="configInactivityDayLimit"  placeholder="Maximum Days for Inactivity">
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                              <label>Opening Hour:</label>
                                <input type="number" :readonly="!systemSettingsEdit" v-model="model.configOpeningHour" class="form-control" name="configOpeningHour"  placeholder="Opening Hour">
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                              <label>Closing Hour:</label>
                                <input type="number" :readonly="!systemSettingsEdit" v-model="model.configClosingHour" class="form-control" name="configClosingHour"  placeholder="Closing Hour">
                            </div>
                        </div>
                    </div>
                    <base-button v-if="systemSettingsEdit" title="Submit" :loading="this.settings.systemSettingLoading"/>
                    <button v-else @click="systemSettingsEdit = !systemSettingsEdit" class="btn btn-brand-02 btn-block">Edit</button>
                </form>
            </div><!-- card -->
        </div>
    </div><!-- row -->
</template>

<script>
    // import FlotChart from "../FlotChart";
    import {mapState} from 'vuex'
    import BaseButton from "../button/BaseButton";
    export default {
        name: "TopCards",
        components:{
            BaseButton

        },
        data(){
            return{
                model:{
                  configNoOfPasswordTrials:0,
                  configNoOfReusableOldPassword:0,
                  configPasswordChangeDays:0,
                  configInactivityDayLimit:0,
                  configOpeningHour:0,
                  configClosingHour:0,
                  configMaker:'',
                },
                limitEdit: false,
                systemSettingsEdit: false,
                accountEdit: false,
            }
        },
        watch:{
          model: function () {
              console.log("hery")
          }
        },
        methods:{
            async saveSystemSetting(){
                this.model.configMaker = this.auth.userInfo.userId;
                await this.$store.dispatch("settings/changeSystemSettings", this.model, {root: true})
                this.$store.dispatch('settings/updateSystemSettings', {readAll: "YES"});
                this.systemSettingsEdit = !this.systemSettingsEdit
            },
        },
        computed:{
            ...mapState([
                'auth',
                'settings'
            ]),
        },
        async mounted() {
          await this.$store.dispatch('settings/updateSystemSettings', {readAll: "YES"});
          this.model = this.settings.systemSettings
        }
    }
</script>

<style scoped>
    @media only screen and (min-width: 900px) {
        .top-card {
            width: 103%;
        }
    }
    @media only screen and (min-width: 707px) and (max-width: 900px) {
        .top-card {
            width: 104%;
        }
    }

    @media only screen and (min-width: 517px)  and (max-width: 707px) {
        .top-card {
            width: 105%;
        }
    }

    @media only screen and (min-width: 451px)  and (max-width: 517px) {
        .top-card {
            width: 106%;
        }
    }

    @media only screen  and (max-width: 451px) {
        .top-card {
            width: 107%;
        }
    }
</style>