<template>
    <aside class="aside aside-fixed">
        <div class="aside-header">
            <a href="#" class="aside-logo">
                <img src="https://assets-base.s3.amazonaws.com/img/firstbank/logo.png" class="img-fluid"  height="30px" width="100px" alt="FBNBank Logo">
<!--                <img src="https://rubies-ibank-test-two.s3-us-west-2.amazonaws.com/img/new-rubies/logo.png" height="40px" width="100px"/>-->
            </a>
            <a href="#" class="aside-menu-link">
                <menu-icon @click="show(true)"/>
                <x-icon  @click="show(false)"/>
<!--                <i data-feather="x"></i>-->
            </a>
            <a href="" id="chatContentClose" class="burger-menu d-none"><arrow-left-icon/></a>
            <a href="" id="contactContentHide" class="burger-menu d-none"><arrow-left-icon/></a>
        </div>
        <div class="aside-body">
            <div class="aside-loggedin">
                <div class="d-flex align-items-center justify-content-start">
<!--                    <a href="#" class="avatar"><img src="https://via.placeholder.com/500" class="rounded-circle" alt=""></a>-->
<!--                    <div class="aside-alert-link">-->
<!--                        <router-link to="#" class="new" data-toggle="tooltip" title="You have 2 unread messages"><i data-feather="message-square"></i></router-link>-->
<!--                        <router-link to="#" class="new" data-toggle="tooltip" title="You have 4 new notifications"><i data-feather="bell"></i></router-link>-->
<!--                        <a href="/" data-toggle="tooltip" title="Sign out"><i data-feather="log-out"></i></a>-->
<!--                    </div>-->
                </div>
                <div class="aside-loggedin-user">
                    <a href="#loggedinMenu" class="d-flex align-items-center justify-content-between mg-b-2" data-toggle="collapse">
                        <h6 class="tx-semibold mg-b-0">{{auth.userInfo.userFirstName}} {{auth.userInfo.userLastName}}</h6>
                        <chevron-down-icon/>
                    </a>
<!--                    <p class="tx-color-03 tx-12 mg-b-0">{{$t('sidebar.administrator')}}</p>-->
                </div>
                <div class="collapse" id="loggedinMenu">
                    <ul class="nav nav-aside mg-b-0">
                        <li class="nav-item"><router-link to="/personal-profile" class="nav-link"><edit-icon/> <span>{{$t('sidebar.personalProfile')}}</span></router-link></li>
<!--                        <li class="nav-item"><router-link to="#" class="nav-link"><help-circle-icon/> <span>{{$t('sidebar.helpCenter')}}</span></router-link></li>-->
                        <li class="nav-item"><a href="#" @click="logout()" class="nav-link"><log-out-icon/> <span>{{$t('sidebar.signOut')}}</span></a></li>
                    </ul>
                </div>
            </div><!-- aside-loggedin -->
            <base-sidebar :menus="menu"></base-sidebar>
        </div>
    </aside>
</template>

<script>
    import BaseSidebar from "../../views/template/layouts/component/baseComponent/BaseSidebar";
    import {MenuIcon,XIcon, EditIcon, LogOutIcon, ArrowLeftIcon, ChevronDownIcon} from "vue-feather-icons"
    import {mapState} from "vuex";
    import $ from 'jquery'
    import PerfectScrollBar from 'perfect-scrollbar'
    import {RoleUtil} from "@/utility/RoleUtil";
    export default {
        props:['layout'],
        components:{
            BaseSidebar,
            MenuIcon,
            XIcon,
            EditIcon,
            // HelpCircleIcon,
            LogOutIcon,
            ArrowLeftIcon,
            ChevronDownIcon
        },
        name: "SideBar",
        data(){
            return{
                model:{
                    activeCompany:{}
                },
                onBoardingMenu: [
                    {
                        header: true,
                        classType: 'nav-label mt-3',
                        show:true,
                        title: 'sidebar.dashboard'
                    },
                    {
                        icon: 'dashboard',
                        classType: 'nav-item',
                        title: 'Dashboard',
                        href: '/dashboard',
                        show:true,
                        id:'v-step-0'
                    },
                    {
                        icon: 'shopping-bag',
                        classType: 'nav-item',
                        title: 'Analytics',
                        href: '/gateway',
                        show:RoleUtil.authorizeModule('REPORT'),
                        id:'v-step-0'
                    },
                    {
                      header: true,
                      classType: 'nav-label mt-3',
                      show:RoleUtil.authorizeModule('DIRECT_CREDIT') || RoleUtil.authorizeModule('CASH_PICKUP'),
                      title: 'Transaction'
                    },
                    {
                        icon: 'shopping-bag',
                        classType: 'nav-item',
                        title: 'Direct Credits',
                        href: '/inbound-transactions',
                        show:RoleUtil.authorizeModule('DIRECT_CREDIT'),
                        id:'v-step-0'
                    },
                    {
                        icon: 'shopping-bag',
                        classType: 'nav-item',
                        title: 'Cash Pickups',
                        href: '/outbound-transactions',
                        show:RoleUtil.authorizeModule('CASH_PICKUP'),
                        id:'v-step-0'
                    },
                    {
                      header: true,
                      classType: 'nav-label mt-3',
                      show:RoleUtil.authorizeModule('TRANSACTION_RULE'),
                      title: 'Aml & Screening'
                    },
                    {
                        icon: 'shopping-bag',
                        classType: 'nav-item',
                        title: 'Transaction Queue',
                        href: '/transactions',
                        show:RoleUtil.authorizeModule('TRANSACTION_RULE'),
                        id:'v-step-0'
                    },
                    {
                        icon: 'shopping-bag',
                        classType: 'nav-item',
                        title: 'Transaction Rule',
                        href: '/transaction-rule',
                        show:RoleUtil.authorizeModule('TRANSACTION_RULE'),
                        id:'v-step-0'
                    },
                    {
                      header: true,
                      classType: 'nav-label mt-3',
                      show:RoleUtil.authorizeModule('ACCOUNTING_RULE'),
                      title: 'Rules'
                    },
                    {
                        icon: 'shopping-bag',
                        classType: 'nav-item',
                        title: 'Accounting Rule',
                        href: '/accounting-rules',
                        show:RoleUtil.authorizeModule('ACCOUNTING_RULE'),
                        id:'v-step-0'
                    },
                    {
                        icon: 'shopping-bag',
                        classType: 'nav-item',
                        title: 'New Accounting Rule',
                        href: '/create-accounting-rule',
                        show:RoleUtil.authorizeModule('ACCOUNTING_RULE'),
                        id:'v-step-0'
                    },
                    {
                      header: true,
                      classType: 'nav-label mt-3',
                      show:RoleUtil.authorizeModule('MTO'),
                      title: 'Partner Management'
                    },
                    {
                      icon: 'users',
                      classType: 'nav-item',
                      title: 'Partners',
                      href: '/partners',
                      show:RoleUtil.authorizeModule('MTO'),
                      id:'partners'
                    },
                    {
                      icon: 'users',
                      classType: 'nav-item',
                      title: 'Parameterization',
                      href: '/parameterization',
                      show:RoleUtil.authorizeModule('MTO_PARAMETER'),
                      id:'Parameterization'
                    },
                    {
                      header: true,
                      classType: 'nav-label mt-3',
                      show:RoleUtil.authorizeModule('USER') || RoleUtil.authorizeModule('ROLE'),
                      title: 'User Management'
                    },
                    {
                        icon: 'users',
                        classType: 'nav-item',
                        title: 'Users',
                        href: '/users',
                        show:RoleUtil.authorizeModule('USER'),
                        id:'users'
                    },
                  // {
                  //       icon: 'users',
                  //       classType: 'nav-item',
                  //       title: 'Upload User',
                  //       href: '/upload-users',
                  //       show:RoleUtil.authorizeModule('USER'),
                  //       id:'users'
                  //   },
                    {
                        icon: 'signatories',
                        classType: 'nav-item',
                        title: 'Roles',
                        href: '/roles',
                        show:RoleUtil.authorizeModule('ROLE'),
                        id:'Roles'
                    },
                    {
                        icon: 'users',
                        classType: 'nav-item',
                        title: 'Audit Log for MTOs',
                        href: '/audit-trails-mto',
                        show:RoleUtil.authorizeModule('USER'),
                        id:'audit'
                    },
                    {
                        icon: 'users',
                        classType: 'nav-item',
                        title: 'Audit Log',
                        href: '/audit-trails',
                        show:RoleUtil.authorizeModule('USER'),
                        id:'audit'
                    },
                    {
                      header: true,
                      classType: 'nav-label mt-3',
                      show:RoleUtil.authorizeModule('REPORT'),
                      title: 'Report'
                    },
                    {
                      icon: 'activities',
                      classType: 'nav-item',
                      title: 'Report Engine',
                      href: '/report-engine',
                      show:RoleUtil.authorizeModule('REPORT'),
                      id:'users'
                    },
                  {
                      icon: 'activities',
                      classType: 'nav-item',
                      title: 'List of Periodic Reports ',
                      href: '/periodic-report',
                      show:RoleUtil.authorizeModule('REPORT'),
                      id:'users'
                    },
                    {
                      header: true,
                      classType: 'nav-label mt-3',
                      show:true,
                      title: 'Queues and Alert'
                    },
                    {
                      icon: 'activities',
                      classType: 'nav-item',
                      title: 'Checkers Queue',
                      href: '/checkers-queue',
                      show:RoleUtil.authorizePrivilegeSingle('CHECKER'),
                      id:'checkers'
                    },
                    {
                      icon: 'activities',
                      classType: 'nav-item',
                      title: 'Makers Queue',
                      href: '/makers-queue',
                      show:true,
                      id:'makers'
                    },
                    // {
                    //     icon: 'file-text',
                    //     classType: 'nav-item',
                    //     title: 'Report Engine',
                    //     href: '#',
                    //     show:true,
                    //     id:'report'
                    // }
                    {
                        header: true,
                        classType: 'nav-label mt-3',
                        title: 'Settings',
                        show:RoleUtil.authorizeModule('SYSTEM_SETTINGS'),
                    },
                    {
                        icon: 'file-text',
                        classType: 'nav-item',
                        title: 'System Settings',
                        href: '/system-settings',
                        show:RoleUtil.authorizeModule('SYSTEM_SETTINGS'),
                        id:'SystemSettings'
                    }
                ],
                imtoMenu: [
                    {
                        header: true,
                        classType: 'nav-label mt-3',
                        show:true,
                        title: 'sidebar.dashboard'
                    },
                    {
                        icon: 'dashboard',
                        classType: 'nav-item',
                        title: 'Dashboard',
                        href: '/dashboard',
                        show:true,
                        id:'v-step-0'
                    },
                    {
                      icon: 'shopping-bag',
                      classType: 'nav-item',
                      title: 'Analytics',
                      href: '/gateway',
                      show:true,
                      id:'v-step-0'
                    },
                    {
                      header: true,
                      classType: 'nav-label mt-3',
                      show:true,
                      title: 'Accounts'
                    },
                    {
                      icon: 'shopping-bag',
                      classType: 'nav-item',
                      title: 'Account Balances',
                      href: '/account-balances',
                      show:true,
                      id:'v-step-0'
                    },
                    {
                      header: true,
                      classType: 'nav-label mt-3',
                      show:true,
                      title: 'Transaction'
                    },
                    {
                        icon: 'shopping-bag',
                        classType: 'nav-item',
                        title: 'Direct Credits',
                        href: '/inbound-transactions',
                        show:true,
                        id:'v-step-0'
                    },
                    {
                        icon: 'shopping-bag',
                        classType: 'nav-item',
                        title: 'Cash Pickups',
                        href: '/outbound-transactions',
                        show:true,
                        id:'v-step-0'
                    },
                    {
                      header: true,
                      classType: 'nav-label mt-3',
                      show:true,
                      title: 'User Management'
                    },
                    {
                        icon: 'users',
                        classType: 'nav-item',
                        title: 'Users',
                        href: '/imto-users',
                        show:true,
                        id:'users'
                    },
                    // {
                    //     icon: 'users',
                    //     classType: 'nav-item',
                    //     title: 'Audit Log',
                    //     href: '/audit-trails',
                    //     show:true,
                    //     id:'audit'
                    // },
                    {
                      header: true,
                      classType: 'nav-label mt-3',
                      show:true,
                      title: 'Sandbox'
                    },
                    {
                      icon: 'activities',
                      classType: 'nav-item',
                      title: 'Api Keys',
                      href: '/api-keys',
                      show:true,
                      id:'users'
                    },
                ]
            }
        },
        methods:{
            logout(){
                this.$store.dispatch('auth/logout',{
                  username: this.auth.userInfo.username,
                  userBankCode:'00000',
                  userCountryCode:'234',
                  source: 'WEB'
                }).then(()=>{
                  localStorage.removeItem('token');
                  window.location = window.__env.app.publicPath;
              })
            },

            officerFilteredByUserId(_item) {
                return this.officer.officers.filter(function (el) {
                    return el.officerUserID == _item;
                });
            },

            show: function(type){
                const app = $('.app-contact');
                type ===true ? app.addClass('show-aside') : app.removeClass('show-aside');
            }

        },
        computed:{
            ...mapState([
                'company',
                'officer',
                'auth'
            ]),
            admin:{
                get(){
                    let access = false;
                    if (this.auth.userInfo.role.toUpperCase() === "USER" ||
                        this.auth.userInfo.role.toUpperCase() === "DIRECTOR" ||
                        this.auth.userInfo.role.toUpperCase() === "SIGNATORY" ||
                        this.auth.userInfo.role.toUpperCase() === "DIRECTOR&SIGNATORY" ||
                        this.auth.userInfo.role.toUpperCase() === "DIRECTORANDSIGNATORY")
                        access = true;
                    return access;
                }
            },
            customerService:{
                get(){
                    let access = false;
                    if (this.auth.userInfo.role.toUpperCase() === "CUSTOMERSERVICE")
                        access = true
                    return access;
                }
            },
            developer:{
                get(){
                    let access = false;
                    if (this.auth.userInfo.role.toUpperCase() === "DEVELOPER")
                        access = true
                    return access;
                }
            },
            menu:{
                get(){
                    if (this.auth.userInfo.userType === 'IMTO') {
                        return this.imtoMenu
                    } else {
                        return this.onBoardingMenu
                    }
                }
            },
        },

        mounted() {
            const psSidebar = new PerfectScrollBar('.aside-body', {
                suppressScrollX: true
            });
            psSidebar.isAlive = true
            //this.$tours['myTour'].start()
        }
    }
</script>

<style scoped>

</style>