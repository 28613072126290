<template>
    <div>
        <form v-if="auth.loginScreen === 'login'" @submit.prevent="login()">
            <div class="row">
                <div class="col-12">
                    <h6>Login</h6>
                    <div class="form-group">
                        <div class="input-group">
                            <input id="email" v-model="model.username" type="text" class="form-input" required/>
                            <label class="input-label" for="email">
                                <span class="label-span">{{ $t('auth.username')}}</span>
                            </label>
                        </div>
                        <p v-if="!model.username" class="input-error">{{ $t('auth.usernameIsRequired')}}</p>
                    </div>
                </div>

                <div class="col-12">
                    <div class="form-group">
                      <span style="display: flex">
                        <div class="input-group">
                            <input id="password" style="" v-model="model.userPassword" :type="inputType" class="form-input"  required/>
                          <label class="input-label" style="border-top-right-radius: 0;border-bottom-right-radius: 0;" for="password">
                            <span class="label-span">{{ $t('auth.password')}}</span>
                          </label>
                        </div>
                        <b-input-group-text style="border-top-left-radius: 0;border-bottom-left-radius: 0; cursor: pointer; height: 50px">
                          <b-icon-eye v-if="inputType === 'password'" @click="inputType = 'text'" />
                          <b-icon-eye-slash v-else @click="inputType = 'password'" />
                        </b-input-group-text>
                      </span>
                    </div>
                </div>

            </div>
            <div class="row">
                <div class="col-12">
                    <auth-base-button :title="$t('auth.login')" :loading="auth.authLoading"></auth-base-button>
                </div>
            </div>
        </form>
        <form v-if="auth.loginScreen === 'otp'" @submit.prevent="changePassword()">
            <div class="row">
                <div class="col-12">
                    <h6><span style="cursor: pointer; font-size: 20px;" @click="$store.commit('auth/updateLoginScreen','login')"><strong><b-icon-arrow-left/></strong></span> Change Password</h6>
                    <div class="form-group">
                      <span style="display: flex">
                        <div class="input-group">
                            <input id="userOldPassword" autocomplete="new-password" v-model="model.userOldPassword" :type="inputType1" class="form-input" minlength="6" maxlength="13" required/>
                            <label class="input-label" style="border-top-right-radius: 0;border-bottom-right-radius: 0;" for="userOldPassword">
                                <span class="label-span">Old Password</span>
                            </label>
                        </div>
                        <b-input-group-text style="border-top-left-radius: 0;border-bottom-left-radius: 0; cursor: pointer; height: 50px">
                          <b-icon-eye v-if="inputType1 === 'password'" @click="inputType1 = 'text'" />
                          <b-icon-eye-slash v-else @click="inputType1 = 'password'" />
                        </b-input-group-text>
                      </span>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                      <span style="display: flex">
                        <div class="input-group">
                            <input id="newPassword" autocomplete="new-password" v-model="model.userPassword" :type="inputType2" class="form-input" minlength="6" maxlength="13" required/>
                            <label class="input-label" style="border-top-right-radius: 0;border-bottom-right-radius: 0;" for="newPassword">
                                <span class="label-span">New Password</span>
                            </label>
                        </div>
                        <b-input-group-text style="border-top-left-radius: 0;border-bottom-left-radius: 0; cursor: pointer; height: 50px">
                          <b-icon-eye v-if="inputType2 === 'password'" @click="inputType2 = 'text'" />
                          <b-icon-eye-slash v-else @click="inputType2 = 'password'" />
                        </b-input-group-text>
                      </span>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                      <span style="display: flex">
                        <div class="input-group">
                            <input id="userPasswordConfirmation" v-model="model.userPasswordConfirmation" :type="inputType3" class="form-input" minlength="6" maxlength="13" required/>
                            <label class="input-label" style="border-top-right-radius: 0;border-bottom-right-radius: 0;" for="userPasswordConfirmation">
                                <span class="label-span">Password Confirmation</span>
                            </label>
                        </div>
                        <b-input-group-text style="border-top-left-radius: 0;border-bottom-left-radius: 0; cursor: pointer; height: 50px">
                          <b-icon-eye v-if="inputType3 === 'password'" @click="inputType3 = 'text'" />
                          <b-icon-eye-slash v-else @click="inputType3 = 'password'" />
                        </b-input-group-text>
                      </span>
                    </div>
                </div>
              <div class="col-md-12">
                <div class="form-group">
                  <h6 class="h6">Password Requirement:</h6>
                  <ul >
                    <li :style="'color:'+minLength">Minimum length: 8</li>
                    <li :style="'color:'+maxLength">Maximum length: 13</li>
                    <li :style="'color:'+hasLettersNumbers">Must contain letters and numbers</li>
                    <li :style="'color:'+hasLowerCase">Must contain a lowercase letter</li>
                    <li :style="'color:'+hasUpperCase">Must contain an uppercase letter</li>
                    <li :style="'color:'+hasSpecialXters">Must contain special characters</li>
                    <li :style="'color:'+hasSpace">Must not contain white space</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <auth-base-button title="Change Password" :loading="auth.authLoading"></auth-base-button>
                </div>
            </div>
        </form>
      <confirm-modal :show="showConfirmModal" :row="{}"/>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import AuthBaseButton from "../button/AuthBaseButton";
    import ConfirmModal from "@/components/modal/ConfirmModal";
    export default {
        name: "LogonForm",
        components: {
            AuthBaseButton,
            ConfirmModal,
        },
        data() {
            return {
                showConfirmModal: false,
                inputType: "password",
                inputType1: "password",
                inputType2: "password",
                inputType3: "password",
                model: {
                    username: "",
                    password: "",
                    userPassword: "",
                    userPasswordConfirmation: "",
                    userOldPassword: "",
                    ipAddress: "10.0.0.11",
                    source: "MOBILE",
                    longitude: "6.2222888",
                    latitude: "3.98000",
                    deviceId: "233333098988",
                    devicePlatform: "Android",
                    deviceName: "Infinix",
                    appVersion: "10.0"
                },
            }
        },
        methods: {
            clicked(){
                this.$swal({
                    title: 'Custom animation with Animate.css',
                    showClass: {
                        popup: 'animated slideInLeft faster display-6'
                    },
                    hideClass: {
                        popup: 'animated slideOutRight faster display-6'
                    },
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, delete it!'
                })
            },
            async login() {
                localStorage.removeItem('token');
                this.model.userPassword = btoa(this.model.userPassword)
                await this.$store.dispatch("auth/logon", this.model, { root: false })
                // console.log(this.auth.userInfo)
                if (this.auth.userInfo.responseCode === "00") {
                  if (this.auth.userInfo.username.includes('@'))
                    window.location = window.__env.app.publicPath+'preloader';
                  else
                    this.showConfirmModal = !this.showConfirmModal
                }
                this.model.password = ''
                this.model.userPassword = ''
                this.model.userPasswordConfirmation = ''
                this.model.userOldPassword = ''
            },
            async changePassword() {
                if(this.minLength !== 'green'||
                    this.maxLength  !== 'green'||
                    this. hasLettersNumbers !== 'green' ||
                    this.hasSpecialXters !== 'green' ||
                    this.hasLowerCase !== 'green' ||
                    this.hasUpperCase !== 'green' ||
                    this.hasSpace !== 'green'
                ) return;
                localStorage.clear()
                await this.$store.dispatch("auth/changePassword", this.model, { root: false })
                this.$store.commit('auth/updateLoginScreen','login')
            },
            async passwordReset1() {
                await this.$store.dispatch("auth/initiateResetPassword", this.model, { root: false })
            },
            async passwordReset2() {
                await this.$store.dispatch("auth/verifyToken", this.model, { root: false })
            },
            async passwordReset3() {
                await this.$store.dispatch("auth/completeResetPassword", this.model, { root: false })
            },
            isNumberKey(evt){
              let charCode = (evt.which) ? evt.which : event.keyCode
              return !(charCode > 31 && (charCode < 48 || charCode > 57));

            },
            toScreenTwo(){
              if (this.model.firstName && this.model.lastName)
                this.screen = 'stepTwo'
            },
            toScreenThree(){
              if (this.model.email && this.model.phone)
                this.screen = 'stepThree'
            },
        },
        computed:{
            ...mapState([
                'company',
                'document',
                'auth',
                'auth',
                'officer',
            ]),
          minLength:function(){
            return this.model.userPassword.length >= 8?'green':'red'
          },
          maxLength:function(){
            return this.model.userPassword.length <= 13?'green':'red'
          },
          hasLettersNumbers:function(){
            return ( /[a-zA-Z]/g.test(this.model.userPassword) && /[0-9]/g.test(this.model.userPassword))?'green':'red'
          },
          hasSpecialXters:function(){
            return /[^a-zA-Z0-9]/g.test(this.model.userPassword)?'green':'red'
          },
          hasLowerCase:function(){
            return /[a-z]/g.test(this.model.userPassword)?'green':'red'
          },
          hasUpperCase:function(){
            return /[A-Z]/g.test(this.model.userPassword)?'green':'red'
          },
          hasSpace:function(){
            return !(/\s/g.test(this.model.userPassword))?'green':'red'
          },

        },

        watch: {
            'auth': function() {
                console.log('Hello World!')
            }
        },
        mounted() {
            this.$store.commit('auth/updateLoginScreen','login')
        },
    }
</script>

<style scoped>

</style>
