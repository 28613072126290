<template>
    <div class="row row-xs">
        <div class="col-sm-6 col-lg-3" v-for="card in carditems" :key="card.id">
            <div :class="['card',  'card-body', card.color]">
                <h6 class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-white tx-semibold mg-b-8">{{ card.name }}</h6>
                <div class="d-flex d-lg-block d-xl-flex align-items-end">
                    <h3 class="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1 tx-white">{{ card.number }}</h3>
                    <!--                    <p class="tx-11 tx-color-03 mg-b-0"><span class="tx-medium tx-white">1.2% <i class="icon ion-md-arrow-up"></i></span></p>-->
                </div>
                <div class="chart-three">
<!--                    <div id="flotChart3" class="flot-chart ht-30"></div>-->
                </div><!-- chart-three -->
            </div>
        </div><!-- col -->
    </div>
</template>

<script>
    export default {
        props: ['carditems'],
        name: "NativeCard"
    }
</script>

<style scoped>

</style>
