<template>
  <div class="row">
    <div class="col-md-12 mg-t-10">
      <div class="card ht-100p">
        <div class="card-header d-flex align-items-center justify-content-between">
          <h6 class="mg-b-0">Create Report</h6>
          <div class="d-flex tx-18"></div>
        </div>
        <form  v-if="screen ===  'one'" class="p-xl-2" style="padding: 10px;"  @submit.prevent="next('two')">
          <strong>1. Set up Report Conditions</strong><br><br>
          <p style="line-height: 0;">Decide the conditions for the columns of the Report.</p><br>
          <p style="line-height: 0;"><code>{{queriesMain}}</code></p><br>
          <p style="text-align: center;"><code v-if="report.columnsLoading">Columns Loading...</code></p>
          <div v-for="(query, index) in queries" v-bind:key="index" class="form-row">
            <div class="col-md-3">
              <div class="form-group">
                <label>Column Name:</label>
                <multiselect
                    v-model="query.columnName"
                    :options="options"
                    placeholder="Column Name"
                    required
                    @change="formatCondition()"
                ></multiselect>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Query Type:</label>
                <multiselect
                    v-model="query.queryType  "
                    :options="options2"
                    placeholder="Query Type"
                    required
                    @change="formatCondition()"
                ></multiselect>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Keyword:</label>
                <b-input type="text" v-model="query.keyword" @change="formatCondition()"/>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="join">Join Type</label>
                <b-select
                    id="join"
                    :type="'text'"
                    v-model="query.join"
                    :label="'JOIN'"
                    :placeholder="'JOIN'"
                    required
                    class="form-control"
                    @change="addMoreQuery(query.join, index)"
                >
                  <option value="NONE">NONE</option>
                  <option value="OR">OR</option>
                  <option value="AND">AND</option>
                </b-select>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-2">
              <!--                            <span style="width: 100%; cursor: pointer" @click="next(model.reportType==='join'?'four':'two')" class="btn btn-secondary btn-">Back</span>-->
            </div>
            <div class="col-md-8"></div>
            <div class="col-md-2">
              <base-button title="Next" :loading="report.reportsLoading"/>
            </div>
          </div>
        </form>
        <form  v-if="screen ===  'two'" class="p-xl-2" style="padding: 10px;"  @submit.prevent="queryReport()">
          <strong>6.  Set up Report Columns</strong><br><br>
          <p style="line-height: 0;">Decide the columns from the <code>Start Table</code> and <code>Join Table</code> that will feature in the Report.</p><br>
          <div class="form-row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Columns:</label>
                <br>
                <b-form-checkbox v-model="selectAll" switch size="lg">Select All Columns</b-form-checkbox>
                <br>
                <span v-if="!selectAll">
                                    <b-form-checkbox-group
                                        v-model="selectedColumns"
                                        :options="options"
                                        value-field="item"
                                        text-field="name"
                                        disabled-field="notEnabled"
                                    ></b-form-checkbox-group>
                                </span>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-2">
              <span style="width: 100%; cursor: pointer" @click="next('one')" class="btn btn-secondary btn-">Back</span>
            </div>
            <div class="col-md-8"></div>
            <div class="col-md-2">
              <base-button title="Query Report" :loading="report.reportsLoading"/>
            </div>
          </div>
        </form>
      </div><!-- card -->
    </div>
  </div><!-- row -->
</template>

<script>
// import FlotChart from "../FlotChart";
import {mapState} from 'vuex'
import BaseButton from "../button/BaseButton";
export default {
  name: "TopCards",
  components:{
    BaseButton
  },
  data(){
    return{
      screen: 'one',
      options: [],
      selectAll: false,
      selectedColumns:[],
      options2: ["LIKE", "=", "<>", ">", "<", "<=", ">="],
      options3: ["NONE", "AND", "OR"],
      queries: [{ columnName: "", queryType: "", keyword: "", join: "" }],
      queriesMain: "",
      model:{
        reportBankCode: "000000",
        reportName:"",
        reportFieldList:"",
        reportCondition:"",
        readCount:"ALL"
      },
      limitEdit: false,
      passwordEdit: false,
      accountEdit: false,
    }
  },
  watch:{
    model: function () {
      console.log("hery")
      this.filterOutBadChar()
    }
  },
  methods:{
    async queryReport(){
      console.log(this.selectedColumns.join(','))
      if (this.selectAll) this.model.reportFieldList = '';
      else this.model.reportFieldList = this.selectedColumns.join(',');
      this.model.reportCondition = this.queriesMain.trim();
      if (this.model.reportCondition === 'null  \'\'') this.model.reportCondition = ''
      if (this.model.reportCondition === 'null null \'\'') this.model.reportCondition = ''
      if (this.model.reportCondition === '\'\'') this.model.reportCondition = ''
      this.model.reportName = localStorage.reportName
      await this.$store.dispatch("report/updateReportSingle", this.model, {root: true})
      this.loadFields(this.report.reportSingle)
      //console.log(this.model)
    },
    addMoreQuery(_item, _index) {
      //this.formatCondition()
      let index = _index + 1;
      console.log("currentIndex", _index);
      console.log("nextIndex", index);
      console.log("length", this.queries.length);
      if (_item !== "NONE") {
        if (this.queries.length <= index) {
          this.queries.push({
            columnName: "",
            queryType: "",
            keyword: "",
            join: ""
          });
        }
      } else {
        this.queries.splice(index, this.queries.length - index);
      }
      this.formatCondition();
    },
    formatCondition() {
      let query = "";
      // eslint-disable-next-line no-unused-vars
      let join = "";

      for (let i = 0; i < this.queries.length; i++) {
        if (this.queries[i].queryType === "LIKE") {
          if (this.queries[i].join === "NONE") join = "";
          else join = this.queries[i].join;
          query =
              query +
              this.queries[i].columnName +
              " " +
              this.queries[i].queryType +
              " '%" +
              this.queries[i].keyword +
              "%' " +
              join +
              " ";
          console.log("looping>>>>", query);
        } else {
          if (this.queries[i].join === "NONE") join = "";
          else join = this.queries[i].join;
          query =
              query +
              this.queries[i].columnName +
              " " +
              this.queries[i].queryType +
              " '" +
              this.queries[i].keyword +
              "' " +
              join +
              " ";
          // console.log("looping>>>>",query)
        }
      }
      this.queriesMain = query;
      // console.log("statements>>>>>>",query)
      // console.log("queries>>>>>>>>>",this.queries)
    },
    async next(_screen){
      console.log(_screen)
      this.screen = _screen;
      if (this.screen === 'four' || this.screen === 'five' || this.screen === 'six'){
        await this.$store.dispatch('report/updateColumns1',{
          reportBankCode:"000000",
          reportName: this.model.reportName
        })
        let columns = []
        let options = []
        columns = this.report.columns1.columns
        for (let i = 0; i < columns.length; i++) options[i] = columns[i].columnName
        this.options = options
        console.log("columns>>>>>>>>>",this.options)
      }
    },
    filterOutBadChar(){
      if (this.model.orgAuthorizationLevel.substr(0,1) === '0')
        this.model.orgAuthorizationLevel =this.model.orgAuthorizationLevel.substr(1,this.model.orgAuthorizationLevel.length)
      this.model.orgAuthorizationLevel = this.model.orgAuthorizationLevel.replace('.', '')
      if (this.model.orgAuthorizationLevel === '') this.model.orgAuthorizationLevel = 0;
      console.log(this.model.orgAuthorizationLevel)
    },
    async saveLimit(){
      this.model.orgId = localStorage.orgID;
      await this.$store.dispatch("company/setCompanyLimit", this.model, {root: true})
      this.limitEdit = !this.limitEdit
    },
    async savePassword(){
      if(this.minLength !== 'green'||
          this.maxLength  !== 'green'||
          this. hasLettersNumbers !== 'green' ||
          this.hasSpecialXters !== 'green' ||
          this.hasLowerCase !== 'green' ||
          this.hasUpperCase !== 'green' ||
          this.hasSpace !== 'green'
      ) return;
      this.model.orgId = localStorage.orgID;
      this.model.userId = this.system.userInfo.id;
      await  this.$store.dispatch("auth/changePassword", this.model, {root: true})
      this.model.oldPassword =  '';
      this.model.password =  '';
      this.model.repeatPassword =  '';
      this.passwordEdit = !this.passwordEdit
    },
    async saveAccounts(){
      this.model.orgId = localStorage.orgID;
      await this.$store.dispatch("account/saveAccounts", this.model, {root: true})
      this.accountEdit = !this.accountEdit
    },
    loadFields(_json){
      let val = _json[0];
      let fields = []
      for(let j in val){
        let sub_key = j;
        // eslint-disable-next-line no-unused-vars
        let sub_val = val[j];
        fields.unshift({key: sub_key, label: sub_key, sortable: true, class: 'text-left'})
        console.log(sub_key);
      }
      this.$store.commit('report/updateFields', this.fields)
    }
  },
  computed:{
    ...mapState([
      'report',
      'system',
      'company',
      'account',
      'auth',
      'officer'
    ]),
    minLength:function(){
      return this.model.password.length >= 8?'green':'red'
    },
    maxLength:function(){
      return this.model.password.length <= 13?'green':'red'
    },
    hasLettersNumbers:function(){
      return ( /[a-zA-Z]/g.test(this.model.password) && /[0-9]/g.test(this.model.password))?'green':'red'
    },
    hasSpecialXters:function(){
      return /[^a-zA-Z0-9]/g.test(this.model.password)?'green':'red'
    },
    hasLowerCase:function(){
      return /[a-z]/g.test(this.model.password)?'green':'red'
    },
    hasUpperCase:function(){
      return /[A-Z]/g.test(this.model.password)?'green':'red'
    },
    hasSpace:function(){
      return !(/\s/g.test(this.model.password))?'green':'red'
    },
    accounts: function () {
      console.log(this.account.allAccounts.data);
      let acc = [];
      if (this.account.allAccounts.data != null)
        for (let i = 0; i < this.account.allAccounts.data.length; i++){
          acc.push(this.account.allAccounts.data[i].accountNumber)
        }
      return acc
    },
    modelAccounts:{
      get(){
        console.log(this.model.accounts);
        return this.model.accounts
      },
      set(val){
        this.model.accounts = val
      }
    }
  },
  async mounted() {
    this.model.reportName = localStorage.reportName
    await this.$store.dispatch('report/updateColumns1',{
      reportBankCode:"000000",
      reportName: this.model.reportName
    })
    let columns = [];
    let options = [];
    columns = this.report.columns1.columns
    for (let i = 0; i < columns.length; i++) options[i] = columns[i].columnName
    this.options = options
    console.log("columns>>>>>>>>>",this.options)
  }
}
</script>

<style scoped>
@media only screen and (min-width: 900px) {
  .top-card {
    width: 103%;
  }
}
@media only screen and (min-width: 707px) and (max-width: 900px) {
  .top-card {
    width: 104%;
  }
}

@media only screen and (min-width: 517px)  and (max-width: 707px) {
  .top-card {
    width: 105%;
  }
}

@media only screen and (min-width: 451px)  and (max-width: 517px) {
  .top-card {
    width: 106%;
  }
}

@media only screen  and (max-width: 451px) {
  .top-card {
    width: 107%;
  }
}
</style>