<template>
  <b-modal size="lg" id="review-modal" :hide-header="true" :hide-footer="true" :centered="true" ref="review-modal">
    <div class="modal-body">
      <a href="#" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"
         @click="hide()">
        <span aria-hidden="true">&times;</span>
      </a>
      <div>
        <p class="tx-color-03">Review Screen</p>
        <b-img src="https://veezahrepo.s3.amazonaws.com/profile-default.png" width="100px" rounded="circle" alt="Circle image"></b-img>
        <p>Name: {{this.row.transactionReceiverFirstName}} {{this.row.transactionReceiverMiddleName}} {{this.row.transactionReceiverLastName}}</p>
        <br>
        <span v-if="row.transactionStatus === 'PENDING' || row.transactionStatus === 'FAILED'">
          <b-button @click="updateStatus('PASSED')" :disabled="transaction.transactionLoading?transaction.transactionLoading:cashPickup.cashPickupLoading" variant="success"><b-icon-check/> Pass Transaction</b-button> &nbsp; <b-button @click="updateStatus('FAILED')" :disabled="transaction.transactionLoading?transaction.transactionLoading:cashPickup.cashPickupLoading || row.transactionStatus === 'FAILED'" variant="danger"><b-icon-x-circle/> Fail Transaction</b-button>
          <br><br>
        </span>
        <br>
        <b-tabs content-class="mt-3" fill>
          <b-tab title="Transaction History" active>
            <sub-base-table
                :items="transactions()"
                :fields="historyFields"
                :show-title="true"
                :is-busy="transaction.transactionLoading?transaction.transactionLoading:cashPickup.cashPickupLoading"
                class="mt-2"
                filter-mode="balances"
            ></sub-base-table>
          </b-tab>
          <b-tab title="Screening Logs">
            <sub-base-table
                :items="screening.transactionScreeningLogs.data"
                :fields="logFields"
                :show-title="true"
                :is-busy="screening.screeningLoading"
                class="mt-2"
                filter-mode="balances"
            ></sub-base-table>
          </b-tab>
        </b-tabs>
      </div>
    </div><!-- modal-body -->
  </b-modal>
</template>
<script>
import { mapState } from "vuex"
import SubBaseTable from "@/components/table/SubBaseTable";
// import BaseButton from "../button/BaseButton";
export default {
  props:['show','row','type'],
  components:{SubBaseTable},
  name: 'ConfirmModal',
  data(){
    return{
      password: '',
      model:{
        contractCreditAccountNumber:'',
        contractDebitAccountNumber:''
      },
      historyFields: [
        { key: 'serial', label: 'S/N', sortable: true, sortDirection: 'desc' },
        { key: 'transactionMtoCode', label: 'MTO Code', sortable: true, sortDirection: 'desc' },
        { key: 'transactionContractReference', label: 'Trn Ref', sortable: true, class: 'text-left' },
        // { key: 'transactionSourceCountryCurrency', label: 'Source CCY', sortable: true, class: 'text-right' },
        // { key: 'transactionCurrency', label: 'S. CCY', sortable: true, class: 'text-right' },
        { key: 'transactionAmount', label: 'S. Amount', sortable: true, class: 'text-right' },
        { key: 'transactionDestinationAmount', label: 'D. Amount', sortable: true, class: 'text-right' },
        // { key: 'transactionSourceCountryCurrency', label: 'S. CCY', sortable: true, class: 'text-left' },
        { key: 'transactionStatus', label: 'Status', sortable: true, class: 'text-left' },
        { key: 'actions', label: 'Action', sortable: true, class: 'text-center' }
      ],
      logFields: [
        { key: 'serial', label: 'S/N', sortable: true, sortDirection: 'desc' },
        { key: 'screeningLogScreeningCode', label: 'Code', sortable: true, sortDirection: 'desc' },
        { key: 'screeningLogScreeningType', label: 'Type', sortable: true, class: 'text-left' },
        { key: 'screeningLogStatus', label: 'Status', sortable: true, class: 'text-left' },
        { key: 'screeningLogCreatedAt', label: 'Logged At', sortable: true, class: 'text-left' },
        { key: 'actions', label: 'Action', sortable: true, class: 'text-center' }
      ],

    }
  },
  methods:{
    hide() {
      this.$refs['review-modal'].hide()
    },
    hideauth: function () {
      this.password = '';
      this.$refs['review-modal'].hide();
    },
    currentDate(){
      let date = new Date()
      date = this.$moment(date.toDateString()).format('YYYY-MM-DD')
      console.log(date)
      return date
    },
    transactions(){
      if (this.type === 'CP')
        return this.cashPickup.history.data
      else
        return this.transaction.dcHistory.data
    },
    async updateStatus(_status){
      if (this.row.contractType === 'CP'){
        await this.$store.dispatch('transaction/updateStatusCpTransaction',{
          transactionReference: this.row.transactionReference,
          transactionStatus: _status,
          transactionMaker: this.auth.userInfo.userId
        })
      }else{
        await this.$store.dispatch('transaction/updateStatusDcTransaction',{
          transactionReference: this.row.transactionReference,
          transactionStatus: _status,
          transactionMaker: this.auth.userInfo.userId
        })
      }
      this.loadTransaction()
    },
    loadTransaction(){
      this.$store.dispatch("transaction/updateDcTransactions", {requestType:'transactions',transactionMtoCode:this.auth.userInfo.userMtoCode}, {root: false}).then()
      this.$store.dispatch("transaction/updateCpTransactions", {requestType:'transactions',transactionMtoCode:this.auth.userInfo.userMtoCode}, {root: false}).then()
    },
    loadLogs(_transactionId, _screeningType){
      this.$store.dispatch('screening/updateTransactionScreeningLogs',{
        transactionId: _transactionId,
        screeningType: _screeningType
      })
    },
    loadHistory(_receiver, _type){
      if (_type === 'CP')
        this.$store.dispatch('cashPickup/updateHistory',{
          receiver: _receiver,
          requestType: 'history'
        })
      else
        this.$store.dispatch('transaction/updateDcHistory',{
          receiver: _receiver,
          requestType: 'history'
        })
    }
  },

  computed:{
    ...mapState(['cashPickup','system','transaction','screening','auth']),
  },
  watch: {
    show: function () {
      //load transaction history
      this.loadHistory(this.row.transactionReceiverEmailAddress?this.row.transactionReceiverEmailAddress:this.row.transactionReceiverPhoneNumber, this.type)
      //load screening logs
      console.log(this.row)
      this.loadLogs(this.row.transactionId, this.type)
      this.$refs['review-modal'].show()
    }
  },
}
</script>
