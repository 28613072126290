import apiClient from "./BaseService";

export default {
  callReadCheckerQueueApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgID;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.post("checker/read", payload);
  },
  callAuthorizeCheckerQueueApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgID;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.post("checker/authorize", payload);
  }
};
