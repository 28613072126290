<template>
    <div>
        <auth-header></auth-header>
        <router-view></router-view>
    </div>
</template>

<script>
    import AuthHeader from "../../../components/menu/AuthHeader";
    import {mapState} from "vuex";
    export default {
        components:{
            AuthHeader
        },
        computed:{...mapState(['auth','system'])}
    }
</script>
