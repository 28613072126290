<template>
    <div class="content ht-100v pd-0">
        <div class="content-body pd-10">
            <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                <div>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                            <li class="breadcrumb-item">
                                <router-link to="#">Role</router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">All Roles</li>
                        </ol>
                    </nav>
                    <h4 class="mg-b-0 tx-spacing--1">View All Roles</h4>
                    <p class="tx-color-03 tx-12 mg-b-0">{{auth.userInfo.userFirstName}} {{auth.userInfo.userLastName}}</p>
                    <p class="tx-color-03 tx-12 mg-b-0"><strong>Last login:</strong> {{auth.userInfo.userLastLogin | moment('dddd, MMMM Do YYYY, h:mm:ss a')}}</p>
                </div>
            </div>
            <hr>
            <create-role-form :type="$route.params.roleId?'edit':'create'" :row="{roleId: $route.params.roleId}" />
        </div>
    </div>
</template>

<script>
    import CreateRoleForm from "@/components/form/CreateRoleForm";
    import {mapState} from 'vuex'

    export default {
        name: 'Role',
        data() {
            return {
                fields: [
                    { key: 'serial', label: 'S/N', sortable: true, sortDirection: 'desc' },
                    { key: 'roleFirstName', label: 'First Name', sortable: true, sortDirection: 'desc' },
                    { key: 'roleLastName', label: 'Last Name', sortable: true, class: 'text-left' },
                    { key: 'roleEmail', label: 'Email', sortable: true, class: 'text-left' },
                    { key: 'roleRole', label: 'Role', sortable: true, class: 'text-left' },
                    { key: 'roleStatus', label: 'Status', sortable: true, class: 'text-left' },
                    // { key: 'roleLastLogin', label: 'Last Login Date', sortable: true, sortDirection: 'desc' },
                    { key: 'roleCreatedAt', label: 'Enrollment Date', sortable: true, class: 'text-left' },
                    { key: 'actionsRole', label: 'Action', sortable: true, class: 'text-center' }
                ],
                model:{
                    roleFirstName:'',
                    roleLastName:'',
                    roleEmail:'',
                    roleRole:''
                }
            }
        },
        components: {
          CreateRoleForm
        },
        computed: {
            cards:{
                get(){
                    return [
                        {
                            id: 0,
                            name: 'Total Roles',
                            number: this.role.roles.totalRoles,// == null?'0.00':'GHS '+this.numberWithCommas(this.totalGhs),
                            color: 'bg-primary'
                        },
                        {
                            id: 1,
                            name: 'Total Pending Roles',
                            number: this.role.roles.totalPendingRoles,// == null?'0.00':'GBP '+this.numberWithCommas(this.totalGbp),
                            color: 'bg-orange'
                        },
                        {
                            id: 2,
                            name: 'Total Active Roles',
                            number: this.role.roles.totalActiveRoles,// == null?'0.00':'USD '+this.numberWithCommas(this.totalUsd),
                            color: 'bg-success'
                        },
                        {
                            id: 3,
                            name: 'Total Disabled Roles',
                            number: this.role.roles.totalDisabledRoles,// == null?'0.00':'EUR '+this.numberWithCommas(this.totalEur),
                            color: 'bg-black-8'
                        }
                    ]
                },
                set(){

                }
            },
            roles(){
                return  this.role.roles.data
            },
            authobj: function () {
                return this.$store.state.role.response
            },
            ...mapState([
                'auth',
                'role'
            ])
        },
        methods: {
            hideModal(){
                this.$refs['new-role-modal'].hide();
            },
            createRole: async function () {
                await this.$store.dispatch("auth/initiateEnrollment", this.model, {root: true}).then();
                this.$refs['new-role-modal'].hide();
                await this.$store.dispatch("auth/updateRoles", {
                    request: 'roles'
                }, {root: true}).then();
            },
            numberWithCommas(x) {
                let parts = x.toString().split(".");
                if (parts[1] == null)  parts[1] = '00'
                else if (parts[1].length === 0) parts[1] = parts[1]+'00'
                else if (parts[1].length === 1) parts[1] = parts[1]+'0'
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            },
        },
        created() {

            this.$store.dispatch("role/updateRoles", {readAll: 'YES',source: 'WEB'}, {root: false}).then()
        }
    }


</script>

<style scoped>

</style>
