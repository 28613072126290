import MtoService from "../../service/MtoService";
import swal from 'sweetalert'
//import router from "../../router";



export const state = {
    parameters:{
        responseCode: "",
        responseMessage: "",
        data: []
    },
    apiKeys:{
        responseCode: "",
        responseMessage: ""
    },
    apiKeyLoading: false,
    parameterLoading: false,
    response:{}

}

export const mutations = {
    updateParameter: (state, payload) =>{
        state.parameters = payload
    },
    updateParameterLoading: (state, payload) =>{
        state.parameterLoading = payload
    },
    updateApiKeys: (state, payload) =>{
        state.apiKeys = payload
    },
    updateApiKeyLoading: (state, payload) =>{
        state.apiKeyLoading = payload
    },
    updateResponse: (state, payload) =>{
        state.response = payload
    },
}

export const actions = {
    updateParameters: ({ commit }, payload)=>{
        if(state.parameters.data.length < 1)
            commit("updateParameterLoading", true)
        return MtoService.callReadParameterApi(payload)
            .then(response => {
                commit("updateParameterLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updateParameterLoading", false)
                    let responseData = response.data;
                    commit("updateParameter", responseData)
                }

            })
            .catch(error => {
                commit("updateParameterLoading", false)
                alert(error)
                // throw error;
            });
    },

    updateApiKeys: ({ commit }, payload)=>{
        if(state.parameters.data.length < 1)
            commit("updateApiKeyLoading", true)
        return MtoService.callReadSingleApiKeyApi(payload)
            .then(response => {
                commit("updateApiKeyLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updateApiKeyLoading", false)
                    let responseData = response.data;
                    commit("updateApiKeys", responseData)
                }

            })
            .catch(error => {
                commit("updateApiKeyLoading", false)
                alert(error)
                // throw error;
            });
    },

    createApiKeys: ({ commit }, payload)=>{
        if(state.parameters.data.length < 1)
            commit("updateApiKeyLoading", true)
        return MtoService.callCreateApiKeyApi(payload)
            .then(response => {
                commit("updateApiKeyLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updateApiKeyLoading", false)
                    let responseData = response.data;
                    commit("updateApiKeys", responseData)
                }else{
                    swal('Error!', response.data.responseMessage, 'error')
                }

            })
            .catch(error => {
                commit("updateApiKeyLoading", false)
                alert(error)
                // throw error;
            });
    },

    createParameter: ({ commit }, payload)=>{
        commit("updateParameterLoading", true)
        return MtoService.callCreateParameterApi(payload)
            .then(response => {
                commit("updateParameterLoading", false)
                let responseData = response.data
                if (responseData.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success')
                }else{
                    swal('Error!', responseData.responseMessage, 'error')
                }

            })
            .catch(error => {
                commit("updateParameterLoading", false)
                alert(error)
                // throw error;
            });
    },

    editParameter: ({ commit }, payload)=>{
        commit("updateParameterLoading", true)
        return MtoService.callUpdateParameterApi(payload)
            .then(response => {
                commit("updateParameterLoading", false)
                let responseData = response.data
                if (responseData.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success')
                }else{
                    swal('Error!', responseData.responseMessage, 'error')
                }

            })
            .catch(error => {
                commit("updateParameterLoading", false)
                alert(error)
                // throw error;
            });
    },
}