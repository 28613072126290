<template>
  <div class="row">
    <div class="col-md-12 mg-t-10">
      <div class="card ht-100p">
        <div class="card-header d-flex align-items-center justify-content-between">
          <h6 class="mg-b-0">API Configuration - <span v-if="toggle">Live Mode</span><span v-else>Test Mode</span></h6>
          <div class="d-flex tx-18">
            <a href="" class="link-03 lh-0"><b-icon-arrow-repeat/></a>
            <a href="" class="link-03 lh-0 mg-l-10"><b-icon-three-dots/></a>
          </div>
        </div>
        <p v-if="mto.apiKeyLoading" style="margin-top: -20px;" align="center"><bar-loader  class="custom-class mt-3" color="#6d0606" loading="loading" style="width: 100%;" sizeUnit="px"></bar-loader></p>
        <form v-if="!mto.apiKeyLoading" class="p-10" style="padding: 10px;">
          <toggle-button
              v-model="toggle"
              :color="{checked: '#009f00', unchecked: '#960000', disabled: '#CCCCCC'}"
              :sync="true"
              :width="120"
              :font-size="12"
              :height="30"
              :labels="{checked: 'Live Keys', unchecked: 'Test Keys'}"/>
        </form>

        <form class="p-10" style="padding: 10px;">
          <div v-if="toggle && mto.apiKeys.apikeyLiveSK != null && mto.apiKeys.apikeyLiveSK !== '' && !mto.apiKeyLoading">
            <div class="form-row">
              <div class="col-md-12 ">
                <div class="position-relative form-group">
                  <b-form-group>
                    <b-row>
                      <b-col md="6">
                        <label for="liveSK">Live Secret Key</label>
                        <b-input-group>
                          <input type="text" readonly id="liveSK" v-model="mto.apiKeys.apikeyLiveSK" class="form-control" placeholder="Live secrete key" aria-label="" aria-describedby="button-addon11">
                          <b-input-group-append>
                            <button class="btn btn-outline-secondary" type="button" id="button-addon11"
                                    v-clipboard:copy="mto.apiKeys.apikeyLiveSK"
                                    v-clipboard:success="onCopy"
                                    v-clipboard:error="onError"
                            >Copy</button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-col>
                      <b-col md="6">
                        <label for="livePK">Live Public Key</label>
                        <div   class="input-group mb-3">
                          <input type="text" readonly id="livePK" v-model="mto.apiKeys.apikeyLivePK" class="form-control" placeholder="Live public key here" aria-label="Recipient's username" aria-describedby="button-addon22">
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary" type="button" id="button-addon22"
                                    v-clipboard:copy="mto.apiKeys.apikeyLivePK"
                                    v-clipboard:success="onCopy"
                                    v-clipboard:error="onError"
                            >Copy</button>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </div>
              </div>
            </div>
            <b-row>
<!--              <b-col md="6">-->
<!--                <div class="form-row">-->
<!--                  <div class="col-md-12 ">-->
<!--                    <div class="position-relative form-group">-->
<!--                      <label for="callback">Live Callback URL</label>-->
<!--                      <div   class="input-group mb-3">-->
<!--                        <input type="text" v-model="mto.apiKeys.apikeyLiveCallback" id="callback" class="form-control" placeholder="Live Callback URL">-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </b-col>-->
<!--              <b-col md="6">-->
<!--                <div class="form-row">-->
<!--                  <div class="col-md-12 ">-->
<!--                    <div class="position-relative form-group">-->
<!--                      <label for="webhook">Live Webhook URL</label>-->
<!--                      <div   class="input-group mb-3">-->
<!--                        <input type="text" v-model="mto.apiKeys.apikeyLiveWebhook" id="webhook" class="form-control" placeholder="Live Webhook URL">-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </b-col>-->
            </b-row>
            <p v-if="mto.apiKeyLoading" align="right"><bar-loader  class="custom-class" color="#6d0606" loading="loading" :size="150" sizeUnit="px"></bar-loader></p>
            <span v-else >
<!--                            <button @click="saveChangesLive()" type="button" style="float: right; box-shadow: none;" class="btn-shadow d-inline-flex align-items-center btn btn-danger button">-->
<!--                                Save Changes-->
<!--                            </button>-->
                            <span @click="createApiKey('prod')" style="cursor: pointer; float: right; "  class="new-key mt-1 mr-3">Generate new key <b-icon-arrow-repeat/></span>
                        </span>
          </div>
          <div v-if="!toggle && mto.apiKeys.apikeyTestSK != null && mto.apiKeys.apikeyTestSK !== '' && !mto.apiKeyLoading">
            <div class="form-row">
              <div class="col-md-12 ">
                <div class="position-relative form-group">
                  <b-form-group>
                    <b-row>
                      <b-col md="6">
                        <label for="liveSK">Test Secret Key</label>
                        <b-input-group>
                          <input type="text" readonly id="testSK" v-model="mto.apiKeys.apikeyTestSK" class="form-control" placeholder="Test secrete key her" aria-label="" aria-describedby="button-addon1">
                          <b-input-group-append>
                            <button class="btn btn-outline-secondary" type="button" id="button-addon1"
                                    v-clipboard:copy="mto.apiKeys.apikeyTestSK"
                                    v-clipboard:success="onCopy"
                                    v-clipboard:error="onError"
                            >Copy</button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-col>
                      <b-col md="6">
                        <label for="testPK">Test Public Key</label>
                        <div   class="input-group mb-3">
                          <input type="text" readonly id="testPK" v-model="mto.apiKeys.apikeyTestPK" class="form-control" placeholder="Test public key here" aria-label="Recipient's username" aria-describedby="button-addon2">
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary" type="button" id="button-addon2"
                                    v-clipboard:copy="mto.apiKeys.apikeyTestPK"
                                    v-clipboard:success="onCopy"
                                    v-clipboard:error="onError"
                            >Copy</button>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </div>
              </div>
            </div>
            <b-row>
<!--              <b-col md="6">-->
<!--                <div class="form-row">-->
<!--                  <div class="col-md-12 ">-->
<!--                    <div class="position-relative form-group">-->
<!--                      <label for="callbacktest">Test Callback URL</label>-->
<!--                      <div   class="input-group mb-3">-->
<!--                        <input type="text" v-model="mto.apiKeys.apikeyTestCallback" id="callbacktest" class="form-control" placeholder="Test Callback URL">-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </b-col>-->
<!--              <b-col md="6">-->
<!--                <div class="form-row">-->
<!--                  <div class="col-md-12 ">-->
<!--                    <div class="position-relative form-group">-->
<!--                      <label for="webhooktest">Test Webhook URL</label>-->
<!--                      <div   class="input-group mb-3">-->
<!--                        <input type="text" v-model="mto.apiKeys.apikeyTestWebhook" id="webhooktest" class="form-control" placeholder="Test Webhook URL">-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </b-col>-->
            </b-row>
            <p v-if="mto.apiKeyLoading" align="right"><bar-loader  class="custom-class" color="#6d0606" loading="loading" :size="150" sizeUnit="px"></bar-loader></p>
            <span v-else >
<!--                        <button @click="saveChangesTest()" type="button" style="float: right; box-shadow: none;" class="btn-shadow d-inline-flex align-items-center btn btn-danger button">-->
<!--                            Save Changes-->
<!--                        </button>-->
                                      <span @click="createApiKey('dev')" style="cursor: pointer; float: right; "  class="new-key mt-1 mr-3">Generate new key <b-icon-arrow-repeat/></span>
                    </span>
          </div>
          <div v-if="toggle && (mto.apiKeys.apikeyLiveSK == null || mto.apiKeys.apikeyLiveSK === '') && !mto.apiKeyLoading">
            <p class="no-config">No API configuration found for live mode... please generate one <br><br>
              <button @click="createApiKey('PROD')" style="box-shadow: none;" type="button" class="btn-shadow d-inline-flex align-items-center btn btn-danger button">
                Generate Keys
              </button>
            </p>
          </div>
          <div v-if="!toggle && (mto.apiKeys.apikeyTestSK == null || mto.apiKeys.apikeyTestSK === '') && !mto.apiKeyLoading">
            <p class="no-config">No API configuration found for test mode... please generate one <br><br>
              <button @click="createApiKey('DEV')" style="box-shadow: none;" type="button" class="btn-shadow d-inline-flex align-items-center btn btn-danger button">
                Generate Keys
              </button>
            </p>
          </div>
          <a href="https://documenter.getpostman.com/view/4543866/TzRNGAgo" target="_blank">Click here to view the API documentation</a>
        </form>
      </div><!-- card -->
    </div>
<!--    <notifications group="foo"  position="top right" />-->
  </div><!-- row -->
</template>

<script>
// import FlotChart from "../FlotChart";
import {mapState} from 'vuex'
export default {
  name: "TopCards",
  components:{

  },
  data(){
    return {
      toggle:true,
      value: [
        { name: 'Javascript', code: 'js' }
      ],
      options: [

      ],
      model:{
        username:'',
        mtoCode:'',
        env:'',
        apiKeyMtoCode:''
      },
      accountsA:[],
      accounts:[],
    };
  },
  methods: {
    nextClicked(currentPage) {
      console.log('next clicked', currentPage)
      if (currentPage >= this.steps.length-1)
        this.createOfficer()
      return true; //return false if you want to prevent moving to next page
    },
    backClicked(currentPage) {
      console.log('back clicked', currentPage);
      return true; //return false if you want to prevent moving to previous page
    },
    async readOfficers(){
      await this.$store.dispatch("officer/updateOfficers", {
        orgID: localStorage.orgID,
        accID: localStorage.accID,
        requestType: 'officer'
      }, {root: true})
    },
    showAlert(title, message) {
      const options = {title: title, size: 'sm'}
      this.$dialogs.alert(message, options)
          .then(res => {
            console.log(res) // {ok: true|false|undefined}
          })
    },
    onCopy: function (e) {
      this.$notify({
        group: 'foo',
        type: 'success',
        speed: 1000,
        title: 'Copied Success',
        text: 'You just copied: ' + e.text
      });
    },
    onError: function () {
      alert('Failed to copy texts')
    },
    async createApiKey(_stage){
      this.model.env = _stage
      this.model.apiKeyMtoCode = this.auth.userInfo.userMtoCode
      this.model.mtoCode = this.auth.userInfo.userMtoCode
      this.model.username = this.auth.userInfo.username
      await this.$store.dispatch("mto/createApiKeys", this.model, {root: true})
      // this.$router.push("/dashboard")
    },
  },
  computed:{
    ...mapState([
      'auth',
      'mto'
    ])
  },
  mounted() {
    this.model.apiKeyMtoCode = this.auth.userInfo.userMtoCode
    this.$store.dispatch("mto/updateApiKeys", this.model, {root: true})
  }
}
</script>

<style scoped>
@media only screen and (min-width: 900px) {
  .top-card {
    width: 103%;
  }
}
@media only screen and (min-width: 707px) and (max-width: 900px) {
  .top-card {
    width: 104%;
  }
}

@media only screen and (min-width: 517px)  and (max-width: 707px) {
  .top-card {
    width: 105%;
  }
}

@media only screen and (min-width: 451px)  and (max-width: 517px) {
  .top-card {
    width: 106%;
  }
}

@media only screen  and (max-width: 451px) {
  .top-card {
    width: 107%;
  }
}
.h-100 {
  height: 100vh !important;
}
@media only screen and  (max-width: 991px) {
  .extra{
    display: none;
  }
}
.image-input{
  display: block;
  width: 100%;
  height: 300px;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

.placeholder{
  background: #F0F0F0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica,serif;
}

.placeholder:hover{
  background: #E0E0E0
}

.file-input{
  display: none;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>