<template>
    <b-modal id="skill-create-modal" :hide-header="true" :hide-footer="true" :centered="true" ref="skill-create-modal">
        <div class="modal-body">
            <a href="#" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"
               @click="hide()">
                <span aria-hidden="true">&times;</span>
            </a>
            <div>
                <h4 v-if="model.type === 'edit'">Edit Mto</h4>
                <h4 v-else>Add New Mto</h4>
                <b-form @submit.prevent="submit" enctype="multipart/form-data">
                    <b-form-group v-if="model.type !== 'edit'" label="Name">
                        <b-input-group>
                          <input type="text" class="form-control" @input="filterOutBadChar()"  placeholder="First Name" required v-model="model.mtoUserFirstName"/>
                          <input type="text" class="form-control" @input="filterOutBadChar()"  placeholder="Last Name" required v-model="model.mtoUserLastName"/>
                        </b-input-group>
                    </b-form-group>
                    <b-form-group label="MTO Details">
                        <b-input-group>
                          <input :readonly="model.type === 'edit'" type="text" class="form-control"  @input="filterOutBadChar()" placeholder="MTO Code" required v-model="model.mtoCode"/>
                          <b-input type="text"  placeholder="MTO Name" required v-model="model.mtoName"/>
                        </b-input-group>
                    </b-form-group>
                    <b-form-group label="MTO Phone Number">
                      <b-input-group>
                        <vue-country-code v-model="model.mtoCountryCode" @onSelect="onSelect" :preferredCountries="['ng', 'us', 'gb']" />
                        <input class="form-control" @input="filterOutBadChar()" v-model="model.mtoPhone" required placeholder="Phone Number" type="text"/>
                      </b-input-group>
                    </b-form-group>
                    <b-form-group label="MTO Email">
                        <input type="email" class="form-control"  placeholder="MTO Email" required v-model="model.mtoEmail"/>
                    </b-form-group>
                    <b-form-group>
                      <b-input-group>
                        <country-select required class="form-control" :countryName="true" :regionName="true" v-model="model.mtoCountry" :country="model.mtoCountry" topCountry="NG" />
                      </b-input-group>
                    </b-form-group>
                    <b-form-group label="Accounting Rule Direct Credit (Existing Account)">
                      <b-select required v-model="model.mtoAccountingRuleDc">
                        <b-select-option value="">Select Accounting Rule</b-select-option>
                        <b-select-option v-for="entry in accountingRule.accountingRules.data" :value="entry.aeCode" v-bind:key="entry.aeCode">
                          {{ entry.aeCode }}</b-select-option>
                      </b-select>
                    </b-form-group>
                    <b-form-group label="Accounting Rule Direct Credit (New Account)">
                      <b-select required v-model="model.mtoAccountingRuleDcNewAccount">
                        <b-select-option value="">Select Accounting Rule</b-select-option>
                        <b-select-option v-for="entry in accountingRule.accountingRules.data" :value="entry.aeCode" v-bind:key="entry.aeCode">
                          {{ entry.aeCode }}</b-select-option>
                      </b-select>
                    </b-form-group>

                  <b-form-group label="Accounting Rule Direct Credit (InterBank)">
                    <b-select required v-model="model.mtoAccountingRuleDcInterbank">
                      <b-select-option value="">Select Accounting Rule</b-select-option>
                      <b-select-option v-for="entry in accountingRule.accountingRules.data" :value="entry.aeCode" v-bind:key="entry.aeCode">
                        {{ entry.aeCode }}</b-select-option>
                    </b-select>
                  </b-form-group>

                    <b-form-group label="Accounting Rule Cash Pickup">
                      <b-select required v-model="model.mtoAccountingRuleCp">
                        <b-select-option value="">Select Accounting Rule</b-select-option>
                        <b-select-option v-for="entry in accountingRule.accountingRules.data" :value="entry.aeCode" v-bind:key="entry.aeCode">
                          {{ entry.aeCode }}</b-select-option>
                      </b-select>
                    </b-form-group>
                    <b-form-group label="MTO Accounts">
<!--                      <b-form-tags placeholder="add accounts separating with comma" @input="filterOutBadChar()" required separator="," no-add-on-enter v-model="model.accounts"></b-form-tags>-->
                      <input placeholder="add accounts separating with comma" @input="filterOutBadChar()" required class="form-control" v-model="model.mtoAccounts">
                    </b-form-group>
                    <b-form-group label="MTO CBN Licence">
                      <b-input required v-model="model.mtoCbnLicenceNo"></b-input>
                    </b-form-group>
                    <b-form-group label="MTO Address">
                      <b-textarea required v-model="model.mtoAddress"></b-textarea>
                    </b-form-group>
                    <b-form-group label="Cbn License">
                      <b-select required v-model="model.mtoCbnLicense">
                        <b-select-option value="NO">NO</b-select-option>
                        <b-select-option value="YES">YES</b-select-option>
                      </b-select>
                    </b-form-group>
                    <b-form-group label="NDA Executed">
                      <b-select required v-model="model.mtoNdaExecuted">
                        <b-select-option value="NO">NO</b-select-option>
                        <b-select-option value="YES">YES</b-select-option>
                      </b-select>
                    </b-form-group>
                    <b-form-group label="VPN Connection Setup">
                      <b-select required v-model="model.mtoVpnConnectionSetup">
                        <b-select-option value="NO">NO</b-select-option>
                        <b-select-option value="YES">YES</b-select-option>
                      </b-select>
                    </b-form-group>
                    <b-form-group label="PCIDSS Certification">
                      <b-select required v-model="model.mtoPcidssCertificate">
                        <b-select-option value="NO">NO</b-select-option>
                        <b-select-option value="YES">YES</b-select-option>
                      </b-select>
                    </b-form-group>
                    <b-form-group label="CAC Incorporation Documents">
                      <b-select required v-model="model.mtoCacIncorporationDocuments">
                        <b-select-option value="NO">NO</b-select-option>
                        <b-select-option value="YES">YES</b-select-option>
                      </b-select>
                    </b-form-group>
                    <b-form-group label="Memorandum and Article of Association">
                      <b-select required v-model="model.mtoMemorandumAndArticlesOfAssociation">
                        <b-select-option value="NO">NO</b-select-option>
                        <b-select-option value="YES">YES</b-select-option>
                      </b-select>
                    </b-form-group>
                    <b-form-group label="Consumer Protection and Dispute Mechanism">
                      <b-select required v-model="model.mtoConsumerProtectionAndDisputeMechanism">
                        <b-select-option value="NO">NO</b-select-option>
                        <b-select-option value="YES">YES</b-select-option>
                      </b-select>
                    </b-form-group>
                    <b-form-group label="Enterprise Risk Management Framework">
                      <b-select required v-model="model.mtoEnterpriseRiskManagementFramework">
                        <b-select-option value="NO">NO</b-select-option>
                        <b-select-option value="YES">YES</b-select-option>
                      </b-select>
                    </b-form-group>
                    <b-form-group label="Contingency and Disaster Recovery">
                      <b-select required v-model="model.mtoContingencyAndDisasterRecovery">
                        <b-select-option value="NO">NO</b-select-option>
                        <b-select-option value="YES">YES</b-select-option>
                      </b-select>
                    </b-form-group>
                    <b-form-group label="Contract Agreement">
                      <b-select required v-model="model.mtoContractAgreement">
                        <b-select-option value="NO">NO</b-select-option>
                        <b-select-option value="YES">YES</b-select-option>
                      </b-select>
                    </b-form-group>
                    <b-form-group v-if="model.type === 'edit'" label="Status">
                      <b-select required v-model="model.mtoStatus">
                        <b-select-option value="PENDING">Pending</b-select-option>
                        <b-select-option value="ACTIVE">Active</b-select-option>
                        <b-select-option value="DELETED">Delete</b-select-option>
                      </b-select>
                    </b-form-group>
                    <base-button title="Submit" :loading="user.userLoading"/>
                </b-form>
            </div>
        </div><!-- modal-body -->
    </b-modal>
</template>
<script>
    import { mapState } from "vuex"
    import BaseButton from "../button/BaseButton";
    export default {
        props:['show','row'],
        components:{BaseButton},
        name: 'ConfirmModal',
        data(){
            return{
                password: '',
                model:{
                  mtoUserFirstName: '',
                  mtoUserLastName: '',
                  mtoCountryCode: '',
                  mtoCode: '',
                  mtoName: '',
                  mtoCountry: '',
                  mtoAddress: '',
                  mtoMaker: '',
                  mtoPhone: '',
                  mtoEmail: '',
                  mtoCbnLicenceNo: '',
                  mtoCbnLicense: '',
                  mtoUserMaker: '',
                  mtoFinacleId: '',
                  mtoAccounts: '',
                  accounts: [],
                  mtoStatus: 'PENDING',
                  mtoAccountingRuleDc: '',
                  mtoAccountingRuleDcNewAccount: '',
                  mtoAccountingRuleDcInterbank: '',
                  mtoNdaExecuted: '',
                  mtoVpnConnectionSetup: '',
                  mtoAccountingRuleCp: '',
                  mtoPcidssCertificate: '',
                  mtoCacIncorporationDocuments: '',
                  mtoMemorandumAndArticlesOfAssociation: '',
                  mtoConsumerProtectionAndDisputeMechanism: '',
                  mtoEnterpriseRiskManagementFramework: '',
                  mtoContingencyAndDisasterRecovery: '',
                  mtoContractAgreement: '',
                },
            }
        },
        methods:{
            filterOutBadChar(){
              this.model.mtoUserFirstName = this.model.mtoUserFirstName.replace(/[^A-Z0-9\s]/i, '')
              this.model.mtoUserLastName = this.model.mtoUserLastName.replace(/[^A-Z0-9\s]/i, '')
              this.model.mtoCode = this.model.mtoCode.replace(/[^A-Z0-9\s]/i, '')
              this.model.mtoAccounts = this.model.mtoAccounts.replace(/[^0-9,\s]/i, '')
              this.model.mtoPhone = this.model.mtoPhone.replace(/[^0-9]/i, '')
            },
            hide() {
                this.$refs['skill-create-modal'].hide()
            },
            onSelect({name, iso2, dialCode}) {
              this.model.mtoCountryCode =  dialCode
              console.log(name, iso2, dialCode);
            },
            async submit(){
                this.model.mtoMaker = this.auth.userInfo.userId
                // if (this.model.mtoAccounts !== '')
                //   this.model.mtoAccounts = this.model.accounts.join()
                // delete this.model.accounts
                if (this.model.type !== 'edit')
                  delete this.model.mtoStatus
                delete this.model.mtoFinacleId
                delete this.model.mtoUserMaker
                if (this.model.type === 'edit')
                  await this.$store.dispatch("user/editMto", this.model, {root: false});
                else {
                  //this.model.userEmployeeId = this.row.employeeId
                  await this.$store.dispatch("user/createMto", this.model, {root: false});
                }
                this.hide();
                this.$store.dispatch('user/updateMtos', {
                  readAll: 'NO',
                  employerId: localStorage.orgId,
                  employeeId: localStorage.orgId
                })
                let self = this;
                // eslint-disable-next-line no-unused-vars
                Object.keys(this.model).forEach(function(key,index) {
                    self.model[key] = '';
                });

            },
            gradeCode(){
                return  localStorage._gradeCode
            },
            loadGrade(){
                this.$store.dispatch("grade/updateGradeSalaries", {
                    readAll:'NO',
                    employerGradeSalaryEmployerId: localStorage.orgId,
                    employerGradeSalaryGradeCode: localStorage._gradeCode
                }, {root: false}).then()
            },
            hideauth: function () {
                this.password = '';
                this.$refs['skill-create-modal'].hide();
            },
            currentDate(){
                let date = new Date()
                date = this.$moment(date.toDateString()).format('YYYY-MM-DD')
                console.log(date)
                return date
            },
        },
        mounted() {
          this.$store.dispatch("accountingRule/updateAccountingRules", {readAll: 'YES',source: 'WEB'}, {root: false}).then()
            //this.model = this.row readSkillCategory
          //this.$store.dispatch('employee/readSkillCategory',{readAll:"YES"})
        },
        computed:{
            ...mapState(['auth','user','accountingRule'])
        },
        watch: {
            show: function () {
                let self = this;
              // eslint-disable-next-line no-unused-vars
                Object.keys(this.model).forEach(function(key,index) {
                  self.model[key] = '';
                });
                // eslint-disable-next-line no-unused-vars
                Object.keys(this.row).forEach(function(key,index) {
                    self.model[key] = self.row[key];
                });
                //this.model.accounts = this.model.mtoAccounts.split(',')
                this.$refs['skill-create-modal'].show()
            }
        },
    }
</script>
