import apiClient from "./BaseService";

export default {
  callGetUsersApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgID;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.post("user/read", payload);
  },
  callGetAuditTrailsApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgID;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.post("user/readAuditTrail", payload);
  },
  callCreateUsersApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgID;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.post("user/create", payload);
  },
  callEditUsersApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgID;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.post("user/update", payload);
  },
  callCreateMtoApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgID;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.post("mto/create", payload);
  },
  callCreateMtoUserApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgID;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.post("mto/userCreate", payload);
  },
  callEditMtoUserApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgID;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.post("mto/updateUser", payload);
  },
  callEditMtoApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgID;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.post("mto/update", payload);
  },
  callReadMtoApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgID;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.post("mto/read", payload);
  },
  callReadMtoUserApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgID;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.post("mto/readUser", payload);
  },
  callReadMtoUserByCodeApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgID;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.post("mto/readUserByCode", payload);
  },
  callReadAuditTrailApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgID;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.post("user/readAuditTrail", payload);
  },
  callReadAuditTrailMtoApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgID;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.post("user/readAuditTrailMto", payload);
  },
  callGetAdUsersApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgID;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.post("user/getAdUserDetails", payload);
  },
  callCreateUserUploadsApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgID;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.post("user/upload", payload);
  },
  callReadUserUploadsApi(payload) {
    // console.log("Service Payload ==>>", payload)
    payload.orgId = localStorage.orgID;
    if (localStorage._countryId != null)
      payload.countryId = localStorage._countryId;
    return apiClient.post("user/readUserUploads", payload);
  },
};
