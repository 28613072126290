import apiClient from "./BaseService";

export default {
    callTransactionScreeningCreateApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgID;
        return apiClient.post("transactionScreening/create", payload);
    },
    callTransactionScreeningUpdateStatusApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgID;
        return apiClient.post("transactionScreening/updateStatus", payload);
    },
    callTransactionScreeningReadApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgID;
        return apiClient.post("transactionScreening/read", payload);
    },
    callTransactionScreeningReadSingleApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgID;
        return apiClient.post("transactionScreening/readSingle", payload);
    },
    callTransactionScreeningReadColumnApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgID;
        return apiClient.post("report/readColumn", payload);
    },
    callTransactionScreeningReadLogApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgID;
        return apiClient.post("transactionScreening/readLogs", payload);
    },
};
