import AccountService from "../../service/AccountService";

export const namespaced = true;

export const state = {
    accounts:{},
    balances:{
        responseCode:"",
        responseMessage:"",
        data:[]
    },
    balanceLoading: true,
    response: {}

}

export const getters = {

};

export const mutations = {
    updateBalances: (state, payload) =>{
        // console.log('Entered Mutation officer', payload);
        state.balances = payload
    },

    updateBalanceLoading: (state, payload) =>{
        state.balanceLoading = payload
    },

};

export const actions = {

    updateBalances: ({ commit, state }, payload)=>{
        console.log("updateOfficers>>>>>>")
        if(state.balances.data.length < 1)
            commit("updateBalanceLoading", true)
        return AccountService.callAccountBalancesApi(payload)
            .then(response => {
                let responseData = response.data;
                console.log("response>>>>>>>>>>>>",response)
                commit("updateBalanceLoading", false)
                if (responseData.responseCode === "00") {
                    commit("updateBalances", responseData)
                }

            })
            .catch(error => {
                commit("updateBalanceLoading", false)
                alert(error)
                // throw error;
            });
    }

}
