import SettingsService from "../../service/SettingsService";
import swal from "sweetalert";
// import swal from 'sweetalert'
//import router from "../../router";



export const state = {
    systemSettings:{
        responseCode: '',
        responseMessage: '',
        configNoOfPasswordTrials:0,
        configNoOfReusableOldPassword:0
    },
    systemSettingLoading: true,
    response:{}

}

export const mutations = {
    updateSystemSettings: (state, payload) =>{
        state.systemSettings = payload
    },
    updateMakerSystemSettings: (state, payload) =>{
        state.makerSystemSettings = payload
    },
    updateSystemSettingsLoading: (state, payload) =>{
        state.systemSettingLoading = payload
    },
    updateResponse: (state, payload) =>{
        state.response = payload
    },
}

export const actions = {
    updateSystemSettings: ({ commit, state }, payload)=>{
        if(state.systemSettings.responseCode !== "00")
            commit("updateSystemSettingsLoading", true)
        return SettingsService.callSystemSettingsReadApi(payload)
            .then(response => {
                commit("updateSystemSettingsLoading", false)
                commit("updateResponse", response.data)
                if (response.data.responseCode === "00") {
                    commit("updateSystemSettingsLoading", false)
                    let responseData = response.data;
                    commit("updateSystemSettings", responseData)
                }

            })
            .catch(error => {
                commit("updateSystemSettingsLoading", false)
                alert(error)
                // throw error;
            });
    },

    changeSystemSettings: ({ commit }, payload)=>{
        commit("updateSystemSettingsLoading", true)
        return SettingsService.callSystemSettingsUpdateApi(payload)
            .then(response => {
                commit("updateSystemSettingsLoading", false)
                let responseData = response.data
                commit("updateResponse", response.data)
                if (responseData.responseCode === "00") {
                    swal('Success!',responseData.responseMessage,'success').then();
                }else
                    swal('Error!',responseData.responseMessage,'error').then();

            })
            .catch(error => {
                commit("updateSystemSettingsLoading", false)
                alert(error)
                // throw error;
            });
    },
}