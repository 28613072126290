import apiClient from "./BaseService";

// https://cors-anywhere.herokuapp.com


// apiClient.interceptors.request.use(config => {
//     const token = store.getters.getToken;
//     const tok = store.getters["auth/getTok"];
//     console.log("store.state.system.deviceId>>>>>>",store.state.system.deviceId)
//     config.headers.xreferrer = store.state.system.deviceId
//     config.headers.Authorization = (tok == null)? token: tok;
//     return config
// })
//
// apiClient.interceptors.response.use(config => {
//     if (config != null)
//         if (config.data != null)
//             if (config.data.responseCode === '92' || config.data.responsecode === '92'){
//                 swal('Session Expired!',config.data.responsemessage != null?config.data.responsemessage:config.data.responseMessage,'info').then(res=>{
//                     localStorage.clear();
//                     console.log(res)
//                     router.push('/').then()
//                 })
//             }
//     return config
// });
export default {
    callProprietorCreateApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgID;
        return apiClient.post("proprietorCreate", payload);
    },
    callProprietorReadAllApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgID;
        return apiClient.post("proprietorReadAll", payload);
    },
};
