<template>
    <div class="content-body">
        <div class="container pd-x-0">
            <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                <div>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                            <li class="breadcrumb-item"><a href="#">User</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Personal Profile</li>
                        </ol>
                    </nav>
                    <h4 class="mg-b-0 tx-spacing--1">{{$t('sidebar.personalProfile')}}</h4>
                    <p class="tx-color-03 tx-12 mg-b-0">{{auth.userInfo.userFirstName}} {{auth.userInfo.userLastName}}</p>
                    <p class="tx-color-03 tx-12 mg-b-0"><strong>Last login:</strong> {{auth.userInfo.userLastLogin | moment('dddd, MMMM Do YYYY, h:mm:ss a')}}</p>
                </div>
                <div class="d-none d-md-block">
<!--                    <new-account-button></new-account-button>-->
<!--                    <router-link to="/onboarding/new-officer" class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5"><i data-feather="plus" class="wd-10 mg-r-5"></i> {{$t('extra.newOfficer')}}</router-link>-->
                </div>
            </div>

            <!--TODO TOP CARDS-->
            <top-cards></top-cards>
            <!--TODO GROWTH-->
            <personal-profile-form></personal-profile-form>


        </div><!-- container -->
    </div>
</template>

<script>
    import TopCards from "../../components/card/TopCards";
    // import DetailsForm from "../../components/form/DetailsForm";
    // import {} from ''
    import PersonalProfileForm from "../../components/form/PersonalProfileForm";
    // import BaseTable from "../../components/table/BaseTable";
    // import NewAccountButton from "../../components/button/NewAccountButton";
    import {mapState} from 'vuex'
    export default {
        name: 'Home',
        components:{
            TopCards,
            PersonalProfileForm,
            // NewAccountButton
        },
        data(){
            return{
                fields: [
                    { key: 'serial', label: 'S/N', sortable: true, sortDirection: 'desc' },
                    { key: 'officerFirstName', label: 'First Name', sortable: true, sortDirection: 'desc' },
                    { key: 'officerLastName', label: 'Surname', sortable: true, sortDirection: 'desc' },
                    { key: 'officerDesignation', label: 'Role', sortable: true, class: 'text-center' },
                    { key: 'officerAuthBank', label: 'Bank Verified', sortable: true, class: 'text-center' },
                    { key: 'officerAuthStatus', label: 'DIR/SIG Verified', sortable: true, class: 'text-center' },
                    { key: 'actionx', label: 'Action', sortable: true, class: 'text-center' }
                ],
            }
        },
        methods:{

        },
        computed:{
            ...mapState([
                'officer',
                'auth'
            ]),

            officers:{
                get(){
                    return this.officer.officers
                },
                set(val){
                    this.$store.commit('officer/updateOfficers', val)
                }
            },
        }
    }
</script>
