<template>
  <div class="content ht-100v pd-0">
    <div class="content-body pd-10">
      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
        <div>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-style1 mg-b-10">
              <li class="breadcrumb-item">
                <router-link to="#">Settings</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">System Settings</li>
            </ol>
          </nav>
          <h4 class="mg-b-0 tx-spacing--1">System Settings</h4>
          <p class="tx-color-03 tx-12 mg-b-0">{{auth.userInfo.userFirstName}} {{auth.userInfo.userLastName}}</p>
          <p class="tx-color-03 tx-12 mg-b-0"><strong>Last login:</strong> {{auth.userInfo.userLastLogin | moment('dddd, MMMM Do YYYY, h:mm:ss a')}}</p>
        </div>
        <div class="d-none d-md-block">
        </div>
      </div>

      <!--TODO TOP CARDS-->
      <!--            <top-cards></top-cards>-->
      <!--TODO GROWTH-->
      <system-settings-form></system-settings-form>
    </div><!-- container -->
  </div>
</template>

<script>
// import TopCards from "../../components/card/TopCards";
// import DetailsForm from "../../components/form/DetailsForm";
// import {} from ''
import SystemSettingsForm from "@/components/form/SystemSettingsForm";
// import BaseTable from "../../components/table/BaseTable";
import {mapState} from 'vuex'
export default {
  name: 'SystemSettings',
  components:{
    // TopCards,
    SystemSettingsForm,
  },
  data(){
    return{
      fields: [
        { key: 'serial', label: 'S/N', sortable: true, sortDirection: 'desc' },
        { key: 'officerFirstName', label: 'First Name', sortable: true, sortDirection: 'desc' },
        { key: 'officerLastName', label: 'Surname', sortable: true, sortDirection: 'desc' },
        { key: 'officerDesignation', label: 'Role', sortable: true, class: 'text-center' },
        { key: 'officerAuthBank', label: 'Bank Verified', sortable: true, class: 'text-center' },
        { key: 'officerAuthStatus', label: 'DIR/SIG Verified', sortable: true, class: 'text-center' },
        { key: 'actionx', label: 'Action', sortable: true, class: 'text-center' }
      ],
    }
  },
  methods:{

  },
  computed:{
    ...mapState([
      'officer',
      'auth'
    ]),

    officers:{
      get(){
        return this.officer.officers
      },
      set(val){
        this.$store.commit('officer/updateOfficers', val)
      }
    },
  }
}
</script>
