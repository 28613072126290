<template>
    <div>
        <side-bar v-if="auth.userInfo" layout="payments-layout"></side-bar>
        <div class="content ht-100v pd-0">
            <Header v-if="auth.userInfo"></Header>
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import Header from "../../../components/menu/Header";
    import SideBar from "../../../components/menu/Sidebar";
    import  { mapState } from  "vuex";

    export default {
        name: 'Home',
        components: {SideBar, Header},
        computed:{
            ...mapState(['auth','system'])
        },
        mounted() {
        }


    }
</script>
