<template>
    <router-view></router-view>
</template>

<script>
    // @ is an alias to /src

    export default {
        name: 'Home',
        methods: {

        },
        created(){
        },
        computed:{
        },
        mounted() {
        }


    }
</script>
