<template>
    <div>
        <ul class="nav nav-aside">
            <!--<li class="nav-label">Dashboard</li>-->
            <li v-for="(menu,index) in menus" :class="menu.classType+' '+checkActive(menu.href)+' '+checkWithSub(menu.child)" v-bind:key="index">
                <router-link  :id="menu.id" v-if="!menu.header && menu.show" :to="(menu.href != null)? menu.href:''" class="nav-link">
                    <ChevronRightIcon v-if="menu.icon === 'chevron'"/>
                    <shopping-bag-icon v-else-if="menu.icon === 'shopping-bag'" />
                    <layers-icon v-else-if="menu.icon === 'layers'" />
                    <users-icon v-else-if="menu.icon === 'users'" />
                    <home-icon v-else-if="menu.icon === 'home'" />
                    <file-text-icon v-else-if="menu.icon === 'file-text'" />
                    <ChevronsRightIcon v-else-if="menu.icon === 'chevron-multiple'" />
                    <UserCheckIcon v-else-if="menu.icon === 'auth'" />
                    <ColumnsIcon v-else-if="menu.icon === 'accounts'" />
                    <ListIcon v-else-if="menu.icon === 'activities'" />
                    <FileTextIcon v-else-if="menu.icon === 'statements'" />
                    <UsersIcon v-else-if="menu.icon === 'officers'" />
                    <Edit3Icon v-else-if="menu.icon === 'signatories'" />
                    <BarChartIcon v-else-if="menu.icon === 'dashboard'" />
                    <i v-else :data-feather="menu.icon"></i>
                    <span><span v-if="menu.show">{{$t(menu.title)}}</span></span>
                    <b-badge v-if="menu.new === 'yes' && menu.show" variant="success" class="pos-absolute" style="right: 0;">New</b-badge>
                </router-link>
                <span v-else><span :id="menu.id" v-if="menu.show">{{$t(menu.title)}}</span></span>
                <ul v-if="menu.child != null  && menu.show">
                    <li v-for="(child,index) in menu.child" v-bind:key="index" >
                        <router-link  v-if="child.show" :to="child.href"  :id="child.id">{{$t(child.title)}}</router-link>
                        <b-badge variant="success" v-if="child.new === 'yes' && child.show"  class="pos-absolute" style="right: 0;">New</b-badge>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
    import { ListIcon, FileTextIcon, ChevronRightIcon, BarChartIcon, UserCheckIcon, LayersIcon, HomeIcon,
        ColumnsIcon,ChevronsRightIcon, UsersIcon, Edit3Icon, ShoppingBagIcon} from "vue-feather-icons"
    export default {
        props:["menus"],
        name: "BaseSidebar",
        components: {
            ListIcon, FileTextIcon, ChevronRightIcon, BarChartIcon, ShoppingBagIcon, LayersIcon, HomeIcon,
            UserCheckIcon, ColumnsIcon, ChevronsRightIcon, UsersIcon, Edit3Icon
        },
        data(){
            return{
                classType:''
            }
        },
        methods:{
            checkActive(_link){
                if (_link != null)
                    if(this.$route.path.replace('/','') === _link.replace('/',''))
                        return 'active'
                    else
                        return ''
                else
                    return ''
            },
            checkWithSub(_child){
                if (_child != null)
                    return 'with-sub'
                else
                    return ''
            }
        },
        computed:{

        }
    }
</script>

<style scoped>

</style>