import apiClient from "./BaseService";

export default {
    callSystemSettingsUpdateApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgID;
        return apiClient.post("config/update", payload);
    },
    callSystemSettingsReadApi(payload) {
        // console.log("Service Payload ==>>", payload)
        payload.orgId = localStorage.orgID;
        return apiClient.post("config/readSingle", payload);
    }
};
